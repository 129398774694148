
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AboutSeven = () => {
    const { t } = useTranslation();
    return (
        <div className=" bridgesoftrust-home-four-about edu-about-area about-style-2 edu-section-gap bg-color-white">
            <div className="container bridgesoftrust-animated-shape">
                <div className="row row--50">
                    <div className="col-lg-6">
                        <div className="about-image-gallery">
                            <div className="bridgesoftrust-about-1-img-wrapper">
                                <img className="image-1" src="/images/about/about-07/about-image-01.png" alt="About Images" />
                                <span className="bridgesoftrust-about-blur">
                                    <img src="/images/about/about-07/about-blur.png" alt="About Blur" />
                                </span>
                            </div>
                            <div className="circle-image-wrapper">
                                <img className="image-2" src="/images/about/about-07/about-image-02.png" alt="About Images" />
                                <div className="circle-image">
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="inner mt_md--40 mt_sm--40">
                            <div className="section-title text-start">
                                <span className="pre-title">{t("aboutFoundationTitleSmall")}</span>
                                <h3 className="title">{t("aboutFoundationTitleBig")}</h3>
                            </div>
                            <p className="description mt--40 mt_md--20 mt_sm--20">{t("aboutFoundationDesc")}</p>
                                <h6 className="subtitle mb--20">{t("aboutFoundationSubtitle")}</h6>
                            <div className="about-feature-list">
                                <div className="row g-5">
                                    <div className="col-lg-6">
                                        <div className="feature-style-3">
                                            <div className="feature-content">
                                                <h6 className="feature-title">{t("aboutFoundationFeature1Title")}</h6>
                                                <p className="feature-description">{t("aboutFoundationFeature1Desc")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="feature-style-3">
                                            <div className="feature-content">
                                                <h6 className="feature-title">{t("aboutFoundationFeature2Title")}</h6>
                                                <p className="feature-description">{t("aboutFoundationFeature2Desc")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="read-more-btn mt--40">
                                <Link className="edu-btn" to="https://docs.mnd.bg/" target='_blank'>{t('aboutLearnMore')}<i className="icon-arrow-right-line-right"></i></Link>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-11-05.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-08-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-30.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-1">
                        <span className="shape-dot"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSeven;