import React from 'react';
import { Link } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';
import { useTranslation } from 'react-i18next';

const AboutFive = ( { wrapperClass } ) => {
    const { t } = useTranslation();
    return (
        <div className={`home-three-about edu-about-area about-style-4 bg-color-white ${ wrapperClass || 'edu-section-gapBottom' }`}>
            <div className="container bridgesoftrust-animated-shape">
                <div className="row g-lg-5 g-md-5 g-sm-5">
                    <div className="col-lg-12 col-xl-6">
                        <div className="gallery-wrapper">
                            <img src="/images/about/about-05/social-experts.jpg" alt="About Images" />
                        </div>
                    </div>
                    <div className="col-lg-12 col-xl-6">
                        <div className="inner mt_mobile--40">
                            <div className="section-title text-start">
                                <span className="pre-title">{t("socialExpertsWhoAreWeTitle1")}</span>
                                <h3 className="title">{t("socialExpertsWhoAreWeTitle2")}</h3>
                            </div>
                            <div className="feature-style-6 mt--40">
                                <div className="edu-feature-list color-variation-1">
                                    <div className="icon">
                                        <img src="/images/about/about-05/student.png" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">{t("socialExpertsWhoAreWeFeat1Title")}</h6>
                                        <p className="description">{t("socialExpertsWhoAreWeFeat1Desc")}</p>
                                    </div>
                                </div>

                                <div className="edu-feature-list color-variation-2">
                                    <div className="icon">
                                        <img src="/images/about/about-05/book.png" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">{t("socialExpertsWhoAreWeFeat2Title")}</h6>
                                        <p className="description">{t("socialExpertsWhoAreWeFeat2Desc")}</p>
                                    </div>
                                </div>
                                
                                <div className="edu-feature-list color-variation-3">
                                    <div className="icon">
                                        <img src="/images/about/about-05/reward.png" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">{t("socialExpertsWhoAreWeFeat3Title")}</h6>
                                        <p className="description">{t("socialExpertsWhoAreWeFeat3Desc")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="read-more-btn mt--30">
                            <Link className="edu-btn" to="/join">{t("socialExpertsWhoAreWeButton")} <i className="icon-arrow-right-line-right"></i></Link>
                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-03-08.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-27.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFive;