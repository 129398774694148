import React from 'react';
import Slider from 'react-slick';
import { useTranslation } from "react-i18next";

const PrevArrow = ( props ) => {
    const { onClick } = props;
    return <button className="slide-arrow prev-arrow slick-arrow" onClick={onClick}><i className="icon-arrow-left-line"></i></button>
}

const NextArrow = ( props ) => {
    const { onClick } = props;
    return <button className="slide-arrow next-arrow slick-arrow" onClick={onClick}><i className="icon-arrow-right-line"></i></button>
}

const TestimonialThree = ( props ) => {
    const { t } = useTranslation();
    const sliderSettings = {
        dots: props.dots === "enable" ? true : false,
        infinite: true,
        arrows: props.arrows === "disable" ? false : true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        fade: props.fade === "disable" ? false : true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    let data = [
        {
            name: t("testimonial1Name"),
            details: t("testimonial1Details1"),
            details2: t("testimonial1Details2"),
            designation: t("testimonial1Designation"),
            image: "client-01.png",
            rating: 5
        },
    ];

    data = props.item ? data.slice( 0, props.item ) : data;
  
    const classes = `bridgesoftrust-testimonial-three inner testimonial-card-activation-1 slick-arrow-style-2 ${props.className ? props.className : '' }`;
    return (
        <Slider 
            className={classes}
            { ...sliderSettings }
        >
            { data.map( ( item, i ) => (
                <div 
                    className={ props.itemClass || 'single-card' }
                    key={i}
                >
                    { props.rating !== 'disable' && 
                        <div className="rating bridgesoftrust-course-rating-stars">
                            {/* {[...Array(5)].map((_, index) => {
                                return <i key={index} className={ index < item.rating ? 'on icon-Star' : 'off icon-Star' }></i>
                            })} */}
                        </div>
                    }
                    <p className="description">{ props.details2 !=="enable" ? item.details : item.details2 }</p>
                    {/* <div className="client-info">
                        <div className="thumbnail">
                            <img src={`${process.env.PUBLIC_URL}/images/testimonial/testimonial-04/${item.image}`} alt="Client Thumb" />
                        </div>
                        <div className="content">
                            <h6 className="title">{ item.name }</h6>
                            <span className="designation">{ item.designation }</span>
                        </div>
                    </div> */}
                </div>
            ) ) }
        </Slider>
    )
}

export default TestimonialThree;