import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactUsForm from '../../components/contact/ContactUsForm';
import { useTranslation } from 'react-i18next';

const ContactUs = () => {
    const { t } = useTranslation()
    return (
        <>
            <SEO title={t('seoContact')} />
            <Layout>
                <BreadcrumbOne 
                    title={t('contactTitle')}
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Contact Us"
                />
                <div className="bridgesoftrust-contact-us edu-contact-us-area edu-section-gap bg-color-white">
                    <div className="container bridgesoftrust-animated-shape">
                        <div className="row g-5">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="pre-title">{t('contactTitle1')}</span>
                                    <h3 className="title">{t('contactTitle2')}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row g-5 mt--20 justify-content-center">
                            <div className="col-lg-6">
                                <ContactUsForm formStyle="rnt-contact-form rwt-dynamic-form row" />
                            </div>
                        </div>

                        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="/images/shapes/shape-02-08.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-3">
                                <img src="/images/shapes/shape-15.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default ContactUs;