import React from "react";

const JoinForm = () => {
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    document.body.appendChild(script);
  }, []);

  const style = {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    border: 0,
  };

  return (
    <iframe
      style={style}
      data-tally-src="https://tally.so/r/n9B7bQ?transparentBackground=1"
      width="100%"
      height="100%"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      title="Приъседини се"
    ></iframe>
  );
};

export default JoinForm;
