import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from "react-i18next";

const HowItWorksOne = ( { wrapperClass, itemClass } ) => {
    const { t } = useTranslation();

    const items = [
        {
            title: t('solutionItem1Title'),
            info: t('solutionItem1Desc'),
            icon: 'ri-message-2-line',
            shape: 'shape-bg-1',    
            link: '#'
        },
        {
            title: t('solutionItem2Title'),
            info: t('solutionItem2Desc'),
            icon: 'ri-open-arm-line',
            shape: 'shape-bg-2',
            link: '#'
        },
        {
            title: t('solutionItem3Title'),
            info: t('solutionItem3Desc'),
            icon: 'ri-briefcase-4-line',
            shape: 'shape-bg-3',
            link: '#'
        },
    ];
    

    return (
        <div className={ wrapperClass || 'row g-5 mt--20' }>
            { items.map( ( data , i ) => (
                <ScrollAnimation 
                    animateIn="fadeIn"
                    animateOut="fadeInOut"
                    className={ itemClass || 'service-card-single col-lg-4 col-md-6 col-12' }
                    animateOnce={ true }
                    key={ i }
                >
                    <div className={`service-card service-card-7 ${data.shape}`}>
                        <div className="inner">
                            <div className="icon">
                                <a href={ data.link }>
                                    <i className={ data.icon }></i>
                                </a>
                            </div>
                            <div className="content">
                                <h6 className="title"><a href={ data.link }>{ data.title }</a></h6>
                                <p className="description">{ data.info }</p>
                            </div>
                        </div>
                        <div className="arrow-icon">
                            <img src="/images/how-it-works/arrow-down.png" alt="Arrow Images" />
                        </div>
                    </div>
                </ScrollAnimation>
            ) ) }
        </div>
    )
}

export default HowItWorksOne;