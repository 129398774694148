import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import BannerThree from '../../components/banner/BannerThree';
import HomeThreeService from '../../components/home-three/HomeThreeService';
import AboutFive from '../../components/about/AboutFive';
import AboutTwo from '../../components/about/AboutTwo';
import TestimonialSectionTwo from '../../components/testimonial-section/TestimonialSectionTwo';
import FooterTwo from '../../common/footer/FooterTwo';
import { useTranslation } from 'react-i18next';

const HomeThree = () => {
    const { t } = useTranslation()
    return (
        <>
            <SEO title={t('seoDisabilities')} />
             
            <HeaderTwo styles="header-transparent header-style-2" searchDisable buttonStyle="bg-color-white" />

            <BannerThree />
            
            <HomeThreeService />

            <AboutFive />

            <AboutTwo />

            <TestimonialSectionTwo />

            <FooterTwo />
        </>
    )
}

export default HomeThree;