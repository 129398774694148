import React from 'react';
import JoinForm from '../../components/join/JoinForm'
const Join = () => {
    return (
        <>
            <JoinForm />
        </>
    )
}

export default Join;