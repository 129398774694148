import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Nav = () => {
    const { t } = useTranslation();
    return (
        <ul className="mainmenu">

            <li className="">
                <Link to="/">{t('home')}</Link>
            </li>

            <li className="">
                <Link to="/about">{t('about')}</Link>
            </li>
            
            <li className="has-droupdown">
                <Link to="#">{t('programs-support')}</Link>
                <ul className="submenu">
                    <li><Link to="/programs/for-people-with-disabilities">{t('for-people-disabilities')}</Link></li>
                    <li><Link to="/programs/for-social-experts">{t('for-social-experts')}</Link></li>
                    <li><Link to="/programs/for-employers">{t('for-employers')}</Link></li>
                </ul>
            </li>

            <li className="">
                <Link to="/contact">{t('contact')}</Link>
            </li>
        </ul>
    )
}
export default Nav;
