import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';



const CategoryThree = ( { wrapperClass, styleClass } ) => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("peopleDisabilitiesService1Title"),
            numberOfCourses: t("peopleDisabilitiesService1Desc"),
            icon: 'icon-Schoolbag',
            link: '#'
        },
        {
            title: t("peopleDisabilitiesService2Title"),
            numberOfCourses: t("peopleDisabilitiesService2Desc"),
            icon: 'icon-student-read',
            link: '#'
        },
        {
            title: t("peopleDisabilitiesService3Title"),
            numberOfCourses: t("peopleDisabilitiesService3Desc"),
            icon: 'icon-Pencil',
            link: '#'
        },
        {
            title: t("peopleDisabilitiesService4Title"),
            numberOfCourses: t("peopleDisabilitiesService4Desc"),
            icon: 'icon-Class',
            link: '#'
        },
        {
            title: t("peopleDisabilitiesService5Title"),
            numberOfCourses: t("peopleDisabilitiesService5Desc"),
            icon: 'icon-Setting',
            link: '#'
        },
        {
            title: t("peopleDisabilitiesService6Title"),
            numberOfCourses: t("peopleDisabilitiesService6Desc"),
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: t("peopleDisabilitiesService7Title"),
            numberOfCourses: t("peopleDisabilitiesService7Desc"),
            icon: 'icon-Headphone',
            link: '#'
        },
        {
            title: t("peopleDisabilitiesService8Title"),
            numberOfCourses: t("peopleDisabilitiesService8Desc"),
            icon: 'icon-Microscope',
            link: '#'
        },
        {
            title: t("peopleDisabilitiesService9Title"),
            numberOfCourses: t("peopleDisabilitiesService9Desc"),
            icon: 'icon-Fitness',
            link: '#'
        }
    ];

    return (
        <div className={ `row ${ wrapperClass || 'g-5 mt--25' }` }>
            { items.map( ( data , i ) => (
                <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    className={ `col-lg-4 col-md-6 col-12 ${ styleClass ? styleClass : '' }` }
                    animateOnce={ true }
                    key={ i }
                >
                    <div className="service-card service-card-5">
                        <div className="inner">
                            <div className="icon">
                                <i className={ data.icon }></i>
                            </div>
                            <div className="content">
                                <h6 className="title"><a href={ data.link }>{ data.title }</a></h6>
                                <p className="description">{ data.numberOfCourses }</p>
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            ) ) }
        </div>
    )
}

export default CategoryThree;