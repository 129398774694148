import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ScrollTopButton from "./ScrollTopButton";
import PocketBase from "pocketbase";

const FooterTwo = () => {
  const [result, setResult] = useState(false);
  const [err, setErr] = useState(false);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
  
    const formData = new FormData();
    formData.append('formType', 'newsletter');
    formData.append('email', email);

    fetch('https://submissions.vladislav-d-mihov.workers.dev/', {
      method: 'POST',
      body: formData
    })
    .then(response => {
      if (response.ok) {
        setMessage("Thank you for subscribing!");
        setResult(true);
        setErr(false);
        e.target.reset();
      } else {
        throw new Error('Network response was not ok.');
      }
    })
    .catch((error) => {
      console.error('Submission error:', error);
      setMessage("Failed to subscribe. Please try again.");
      setResult(true);
      setErr(true);
    });
  };

  // Automatically hide the result message after 30 seconds
  if (result) {
    setTimeout(() => {
      setResult(false);
      setErr(false);
      setMessage("");
    }, 30000);
  }

  return (
    <>
      <footer className="bridgesoftrust-footer-two edu-footer-with-cta">
        <div className="edu-cta-area cta-with-overlay">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-6 mx-auto">
                <div className="mx-auto call-action-style-1 box-layout bg-color-secondary radius-small">
                  <div className="inner">
                    <div className="content">
                      <div className="section-title text-white">
                        <span className="pre-title">{t("footerRightTitle1")}</span>
                        <h5 className="title">
                        {t("footerRightTitle2")}
                          <br />
                          {t("footerRightTitle3")}
                        </h5>
                      </div>
                      <div className="newsletter-form-box mt--25">
                        <div className="newsletter-style-2">
                          <form action="" onSubmit={handleSubmit}>
                            <input
                              type="email"
                              name="email"
                              placeholder={t("newsletterPlaceholder")}
                            />
                            <div className="subscribe-btn">
                              <button className="edu-btn btn-medium btn-dark">
                              {t("footerRightButton")}
                              </button>
                            </div>
                            {result && (
                              <div className="form-group bg-white">
                                <p className={`form-message ${err ? 'error' : 'success'}`}>{message}</p>
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                      <div className="shape-image-2">
                        <img
                          src="/images/footer/shape-06.png"
                          alt="Shape Images"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="edu-footer footer-style-default">
          <div className="footer-top">
            <div className="container bridgesoftrust-animated-shape">
              <div className="row g-5 medium-margin-top-big">
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <div className="edu-footer-widget">
                    <div className="logo">
                      <Link to={process.env.PUBLIC_URL + "/"}>
                        <img
                          className="logo-light"
                          src="/images/logo/logo-white.png"
                          alt="Footer Logo"
                        />
                      </Link>
                    </div>
                    <p className="description">
                      {t("footerDesc")}
                    </p>
                    <ul className="social-share">
                      <li>
                        <a href="https://www.facebook.com/profile.php?id=100080249443412">
                          <i className="icon-Fb"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/mnd-bg">
                          <i className="icon-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <div className="edu-footer-widget explore-widget">
                    <h5 className="widget-title">{t("about-us")}</h5>
                    <div className="inner">
                      <ul className="footer-link link-hover">
                        <li>
                          <Link to="/about">
                            <i className="icon-Double-arrow"></i>
                            {t("about-us")}
                          </Link>
                        </li>
                        <li>
                          <a href="https://docs.mnd.bg/" target="_blank">
                            <i className="icon-Double-arrow"></i>
                            {t("public-documentation")}
                          </a>
                        </li>
                        <li>
                          <a href="/terms-of-service">
                            <i className="icon-Double-arrow"></i>
                            {t("linksToS")}
                          </a>
                        </li>
                        <li>
                          <Link to="/privacy-policy">
                            <i className="icon-Double-arrow"></i>
                            {t("linksPrivacyPolicy")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <div className="edu-footer-widget quick-link-widget">
                    <h5 className="widget-title">{t("programs-support-footer")}</h5>
                    <div className="inner">
                      <ul className="footer-link link-hover">
                        <li>
                          <Link to="/programs/for-people-with-disabilities">
                            <i className="icon-Double-arrow"></i>
                            {t("for-people-disabilities")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/programs/for-social-experts">
                            <i className="icon-Double-arrow"></i>
                            {t("for-social-experts")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/programs/for-employers">
                            <i className="icon-Double-arrow"></i>
                            {t("for-employers")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <div className="edu-footer-widget">
                    <h5 className="widget-title">Contact Info</h5>
                    <div className="inner">
                      <div className="widget-information">
                        <ul className="information-list">
                          <li>
                            <i className="icon-mail-line-2"></i>
                            <a
                              target="_blank"
                              href="mailto:info@mnd.bg"
                            >
                              info@mnd.bg
                            </a>
                          </li>
                          <li>
                            IBAN: BG 35 BPBI 7940 10 91176901
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="shape-dot-wrapper shape-wrapper d-md-block d-none">
                <div className="animate-image shape-image-1">
                  <img src="/images/shapes/shape-21-01.png" alt="Shape Thumb" />
                </div>
                <div className="animate-image shape-image-2">
                  <img src="/images/shapes/shape-35.png" alt="Shape Thumb" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area copyright-default">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <p>
                    Мостове на доверието 2024
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ScrollTopButton />
    </>
  );
};

export default FooterTwo;
