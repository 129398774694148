import React, { useState } from "react";
import PocketBase from "pocketbase";
import { useTranslation } from "react-i18next";

function ContactUsForm({ formStyle }) {
  const [result, setResult] = useState(false);
  const [err, setErr] = useState(false);
  const { t } = useTranslation();

  const Result = () => {
    if (err) {
      return <p className="form-message error">{t("formError")}</p>;
    } else {
      return <p className="form-message success">{t("formSuccess")}</p>;
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const { fullName, email, phone, subject, message } = e.target;

    const formData = new FormData();
    formData.append('formType', 'contact');
    formData.append('name', fullName.value);
    formData.append('email', email.value);
    formData.append('phone', phone.value);
    formData.append('subject', subject.value);
    formData.append('message', message.value);

    fetch('https://submissions.vladislav-d-mihov.workers.dev/', {
        method: 'POST',
        body: formData,
    })
    .then(response => {
        console.log("RESP,", response)
        if (response.ok) {
            e.target.reset(); // Reset form on successful submission
            setResult(true);  // Update state to indicate success
            setErr(false);    // Reset any previous error state
        } else {
            throw new Error('Network response was not ok.');
        }
    })
    .catch((error) => {
        console.error('Submission error:', error);
        setErr(true);  // Update state to indicate an error
    });
  };
  return (
    <form className={`${formStyle}`} action="" onSubmit={handleSubmit}>
      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="text"
            className="form-control form-control-lg"
            name="fullName"
            placeholder={t("contactFormName")}
            required
          />
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="email"
            className="form-control form-control-lg"
            name="email"
            placeholder={t("contactFormEmail")}
            required
          />
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="text"
            className="form-control form-control-lg"
            name="phone"
            placeholder={t("contactFormPhone")}
          />
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="text"
            className="form-control form-control-lg"
            name="subject"
            placeholder={t("contactFormSubject")}
          />
        </div>
      </div>
      <div className="col-lg-12">
        <div className="form-group">
          <textarea
            className="form-control"
            name="message"
            placeholder={t("contactFormMessage")}
          ></textarea>
        </div>
      </div>
      <div className="col-lg-12">
        <button className="rn-btn edu-btn w-100" type="submit">
          <span>{t("contactFormButton")}</span>
          <i className="icon-arrow-right-line-right"></i>
        </button>
      </div>

      {result && !err && (
        <div className="form-group">
          <p className="form-message success">{t("formSuccess")}</p>
        </div>
      )}

      {err && (
        <div className="form-group">
          <p className="form-message error">{t("formError")}</p>
        </div>
      )}

    </form>
  );
}
export default ContactUsForm;
