import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';
import InstructorOne from '../instructor/InstructorOne';
import InstructorDataEn from "../../data/instructor/InstructorDataEn.json";
import InstructorDataBg from "../../data/instructor/InstructorDataBg.json";
import InstructorDataDe from "../../data/instructor/InstructorDataDe.json";
import InstructorDataSp from "../../data/instructor/InstructorDataSp.json";
import { useTranslation } from "react-i18next";

import i18n from "i18next";

export const getLanguage = () => {
  return (
    i18n.language ||
    (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
    "en"
  );
};

export const getTeamMembers = () => {
  const lang = getLanguage();
  switch (lang) {
    case "bg":
      return InstructorDataBg.slice(0, 8);
    case "en":
      return InstructorDataEn.slice(0, 8);
    case "de":
      return InstructorDataDe.slice(0, 8);
    case "sp":
      return InstructorDataSp.slice(0, 8);
  }
};

const AboutUsOneTeam = () => {
    const { t } = useTranslation();
    const TeamMembers = getTeamMembers();
    return (
        
        <div className="bridgesoftrust-about-one-team edu-team-area edu-section-gap team-area-shape-position bg-image">
            <div className="container bridgesoftrust-animated-shape">
                <div className="row g-5">
                    <div className="col-lg-12">
                        <SectionTitle
                            classes = "text-center"
                            slogan = {t("aboutTeamSlogan")}
                            title = {t("aboutTeamTitle")}
                        />
                    </div>
                </div>
                <div className="row row--20">
                    { 
                        TeamMembers.map((item) => (
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}
                                className="col-lg-3 col-md-6 col-sm-6 col-12 mt--45"
                                key={item.id}
                            >
                                <InstructorOne data={item} />
                            </ScrollAnimation>
                        ))
                    }
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-08-02.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-16-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-4">
                        <img src="/images/shapes/shape-04-02.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>

            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                <div className="shape-image shape-image-2">
                    <img src="/images/shapes/shape-24-02.png" alt="Shape Thumb" />
                </div>
            </div>
        </div>
    )
}

export default AboutUsOneTeam;