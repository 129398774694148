import React from 'react';
import { useTranslation } from 'react-i18next';

const LogoOne = ( { separator }) => {
    const { t } = useTranslation();
    return (
        <div className={`edu-brand-area edu-section-gap bg-color-white ${ separator !== 'disable' ? 'edu-line-separator' : '' }`}>
            <div className="container">
                <div className="row bridgesoftrust-home-five-brands">
                    <div className="col-lg-2 col-md-12">
                        <div className="client-logo">
                            <h6>{t('partners')}: </h6>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="https://worddio.com/" className="client-logo" target="_blank">
                            <img className="logo-main" src="/images/brand/worddio.png" alt="Brand Images" />
                            <img className="logo-hover" src="/images/brand/worddio-hover.png" alt="Brand Images" />
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="https://nbu.bg/" className="client-logo" target="_blank">
                            <img className="logo-main" src="/images/brand/nbu.png" alt="Brand Images" />
                            <img className="logo-hover" src="/images/brand/nbu-hover.png" alt="Brand Images" />
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="https://www.fantastico.bg/" className="client-logo" target="_blank">
                            <img className="logo-main" src="/images/brand/fantastico.png" alt="Brand Images" />
                            <img className="logo-hover" src="/images/brand/fantastico-hover.png" alt="Brand Images" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogoOne;