import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import { useTranslation } from 'react-i18next';

const TermsOfService = () => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <SEO title={t('linksToS')} />
            <Layout>
                <BreadcrumbOne 
                    title={t('linksToS')}
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Terms of Service"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                            {
                            i18n.language === 'bg' ? (
                            <div className="bg content">
                            <ol class="c3 lst-kix_kx2brxvekfev-0 start" start="1">
                                <li class="c30 li-bullet-0"><span class="c16">&#1054;&#1073;&#1097;&#1080;
                                        &#1088;&#1072;&#1079;&#1087;&#1086;&#1088;&#1077;&#1076;&#1073;&#1080;</span></li>
                            </ol>
                            <p class="c1"><span class="c2"></span></p>
                            <ol class="c3 lst-kix_2qbxv6hdtyws-0 start" start="1">
                                <li class="c14 li-bullet-1"><span
                                        class="c2">&#1053;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1080;&#1090;&#1077;
                                        &#1054;&#1073;&#1097;&#1080; &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103; &#1089;&#1077;
                                        &#1087;&#1088;&#1080;&#1083;&#1072;&#1075;&#1072;&#1090; &#1074;
                                        &#1086;&#1090;&#1085;&#1086;&#1096;&#1077;&#1085;&#1080;&#1103;&#1090;&#1072; &#1089;
                                        &#1055;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1080;&#1090;&#1077;
                                        &#1085;&#1072; &#1089;&#1072;&#1081;&#1090;&#1072; &#1085;&#1072;
                                        &#1072;&#1082;&#1094;&#1080;&#1086;&#1085;&#1077;&#1088;&#1085;&#1086;
                                        &#1076;&#1088;&#1091;&#1078;&#1077;&#1089;&#1090;&#1074;&#1086;
                                        &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; &nbsp;.</span></li>
                                <li class="c14 li-bullet-1"><span class="c2">&#1052;&#1086;&#1083;&#1103;
                                        &#1087;&#1088;&#1086;&#1095;&#1077;&#1090;&#1077;&#1090;&#1077;
                                        &#1074;&#1085;&#1080;&#1084;&#1072;&#1090;&#1077;&#1083;&#1085;&#1086;
                                        &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1080;&#1090;&#1077; &#1054;&#1073;&#1097;&#1080;
                                        &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103; &#1079;&#1072;
                                        &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077;
                                        &#1087;&#1088;&#1077;&#1076;&#1080; &#1076;&#1072;
                                        &#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1090;&#1077;
                                        &#1091;&#1077;&#1073;&#1089;&#1072;&#1081;&#1090;&#1072; &#1085;&#1072;
                                        &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; . &#1058;&#1077;&#1079;&#1080;
                                        O&#1073;&#1097;&#1080; &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;
                                        &#1087;&#1088;&#1077;&#1076;&#1089;&#1090;&#1072;&#1074;&#1083;&#1103;&#1074;&#1072;&#1090;
                                        &#1044;&#1054;&#1043;&#1054;&#1042;&#1054;&#1056; &#1084;&#1077;&#1078;&#1076;&#1091;
                                        &#1042;&#1072;&#1089; &#1080; &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; , &#1089;
                                        &#1082;&#1086;&#1081;&#1090;&#1086;
                                        &#1087;&#1086;&#1083;&#1091;&#1095;&#1072;&#1074;&#1072;&#1090;&#1077;
                                        &#1087;&#1088;&#1072;&#1074;&#1086;&#1090;&#1086; &#1076;&#1072;
                                        &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1090;&#1077;
                                        &#1091;&#1089;&#1083;&#1091;&#1075;&#1080;&#1090;&#1077; &#1085;&#1072;
                                        &#1089;&#1072;&#1081;&#1090;&#1072; &#1087;&#1088;&#1080;
                                        &#1089;&#1087;&#1072;&#1079;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;&#1090;&#1072;
                                        &#1087;&#1086;-&#1076;&#1086;&#1083;&#1091;.</span></li>
                                <li class="c9 li-bullet-2"><span class="c31">&#1042;&#1089;&#1103;&#1082;&#1086; &#1083;&#1080;&#1094;&#1077;,
                                        &#1085;&#1072;&#1088;&#1080;&#1095;&#1072;&#1085;&#1086; &#1079;&#1072;
                                        &#1082;&#1088;&#1072;&#1090;&#1082;&#1086;&#1089;&#1090;
                                        &bdquo;&#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&ldquo;,
                                        &#1087;&#1086;&#1083;&#1091;&#1095;&#1080;&#1083;&#1086; &#1076;&#1086;&#1089;&#1090;&#1098;&#1087;
                                        &#1076;&#1086; &#1091;&#1077;&#1073; &#1089;&#1072;&#1081;&#1090; &#1089;
                                        &#1072;&#1076;&#1088;&#1077;&#1089;: </span><span class="c23">https://www.mnd.bg/,</span><span
                                        class="c2">&nbsp;&#1085;&#1072;&#1088;&#1080;&#1095;&#1072;&#1085;
                                        &#1087;&#1086;-&#1085;&#1072;&#1090;&#1072;&#1090;&#1098;&#1082;
                                        &bdquo;&#1089;&#1072;&#1081;&#1090;&ldquo;, &#1080; &#1076;&#1086;
                                        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072; &#1080;
                                        &#1091;&#1089;&#1083;&#1091;&#1075;&#1080;&#1090;&#1077; &#1074; &#1085;&#1077;&#1075;&#1086;,
                                        &#1085;&#1072;&#1088;&#1080;&#1095;&#1072;&#1085;&#1080; &#1079;&#1072;
                                        &#1082;&#1088;&#1072;&#1090;&#1082;&#1086;&#1089;&#1090;
                                        &bdquo;&#1057;&#1098;&#1076;&#1098;&#1088;&#1078;&#1072;&#1085;&#1080;&#1077;&ldquo;,
                                        &#1082;&#1086;&#1077;&#1090;&#1086; &#1087;&#1088;&#1077;&#1076;&#1083;&#1072;&#1075;&#1072;
                                        &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; , &#1089;&#1077;
                                        &#1089;&#1098;&#1075;&#1083;&#1072;&#1089;&#1103;&#1074;&#1072; &#1080;
                                        &#1087;&#1088;&#1080;&#1077;&#1084;&#1072; &#1089;&#1083;&#1077;&#1076;&#1085;&#1080;&#1090;&#1077;
                                        &#1054;&#1073;&#1097;&#1080; &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103; &#1079;&#1072;
                                        &#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072; &#1090;&#1086;&#1079;&#1080;
                                        &#1089;&#1072;&#1081;&#1090;.</span></li>
                                <li class="c9 li-bullet-2"><span
                                        class="c2">&#1055;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1103;&#1090;,
                                        &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1097; &#1090;&#1086;&#1079;&#1080;
                                        &#1089;&#1072;&#1081;&#1090;, &#1089;&#1077;
                                        &#1079;&#1072;&#1076;&#1098;&#1083;&#1078;&#1072;&#1074;&#1072; &#1076;&#1072;
                                        &#1089;&#1087;&#1072;&#1079;&#1074;&#1072; &#1090;&#1077;&#1079;&#1080; &#1054;&#1073;&#1097;&#1080;
                                        &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;, &#1082;&#1072;&#1082;&#1090;&#1086; &#1080;
                                        &#1074;&#1089;&#1080;&#1095;&#1082;&#1080;
                                        &#1088;&#1072;&#1079;&#1087;&#1086;&#1088;&#1077;&#1076;&#1073;&#1080; &#1085;&#1072;
                                        &#1073;&#1098;&#1083;&#1075;&#1072;&#1088;&#1089;&#1082;&#1086;&#1090;&#1086; &#1080;
                                        &#1084;&#1077;&#1078;&#1076;&#1091;&#1085;&#1072;&#1088;&#1086;&#1076;&#1085;&#1086;&#1090;&#1086;
                                        &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1090;&#1077;&#1083;&#1089;&#1090;&#1074;&#1086;.
                                        &#1055;&#1088;&#1080; &#1085;&#1077;&#1089;&#1098;&#1075;&#1083;&#1072;&#1089;&#1080;&#1077; &#1089;
                                        O&#1073;&#1097;&#1080;&#1090;&#1077; &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;
                                        &#1055;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1103;&#1090; &#1077;
                                        &#1076;&#1083;&#1098;&#1078;&#1077;&#1085; &#1076;&#1072;
                                        &#1087;&#1088;&#1077;&#1091;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1080;
                                        &#1085;&#1077;&#1079;&#1072;&#1073;&#1072;&#1074;&#1085;&#1086;
                                        &#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086; &#1085;&#1072;
                                        &#1089;&#1072;&#1081;&#1090;&#1072;.
                                        &#1044;&#1086;&#1089;&#1090;&#1072;&#1074;&#1095;&#1080;&#1082;&#1098;&#1090; &#1085;&#1077;
                                        &#1085;&#1086;&#1089;&#1080;
                                        &#1086;&#1090;&#1075;&#1086;&#1074;&#1086;&#1088;&#1085;&#1086;&#1089;&#1090;, &#1072;&#1082;&#1086;
                                        &#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1103;&#1090; &#1085;&#1077;
                                        &#1089;&#1077; &#1077; &#1079;&#1072;&#1087;&#1086;&#1079;&#1085;&#1072;&#1083; &#1089;
                                        &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1080;&#1090;&#1077;
                                        &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;.</span></li>
                            </ol>
                            <p class="c1"><span class="c2"></span></p>
                            <ol class="c3 lst-kix_kx2brxvekfev-0" start="2">
                                <li class="c13 li-bullet-3"><span class="c16">&#1054;&#1089;&#1085;&#1086;&#1074;&#1085;&#1080;
                                        &#1087;&#1086;&#1085;&#1103;&#1090;&#1080;&#1103;</span></li>
                            </ol>
                            <p class="c1"><span class="c2"></span></p>
                            <ol class="c3 lst-kix_1jdc1cdd6929-0 start" start="1">
                                <li class="c9 li-bullet-4"><span class="c2">&#1055;&#1086; &#1089;&#1084;&#1080;&#1089;&#1098;&#1083;&#1072;
                                        &#1085;&#1072; &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1080;&#1090;&#1077;
                                        &#1054;&#1073;&#1097;&#1080; &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;
                                        &#1080;&#1079;&#1073;&#1088;&#1086;&#1077;&#1085;&#1080;&#1090;&#1077;
                                        &#1087;&#1086;&#1085;&#1103;&#1090;&#1080;&#1103; &#1089;&#1072;
                                        &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1080; &#1089;&#1098;&#1089;
                                        &#1089;&#1083;&#1077;&#1076;&#1085;&#1086;&#1090;&#1086;
                                        &#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1080;&#1077;:</span></li>
                            </ol>
                            <ol class="c3 lst-kix_hnckzlyl3s0k-0 start" start="1">
                                <li class="c6 li-bullet-5"><span
                                        class="c2">&quot;&#1055;&#1054;&#1058;&#1056;&#1045;&#1041;&#1048;&#1058;&#1045;&#1051;&quot; &#1077;
                                        &#1074;&#1089;&#1103;&#1082;&#1086; &#1092;&#1080;&#1079;&#1080;&#1095;&#1077;&#1089;&#1082;&#1086;
                                        &#1083;&#1080;&#1094;&#1077;, &#1082;&#1086;&#1077;&#1090;&#1086;
                                        &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072; &#1085;&#1103;&#1082;&#1086;&#1103;
                                        &#1086;&#1090; &#1091;&#1089;&#1083;&#1091;&#1075;&#1080;&#1090;&#1077; &#1085;&#1072;
                                        &#1089;&#1072;&#1081;&#1090;&#1072; &#1085;&#1072;
                                        &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; .</span></li>
                                <li class="c6 li-bullet-6"><span class="c2">&quot;&#1055;&#1040;&#1056;&#1058;&#1053;&#1068;&#1054;&#1056;&quot;
                                        &#1077; &#1074;&#1089;&#1103;&#1082;&#1086; &#1083;&#1080;&#1094;&#1077;, &#1089;
                                        &#1082;&#1086;&#1077;&#1090;&#1086; &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; &nbsp;&#1089;&#1077;
                                        &#1085;&#1072;&#1084;&#1080;&#1088;&#1072; &#1074;
                                        &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1085;&#1080;
                                        &#1086;&#1090;&#1085;&#1086;&#1096;&#1077;&#1085;&#1080;&#1103; &#1080; &#1086;&#1090;
                                        &#1080;&#1084;&#1077;&#1090;&#1086; &#1085;&#1072; &#1082;&#1086;&#1077;&#1090;&#1086;
                                        &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; &nbsp;&#1080;&#1084;&#1072;
                                        &#1087;&#1088;&#1072;&#1074;&#1086; &#1076;&#1072;
                                        &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1103;
                                        &#1076;&#1086;&#1087;&#1098;&#1083;&#1085;&#1080;&#1090;&#1077;&#1083;&#1085;&#1072;
                                        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103; &#1079;&#1072;
                                        &#1085;&#1077;&#1075;&#1086;&#1074;&#1080;
                                        &#1089;&#1090;&#1086;&#1082;&#1080;/&#1091;&#1089;&#1083;&#1091;&#1075;&#1080; &#1085;&#1072;
                                        &#1088;&#1077;&#1075;&#1080;&#1089;&#1090;&#1088;&#1080;&#1088;&#1072;&#1085;&#1080;
                                        &#1055;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1080; &#1085;&#1072;
                                        &#1089;&#1072;&#1081;&#1090;&#1072;, &#1082;&#1086;&#1080;&#1090;&#1086; &#1089;&#1072;
                                        &#1076;&#1072;&#1083;&#1080; &#1089;&#1098;&#1075;&#1083;&#1072;&#1089;&#1080;&#1077;&#1090;&#1086;
                                        &#1089;&#1080; &#1079;&#1072; &#1090;&#1086;&#1074;&#1072;.</span></li>
                                <li class="c6 li-bullet-5"><span
                                        class="c2">&bdquo;&#1045;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1072;
                                        &#1087;&#1088;&#1077;&#1087;&#1088;&#1072;&#1090;&#1082;&#1072;&rdquo; &#1077;
                                        &#1074;&#1088;&#1098;&#1079;&#1082;&#1072;,
                                        &#1086;&#1073;&#1086;&#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1072; &#1074;
                                        &#1089;&#1072;&#1081;&#1090;&#1072; &#1085;&#1072;
                                        &#1092;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072;,
                                        &#1082;&#1086;&#1103;&#1090;&#1086; &#1087;&#1086;&#1079;&#1074;&#1086;&#1083;&#1103;&#1074;&#1072;
                                        &#1072;&#1074;&#1090;&#1086;&#1084;&#1072;&#1090;&#1080;&#1079;&#1080;&#1088;&#1072;&#1085;&#1086;
                                        &#1087;&#1088;&#1077;&#1087;&#1088;&#1072;&#1097;&#1072;&#1085;&#1077; &#1082;&#1098;&#1084;
                                        &#1076;&#1088;&#1091;&#1075;&#1072; &#1048;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090;
                                        &#1089;&#1090;&#1088;&#1072;&#1085;&#1080;&#1094;&#1072;,
                                        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1086;&#1085;&#1077;&#1085;
                                        &#1088;&#1077;&#1089;&#1091;&#1088;&#1089; &#1080;&#1083;&#1080; &#1086;&#1073;&#1077;&#1082;&#1090;
                                        &#1095;&#1088;&#1077;&#1079;
                                        &#1089;&#1090;&#1072;&#1085;&#1076;&#1072;&#1088;&#1090;&#1080;&#1079;&#1080;&#1088;&#1072;&#1085;&#1080;
                                        &#1087;&#1088;&#1086;&#1090;&#1086;&#1082;&#1086;&#1083;&#1080;.</span></li>
                                <li class="c6 li-bullet-5"><span class="c2">&bdquo;&#1057;&#1072;&#1081;&#1090;&rdquo; &#1077;
                                        &#1089;&#1072;&#1081;&#1090;&#1072; &#1085;&#1072;
                                        &#1092;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072;,
                                        &#1082;&#1086;&#1077;&#1090;&#1086;
                                        &#1087;&#1088;&#1077;&#1076;&#1089;&#1090;&#1072;&#1074;&#1083;&#1103;&#1074;&#1072;
                                        &#1086;&#1073;&#1086;&#1089;&#1086;&#1073;&#1077;&#1085;&#1086;&#1090;&#1086;
                                        &#1084;&#1103;&#1089;&#1090;&#1086; &#1074;
                                        &#1075;&#1083;&#1086;&#1073;&#1072;&#1083;&#1085;&#1072;&#1090;&#1072;
                                        &#1048;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090; &#1084;&#1088;&#1077;&#1078;&#1072;,
                                        &#1076;&#1086;&#1089;&#1090;&#1098;&#1087;&#1085;&#1086; &#1095;&#1088;&#1077;&#1079;
                                        &#1089;&#1074;&#1086;&#1103; &#1091;&#1085;&#1080;&#1092;&#1080;&#1094;&#1080;&#1088;&#1072;&#1085;
                                        &#1072;&#1076;&#1088;&#1077;&#1089; (URL) &#1087;&#1086;
                                        &#1087;&#1088;&#1086;&#1090;&#1086;&#1082;&#1086;&#1083; HTTP &#1080;&#1083;&#1080; HTTPS &#1080;
                                        &#1089;&#1098;&#1076;&#1098;&#1088;&#1078;&#1072;&#1097;&#1086;
                                        &#1092;&#1072;&#1081;&#1083;&#1086;&#1074;&#1077;,
                                        &#1087;&#1088;&#1086;&#1075;&#1088;&#1072;&#1084;&#1080;, &#1090;&#1077;&#1082;&#1089;&#1090;,
                                        &#1079;&#1074;&#1091;&#1082;, &#1082;&#1072;&#1088;&#1090;&#1080;&#1085;&#1072;,
                                        &#1080;&#1079;&#1086;&#1073;&#1088;&#1072;&#1078;&#1077;&#1085;&#1080;&#1077;,
                                        &#1045;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1080;
                                        &#1087;&#1088;&#1077;&#1087;&#1088;&#1072;&#1090;&#1082;&#1080; &#1080;&#1083;&#1080;
                                        &#1076;&#1088;&#1091;&#1075;&#1080; &#1084;&#1072;&#1090;&#1077;&#1088;&#1080;&#1072;&#1083;&#1080;
                                        &#1080; &#1088;&#1077;&#1089;&#1091;&#1088;&#1089;&#1080;.</span></li>
                                <li class="c6 li-bullet-5"><span class="c2">&bdquo;IP &#1040;&#1076;&#1088;&#1077;&#1089;&rdquo; (&bdquo;IP
                                        address&rdquo;) &#1077; &#1091;&#1085;&#1080;&#1082;&#1072;&#1083;&#1077;&#1085;
                                        &#1080;&#1076;&#1077;&#1085;&#1090;&#1080;&#1092;&#1080;&#1082;&#1072;&#1094;&#1080;&#1086;&#1085;&#1077;&#1085;
                                        &#1085;&#1086;&#1084;&#1077;&#1088;, &#1072;&#1089;&#1086;&#1094;&#1080;&#1080;&#1088;&#1072;&#1097;
                                        &#1091;&#1089;&#1090;&#1088;&#1086;&#1081;&#1089;&#1090;&#1074;&#1086;,
                                        &#1048;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090;
                                        &#1089;&#1090;&#1088;&#1072;&#1085;&#1080;&#1094;&#1072; &#1080;&#1083;&#1080;
                                        &#1088;&#1077;&#1089;&#1091;&#1088;&#1089; &#1085;&#1072;
                                        &#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1103;, &#1087;&#1086;
                                        &#1085;&#1072;&#1095;&#1080;&#1085;, &#1082;&#1086;&#1081;&#1090;&#1086;
                                        &#1087;&#1086;&#1079;&#1074;&#1086;&#1083;&#1103;&#1074;&#1072;
                                        &#1083;&#1086;&#1082;&#1072;&#1083;&#1080;&#1079;&#1080;&#1088;&#1072;&#1085;&#1077;&#1090;&#1086;
                                        &#1080;&#1084; &#1074; &#1075;&#1083;&#1086;&#1073;&#1072;&#1083;&#1085;&#1072;&#1090;&#1072;
                                        &#1048;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090; &#1084;&#1088;&#1077;&#1078;&#1072;.</span>
                                </li>
                                <li class="c6 li-bullet-5"><span
                                        class="c2">&bdquo;&#1048;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1086;&#1085;&#1085;&#1072;
                                        &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1072;&rdquo; &#1077;
                                        &#1091;&#1089;&#1090;&#1088;&#1086;&#1081;&#1089;&#1090;&#1074;&#1086; &#1080;&#1083;&#1080;
                                        &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1072; &#1086;&#1090;
                                        &#1089;&#1074;&#1098;&#1088;&#1079;&#1072;&#1085;&#1080;
                                        &#1091;&#1089;&#1090;&#1088;&#1086;&#1081;&#1089;&#1090;&#1074;&#1072;,
                                        &#1082;&#1086;&#1077;&#1090;&#1086; &#1080;&#1083;&#1080; &#1085;&#1103;&#1082;&#1086;&#1077;
                                        &#1086;&#1090; &#1082;&#1086;&#1080;&#1090;&#1086; &#1077;
                                        &#1087;&#1088;&#1077;&#1076;&#1085;&#1072;&#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1086;
                                        &#1076;&#1072; &#1089;&#1098;&#1093;&#1088;&#1072;&#1085;&#1103;&#1074;&#1072;,
                                        &#1080;&#1079;&#1087;&#1088;&#1072;&#1097;&#1072; &#1080;&#1083;&#1080;
                                        &#1087;&#1086;&#1083;&#1091;&#1095;&#1072;&#1074;&#1072;
                                        &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1080;
                                        &#1076;&#1086;&#1082;&#1091;&#1084;&#1077;&#1085;&#1090;&#1080;.</span></li>
                                <li class="c6 li-bullet-6"><span class="c2">&bdquo;&#1057;&#1098;&#1088;&#1074;&#1098;&#1088;&rdquo; &#1077;
                                        &#1091;&#1089;&#1090;&#1088;&#1086;&#1081;&#1089;&#1090;&#1074;&#1086; &#1080;&#1083;&#1080;
                                        &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1072; &#1086;&#1090;
                                        &#1089;&#1074;&#1098;&#1088;&#1079;&#1072;&#1085;&#1080;
                                        &#1091;&#1089;&#1090;&#1088;&#1086;&#1081;&#1089;&#1090;&#1074;&#1072;, &#1085;&#1072;
                                        &#1082;&#1086;&#1077;&#1090;&#1086; &#1080;&#1083;&#1080; &#1085;&#1072;
                                        &#1085;&#1103;&#1082;&#1086;&#1077; &#1086;&#1090; &#1082;&#1086;&#1080;&#1090;&#1086; &#1077;
                                        &#1080;&#1085;&#1089;&#1090;&#1072;&#1083;&#1080;&#1088;&#1072;&#1085;
                                        &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1077;&#1085;
                                        &#1089;&#1086;&#1092;&#1090;&#1091;&#1077;&#1088; &#1079;&#1072;
                                        &#1080;&#1079;&#1087;&#1098;&#1083;&#1085;&#1103;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1079;&#1072;&#1076;&#1072;&#1095;&#1080; &#1074;&#1098;&#1074;
                                        &#1074;&#1088;&#1098;&#1079;&#1082;&#1072; &#1089;&#1098;&#1089;
                                        &#1089;&#1098;&#1093;&#1088;&#1072;&#1085;&#1103;&#1074;&#1072;&#1085;&#1077;,
                                        &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1082;&#1072;,
                                        &#1087;&#1088;&#1080;&#1077;&#1084;&#1072;&#1085;&#1077; &#1080;&#1083;&#1080;
                                        &#1087;&#1088;&#1077;&#1076;&#1072;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;.</span></li>
                                <li class="c6 li-bullet-5"><span
                                        class="c2">&bdquo;&#1048;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1086;&#1085;&#1085;&#1072;
                                        &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1072;&rdquo; &#1077;
                                        &#1091;&#1089;&#1090;&#1088;&#1086;&#1081;&#1089;&#1090;&#1074;&#1086; &#1080;&#1083;&#1080;
                                        &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1072; &#1086;&#1090;
                                        &#1089;&#1074;&#1098;&#1088;&#1079;&#1072;&#1085;&#1080;
                                        &#1091;&#1089;&#1090;&#1088;&#1086;&#1081;&#1089;&#1090;&#1074;&#1072;,
                                        &#1082;&#1086;&#1077;&#1090;&#1086; &#1080;&#1083;&#1080; &#1085;&#1103;&#1082;&#1086;&#1077;
                                        &#1086;&#1090; &#1082;&#1086;&#1080;&#1090;&#1086; &#1077;
                                        &#1087;&#1088;&#1077;&#1076;&#1085;&#1072;&#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1086;
                                        &#1076;&#1072; &#1089;&#1098;&#1093;&#1088;&#1072;&#1085;&#1103;&#1074;&#1072;,
                                        &#1080;&#1079;&#1087;&#1088;&#1072;&#1097;&#1072; &#1080;&#1083;&#1080;
                                        &#1087;&#1086;&#1083;&#1091;&#1095;&#1072;&#1074;&#1072;
                                        &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1080;
                                        &#1076;&#1086;&#1082;&#1091;&#1084;&#1077;&#1085;&#1090;&#1080;.</span></li>
                            </ol>
                            <p class="c1"><span class="c2"></span></p>
                            <p class="c1"><span class="c2"></span></p>
                            <ol class="c3 lst-kix_kx2brxvekfev-0" start="3">
                                <li class="c13 li-bullet-3"><span class="c16">&#1054;&#1073;&#1097;&#1080;
                                        &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103; &#1079;&#1072;
                                        &#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1089;&#1072;&#1081;&#1090;&#1072;</span></li>
                            </ol>
                            <p class="c1"><span class="c2"></span></p>
                            <ol class="c3 lst-kix_o26h6mo9mx00-0 start" start="1">
                                <li class="c14 li-bullet-1"><span
                                        class="c2">&#1055;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1103;&#1090;
                                        &#1080;&#1084;&#1072; &#1087;&#1088;&#1072;&#1074;&#1086; &#1076;&#1072;
                                        &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072; &#1090;&#1086;&#1079;&#1080;
                                        &#1089;&#1072;&#1081;&#1090;, &#1082;&#1072;&#1082;&#1090;&#1086;
                                        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072; &#1080;
                                        &#1084;&#1072;&#1090;&#1077;&#1088;&#1080;&#1072;&#1083;&#1080;&#1090;&#1077;,
                                        &#1089;&#1098;&#1076;&#1098;&#1088;&#1078;&#1072;&#1097;&#1080; &#1089;&#1077; &#1074;
                                        &#1085;&#1077;&#1075;&#1086;, &#1089;&#1072;&#1084;&#1086; &#1079;&#1072;
                                        &#1087;&#1086;&#1079;&#1074;&#1086;&#1083;&#1077;&#1085;&#1080; &#1086;&#1090;
                                        &#1079;&#1072;&#1082;&#1086;&#1085;&#1072; &#1094;&#1077;&#1083;&#1080;, &#1073;&#1077;&#1079;
                                        &#1076;&#1072; &#1085;&#1072;&#1082;&#1098;&#1088;&#1085;&#1103;&#1074;&#1072;
                                        &#1087;&#1088;&#1072;&#1074;&#1072;&#1090;&#1072; &#1080; &#1076;&#1072;
                                        &#1086;&#1075;&#1088;&#1072;&#1085;&#1080;&#1095;&#1072;&#1074;&#1072;
                                        &#1076;&#1086;&#1089;&#1090;&#1098;&#1087;&#1072; &#1076;&#1086; &#1090;&#1086;&#1079;&#1080;
                                        &#1089;&#1072;&#1081;&#1090; &#1085;&#1072; &#1090;&#1088;&#1077;&#1090;&#1072;
                                        &#1089;&#1090;&#1088;&#1072;&#1085;&#1072;.</span></li>
                                <li class="c14 li-bullet-1"><span
                                        class="c2">&#1055;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086; &#1085;&#1072;
                                        &#1089;&#1072;&#1081;&#1090;&#1072; &#1077; &#1087;&#1086;
                                        &#1080;&#1085;&#1080;&#1094;&#1080;&#1072;&#1090;&#1080;&#1074;&#1072;,
                                        &#1078;&#1077;&#1083;&#1072;&#1085;&#1080;&#1077; &#1080; &#1079;&#1072;
                                        &#1089;&#1084;&#1077;&#1090;&#1082;&#1072; &#1085;&#1072;
                                        &#1055;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1103;, &#1087;&#1088;&#1080;
                                        &#1089;&#1087;&#1072;&#1079;&#1074;&#1072;&#1085;&#1077;
                                        &#1086;&#1075;&#1088;&#1072;&#1085;&#1080;&#1095;&#1077;&#1085;&#1080;&#1103;&#1090;&#1072; &#1074;
                                        &#1047;&#1040;&#1055;&#1057;&#1055; &#1074; &#1087;&#1086;&#1083;&#1079;&#1072; &#1085;&#1072;
                                        &#1090;&#1080;&#1090;&#1091;&#1083;&#1103;&#1088;&#1103; &#1085;&#1072;
                                        &#1072;&#1074;&#1090;&#1086;&#1088;&#1089;&#1082;&#1086;&#1090;&#1086;
                                        &#1087;&#1088;&#1072;&#1074;&#1086;
                                        &ndash;&nbsp;&#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; &nbsp;&#1080;&#1083;&#1080;
                                        &#1090;&#1088;&#1077;&#1090;&#1072; &#1089;&#1090;&#1088;&#1072;&#1085;&#1072;.</span></li>
                                <li class="c14 li-bullet-1"><span class="c2">&#1053;&#1072;
                                        &#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1103; &#1077;
                                        &#1079;&#1072;&#1073;&#1088;&#1072;&#1085;&#1077;&#1085;&#1086;
                                        &#1089;&#1083;&#1077;&#1076;&#1085;&#1086;&#1090;&#1086;:</span></li>
                            </ol>
                            <ol class="c3 lst-kix_sbgnmifq4okn-0 start" start="1">
                                <li class="c26 li-bullet-7"><span class="c2">&#1044;&#1072; &#1087;&#1088;&#1086;&#1084;&#1077;&#1085;&#1103;,
                                        &#1082;&#1086;&#1087;&#1080;&#1088;&#1072;,
                                        &#1074;&#1098;&#1079;&#1087;&#1088;&#1086;&#1080;&#1079;&#1074;&#1077;&#1078;&#1076;&#1072;
                                        (&#1094;&#1103;&#1083;&#1086;&#1089;&#1090;&#1085;&#1086; &#1080;&#1083;&#1080;
                                        &#1095;&#1072;&#1089;&#1090;&#1080;&#1095;&#1085;&#1086;),
                                        &#1087;&#1091;&#1073;&#1083;&#1080;&#1082;&#1091;&#1074;&#1072;,
                                        &#1080;&#1079;&#1087;&#1088;&#1072;&#1097;&#1072; (&#1087;&#1086;
                                        &#1082;&#1072;&#1082;&#1098;&#1074;&#1090;&#1086; &#1080; &#1076;&#1072; &#1077;
                                        &#1085;&#1072;&#1095;&#1080;&#1085;), &#1079;&#1072;&#1077;&#1084;&#1072;,
                                        &#1087;&#1088;&#1086;&#1076;&#1072;&#1074;&#1072;, &#1089;&#1098;&#1079;&#1076;&#1072;&#1074;&#1072;
                                        &#1074;&#1090;&#1086;&#1088;&#1080;&#1095;&#1085;&#1080;
                                        &#1084;&#1072;&#1090;&#1077;&#1088;&#1080;&#1072;&#1083;&#1080; &#1085;&#1072;
                                        &#1073;&#1072;&#1079;&#1072;&#1090;&#1072; &#1085;&#1072;
                                        &#1089;&#1098;&#1076;&#1098;&#1088;&#1078;&#1072;&#1085;&#1080;&#1077;&#1090;&#1086; &#1074;
                                        &#1089;&#1072;&#1081;&#1090;&#1072;, &#1076;&#1072;
                                        &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072; &#1089;
                                        &#1090;&#1098;&#1088;&#1075;&#1086;&#1074;&#1089;&#1082;&#1072; &#1094;&#1077;&#1083;
                                        &#1080;&#1083;&#1080; &#1076;&#1072;
                                        &#1088;&#1072;&#1079;&#1087;&#1088;&#1086;&#1089;&#1090;&#1088;&#1072;&#1085;&#1103;&#1074;&#1072;
                                        &#1087;&#1086; &#1082;&#1072;&#1082;&#1098;&#1074;&#1090;&#1086; &#1080; &#1076;&#1072; &#1077;
                                        &#1076;&#1088;&#1091;&#1075; &#1085;&#1072;&#1095;&#1080;&#1085;
                                        &#1085;&#1080;&#1082;&#1072;&#1082;&#1098;&#1074; &#1077;&#1083;&#1077;&#1084;&#1077;&#1085;&#1090;
                                        &#1086;&#1090; &#1089;&#1098;&#1076;&#1098;&#1088;&#1078;&#1072;&#1085;&#1080;&#1077;&#1090;&#1086;
                                        &#1085;&#1072; &#1090;&#1086;&#1079;&#1080; &#1089;&#1072;&#1081;&#1090;, &#1073;&#1077;&#1079;
                                        &#1087;&#1088;&#1077;&#1076;&#1074;&#1072;&#1088;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086;
                                        &#1087;&#1080;&#1089;&#1084;&#1077;&#1085;&#1086;
                                        &#1088;&#1072;&#1079;&#1088;&#1077;&#1096;&#1077;&#1085;&#1080;&#1077;
                                        &#1086;&#1090;&nbsp;&#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; . </span></li>
                                <li class="c26 li-bullet-8"><span class="c2">&#1044;&#1072;
                                        &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;
                                        &#1085;&#1077;&#1087;&#1088;&#1072;&#1074;&#1086;&#1084;&#1077;&#1088;&#1085;&#1086;
                                        &#1089;&#1072;&#1081;&#1090;&#1072; &#1080;&#1083;&#1080;
                                        &#1089;&#1098;&#1076;&#1098;&#1088;&#1078;&#1072;&#1085;&#1080;&#1077;&#1090;&#1086; &#1074;
                                        &#1085;&#1077;&#1075;&#1086; (&#1074; &#1090;.&#1095;., &#1085;&#1086; &#1085;&#1077;
                                        &#1089;&#1072;&#1084;&#1086;, &#1080;&#1079;&#1087;&#1088;&#1072;&#1097;&#1072;&#1085;&#1077;
                                        &#1080;&#1083;&#1080; &#1087;&#1088;&#1077;&#1076;&#1072;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1084;&#1072;&#1090;&#1077;&#1088;&#1080;&#1072;&#1083;&#1080; &#1089;&#1098;&#1089;
                                        &#1079;&#1072;&#1087;&#1083;&#1072;&#1096;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086;,
                                        &#1085;&#1077;&#1074;&#1103;&#1088;&#1085;&#1086;,
                                        &#1087;&#1086;&#1076;&#1074;&#1077;&#1078;&#1076;&#1072;&#1097;&#1086;,
                                        &#1086;&#1089;&#1082;&#1098;&#1088;&#1073;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086;,
                                        &#1086;&#1073;&#1080;&#1076;&#1085;&#1086;,
                                        &#1085;&#1072;&#1088;&#1091;&#1096;&#1072;&#1074;&#1072;&#1097;&#1086;
                                        &#1082;&#1086;&#1085;&#1082;&#1091;&#1088;&#1077;&#1085;&#1094;&#1080;&#1103;&#1090;&#1072;,
                                        &#1086;&#1082;&#1083;&#1077;&#1074;&#1077;&#1090;&#1103;&#1074;&#1072;&#1097;&#1086;,
                                        &#1085;&#1077;&#1087;&#1088;&#1080;&#1083;&#1080;&#1095;&#1085;&#1086; &#1080;&#1083;&#1080;
                                        &#1076;&#1088;&#1091;&#1075; &#1074;&#1080;&#1076;
                                        &#1085;&#1077;&#1079;&#1072;&#1082;&#1086;&#1085;&#1085;&#1086;
                                        &#1089;&#1098;&#1076;&#1098;&#1088;&#1078;&#1072;&#1085;&#1080;&#1077; &#1080;&#1083;&#1080;
                                        &#1082;&#1072;&#1082;&#1074;&#1080;&#1090;&#1086; &#1080; &#1076;&#1072; &#1089;&#1072;
                                        &#1084;&#1072;&#1090;&#1077;&#1088;&#1080;&#1072;&#1083;&#1080;, &#1082;&#1086;&#1080;&#1090;&#1086;
                                        &#1087;&#1088;&#1077;&#1076;&#1089;&#1090;&#1072;&#1074;&#1083;&#1103;&#1074;&#1072;&#1090;
                                        &#1080;&#1083;&#1080; &#1087;&#1086;&#1076;&#1073;&#1091;&#1078;&#1076;&#1072;&#1090;
                                        &#1087;&#1086;&#1074;&#1077;&#1076;&#1077;&#1085;&#1080;&#1077;, &#1082;&#1086;&#1077;&#1090;&#1086;
                                        &#1084;&#1086;&#1078;&#1077; &#1076;&#1072; &#1073;&#1098;&#1076;&#1077;
                                        &#1082;&#1074;&#1072;&#1083;&#1080;&#1092;&#1080;&#1094;&#1080;&#1088;&#1072;&#1085;&#1086;
                                        &#1082;&#1072;&#1090;&#1086;
                                        &#1087;&#1088;&#1077;&#1089;&#1090;&#1098;&#1087;&#1083;&#1077;&#1085;&#1080;&#1077;, &#1076;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1076;&#1077; &#1076;&#1086;
                                        &#1075;&#1088;&#1072;&#1078;&#1076;&#1072;&#1085;&#1089;&#1082;&#1072; &#1080;&#1083;&#1080;
                                        &#1085;&#1072;&#1082;&#1072;&#1079;&#1072;&#1090;&#1077;&#1083;&#1085;&#1072;
                                        &#1086;&#1090;&#1075;&#1086;&#1074;&#1086;&#1088;&#1085;&#1086;&#1089;&#1090; &#1080;&#1083;&#1080;
                                        &#1087;&#1086; &#1076;&#1088;&#1091;&#1075; &#1085;&#1072;&#1095;&#1080;&#1085; &#1076;&#1072;
                                        &#1085;&#1072;&#1088;&#1091;&#1096;&#1080; &#1079;&#1072;&#1082;&#1086;&#1085;&#1080;&#1090;&#1077;
                                        &#1085;&#1072; &#1056;&#1077;&#1087;&#1091;&#1073;&#1083;&#1080;&#1082;&#1072;
                                        &#1041;&#1098;&#1083;&#1075;&#1072;&#1088;&#1080;&#1103;).</span></li>
                                <li class="c26 li-bullet-7"><span class="c2">&#1048;&#1079;&#1088;&#1080;&#1095;&#1085;&#1086; &#1085;&#1077;
                                        &#1089;&#1077; &#1088;&#1072;&#1079;&#1088;&#1077;&#1096;&#1072;&#1074;&#1072;
                                        &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086; &#1085;&#1072;
                                        &#1082;&#1072;&#1082;&#1098;&#1074;&#1090;&#1086; &#1080; &#1076;&#1072; &#1077;
                                        &#1089;&#1086;&#1092;&#1090;&#1091;&#1077;&#1088; &#1080;&#1083;&#1080;
                                        &#1091;&#1089;&#1090;&#1088;&#1086;&#1081;&#1089;&#1090;&#1074;&#1072; &#1089; &#1094;&#1077;&#1083;
                                        &#1076;&#1086;&#1089;&#1090;&#1098;&#1087; &#1080; &#1084;&#1072;&#1089;&#1086;&#1074;&#1086;
                                        &#1082;&#1086;&#1087;&#1080;&#1088;&#1072;&#1085;&#1077;/&#1089;&#1074;&#1072;&#1083;&#1103;&#1085;&#1077;
                                        &#1085;&#1072; &#1089;&#1098;&#1076;&#1098;&#1088;&#1078;&#1072;&#1085;&#1080;&#1077;&#1090;&#1086;
                                        &#1074; &#1089;&#1072;&#1081;&#1090;&#1072;.</span></li>
                                <li class="c26 li-bullet-8"><span class="c2">&#1044;&#1072;
                                        &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072; &#1089;&#1072;&#1081;&#1090;&#1072;,
                                        &#1079;&#1072; &#1076;&#1072; &#1087;&#1086;&#1083;&#1091;&#1095;&#1080;
                                        &#1085;&#1077;&#1086;&#1090;&#1086;&#1088;&#1080;&#1079;&#1080;&#1088;&#1072;&#1085;
                                        &#1076;&#1086;&#1089;&#1090;&#1098;&#1087; &#1076;&#1086; &#1076;&#1088;&#1091;&#1075;&#1080;
                                        &#1082;&#1086;&#1084;&#1087;&#1102;&#1090;&#1098;&#1088;&#1085;&#1080; &#1080;
                                        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1086;&#1085;&#1085;&#1080;
                                        &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1080; &#1080;&#1083;&#1080;
                                        &#1084;&#1088;&#1077;&#1078;&#1080;.</span></li>
                            </ol>
                            <ol class="c3 lst-kix_o26h6mo9mx00-0" start="4">
                                <li class="c18 li-bullet-9"><span class="c2">&#1057; &#1086;&#1075;&#1083;&#1077;&#1076;
                                        &#1087;&#1077;&#1088;&#1080;&#1086;&#1076;&#1080;&#1095;&#1085;&#1086;&#1090;&#1086;
                                        &#1076;&#1086;&#1087;&#1098;&#1083;&#1074;&#1072;&#1085;&#1077; &#1080;
                                        &#1072;&#1082;&#1090;&#1091;&#1072;&#1083;&#1080;&#1079;&#1072;&#1094;&#1080;&#1103; &#1085;&#1072;
                                        &#1059;&#1089;&#1083;&#1091;&#1075;&#1080;&#1090;&#1077;,
                                        &#1090;&#1103;&#1093;&#1085;&#1086;&#1090;&#1086;
                                        &#1091;&#1089;&#1098;&#1074;&#1098;&#1088;&#1096;&#1077;&#1085;&#1089;&#1090;&#1074;&#1072;&#1085;&#1077;
                                        &#1080; &#1088;&#1072;&#1079;&#1096;&#1080;&#1088;&#1103;&#1074;&#1072;&#1085;&#1077;,
                                        &#1082;&#1072;&#1082;&#1090;&#1086; &#1080; &#1074;&#1098;&#1074;
                                        &#1074;&#1088;&#1098;&#1079;&#1082;&#1072; &#1089;
                                        &#1074;&#1098;&#1079;&#1084;&#1086;&#1078;&#1085;&#1080;
                                        &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1090;&#1077;&#1083;&#1085;&#1080;
                                        &#1087;&#1088;&#1086;&#1084;&#1077;&#1085;&#1080;, &#1082;&#1086;&#1080;&#1090;&#1086;
                                        &#1088;&#1077;&#1092;&#1083;&#1077;&#1082;&#1090;&#1080;&#1088;&#1072;&#1090;
                                        &#1074;&#1098;&#1088;&#1093;&#1091; &#1090;&#1103;&#1093;, &#1054;&#1073;&#1097;&#1080;&#1090;&#1077;
                                        &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103; &#1084;&#1086;&#1075;&#1072;&#1090; &#1076;&#1072;
                                        &#1073;&#1098;&#1076;&#1072;&#1090; &#1087;&#1088;&#1086;&#1084;&#1077;&#1085;&#1103;&#1085;&#1080;
                                        &#1077;&#1076;&#1085;&#1086;&#1089;&#1090;&#1088;&#1072;&#1085;&#1085;&#1086; &#1086;&#1090;
                                        &#1092;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072;. &#1058;&#1072;&#1079;&#1080;
                                        &#1087;&#1088;&#1086;&#1084;&#1103;&#1085;&#1072; &#1084;&#1086;&#1078;&#1077; &#1076;&#1072;
                                        &#1089;&#1077; &#1080;&#1079;&#1074;&#1098;&#1088;&#1096;&#1074;&#1072; &#1080; &#1087;&#1088;&#1080;
                                        &#1080;&#1079;&#1084;&#1077;&#1085;&#1077;&#1085;&#1080;&#1077; &#1085;&#1072;
                                        &#1074;&#1080;&#1076;&#1072;, &#1077;&#1089;&#1090;&#1077;&#1089;&#1090;&#1074;&#1086;&#1090;&#1086;
                                        &#1080;&#1083;&#1080;
                                        &#1090;&#1077;&#1093;&#1085;&#1086;&#1083;&#1086;&#1075;&#1080;&#1103;&#1090;&#1072; &#1085;&#1072;
                                        &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1103;&#1085;&#1080;&#1090;&#1077;
                                        &#1059;&#1089;&#1083;&#1091;&#1075;&#1080;, &#1087;&#1088;&#1080;
                                        &#1087;&#1088;&#1077;&#1082;&#1088;&#1072;&#1090;&#1103;&#1074;&#1072;&#1085;&#1077;
                                        &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1103;&#1085;&#1077;&#1090;&#1086;
                                        &#1085;&#1072; &#1086;&#1087;&#1088;&#1077;&#1076;&#1077;&#1083;&#1077;&#1085;&#1080;
                                        &#1059;&#1089;&#1083;&#1091;&#1075;&#1080;, &#1082;&#1072;&#1082;&#1090;&#1086; &#1080;
                                        &#1087;&#1088;&#1080; &#1080;&#1079;&#1084;&#1077;&#1085;&#1077;&#1085;&#1080;&#1077; &#1074;
                                        &#1080;&#1082;&#1086;&#1085;&#1086;&#1084;&#1080;&#1095;&#1077;&#1089;&#1082;&#1080;&#1090;&#1077;
                                        &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;.</span></li>
                                <li class="c18 li-bullet-10"><span class="c2">&#1055;&#1088;&#1080;
                                        &#1080;&#1079;&#1074;&#1098;&#1088;&#1096;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1087;&#1088;&#1086;&#1084;&#1077;&#1085;&#1080; &#1074; &#1054;&#1073;&#1097;&#1080;&#1090;&#1077;
                                        &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;,
                                        &#1092;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072; &#1075;&#1080;
                                        &#1076;&#1086;&#1074;&#1077;&#1078;&#1076;&#1072; &#1076;&#1086;
                                        &#1079;&#1085;&#1072;&#1085;&#1080;&#1077;&#1090;&#1086; &#1085;&#1072;
                                        &#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1080;&#1090;&#1077;
                                        &#1095;&#1088;&#1077;&#1079;
                                        &#1087;&#1091;&#1073;&#1083;&#1080;&#1082;&#1091;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086;
                                        &#1080;&#1084; &#1085;&#1072; &#1059;&#1077;&#1073;&#1089;&#1072;&#1081;&#1090;&#1072;.
                                        &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072;
                                        &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1103; &#1085;&#1072;
                                        &#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1080;&#1090;&#1077;
                                        &#1076;&#1074;&#1091;&#1089;&#1077;&#1076;&#1084;&#1080;&#1095;&#1077;&#1085;
                                        &#1089;&#1088;&#1086;&#1082; &#1076;&#1072; &#1089;&#1077;
                                        &#1079;&#1072;&#1087;&#1086;&#1079;&#1085;&#1072;&#1103;&#1090; &#1089;
                                        &#1087;&#1088;&#1086;&#1084;&#1077;&#1085;&#1080;&#1090;&#1077; &#1074;
                                        &#1054;&#1073;&#1097;&#1080;&#1090;&#1077; &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;,
                                        &#1089;&#1083;&#1077;&#1076; &#1082;&#1086;&#1077;&#1090;&#1086; &#1090;&#1077;
                                        &#1074;&#1083;&#1080;&#1079;&#1072;&#1090; &#1074;
                                        &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1080;&#1077;.</span></li>
                            </ol>
                            <p class="c0"><span class="c2"></span></p>
                            <ol class="c3 lst-kix_dm38neumaoqj-0 start" start="4">
                                <li class="c21 li-bullet-11"><span
                                        class="c16">&#1054;&#1088;&#1075;&#1072;&#1085;&#1080;&#1095;&#1072;&#1074;&#1072;&#1085;&#1077;
                                        &#1085;&#1072;
                                        &#1086;&#1090;&#1075;&#1086;&#1074;&#1086;&#1088;&#1085;&#1086;&#1089;&#1090;&#1090;&#1072;</span></li>
                            </ol>
                            <p class="c22"><span class="c2"></span></p>
                            <ol class="c3 lst-kix_qkca50h8w38s-0 start" start="1">
                                <li class="c12 li-bullet-12"><span class="c2">&#1055;&#1098;&#1083;&#1085;&#1086;&#1090;&#1086;
                                        &#1089;&#1098;&#1076;&#1098;&#1088;&#1078;&#1072;&#1085;&#1080;&#1077; &#1085;&#1072;
                                        &#1089;&#1072;&#1081;&#1090;&#1072; &#1089;&#1077;
                                        &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1103;
                                        &quot;&#1090;&#1072;&#1082;&#1086;&#1074;&#1072;, &#1082;&#1072;&#1082;&#1074;&#1086;&#1090;&#1086;
                                        &#1077;&quot;. &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; &nbsp;&#1077;
                                        &#1087;&#1088;&#1077;&#1076;&#1087;&#1088;&#1080;&#1077;&#1083;&#1086;
                                        &#1074;&#1089;&#1080;&#1095;&#1082;&#1080;
                                        &#1085;&#1077;&#1086;&#1073;&#1093;&#1086;&#1076;&#1080;&#1084;&#1080;
                                        &#1084;&#1077;&#1088;&#1082;&#1080; &#1076;&#1072;
                                        &#1075;&#1072;&#1088;&#1072;&#1085;&#1090;&#1080;&#1088;&#1072;
                                        &#1076;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077;&#1088;&#1085;&#1086;&#1089;&#1090;&#1090;&#1072;,
                                        &#1080;&#1089;&#1090;&#1080;&#1085;&#1085;&#1086;&#1089;&#1090;&#1090;&#1072; &#1080;
                                        &#1087;&#1098;&#1083;&#1085;&#1086;&#1090;&#1072;&#1090;&#1072; &#1085;&#1072;
                                        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072;
                                        &#1090;&#1091;&#1082;, &#1082;&#1072;&#1082;&#1090;&#1086; &#1080;
                                        &#1086;&#1090;&#1089;&#1098;&#1089;&#1090;&#1074;&#1080;&#1077;&#1090;&#1086; &#1085;&#1072;
                                        &#1087;&#1086;&#1076;&#1074;&#1077;&#1078;&#1076;&#1072;&#1097;&#1080;
                                        &#1076;&#1072;&#1085;&#1085;&#1080;.</span></li>
                                <li class="c12 li-bullet-13"><span
                                        class="c2">&#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072; &#1085;&#1077;
                                        &#1085;&#1086;&#1089;&#1080;
                                        &#1086;&#1090;&#1075;&#1086;&#1074;&#1086;&#1088;&#1085;&#1086;&#1089;&#1090; &#1079;&#1072;
                                        &#1085;&#1077;&#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1103;&#1085;&#1077;
                                        &#1085;&#1072; &#1091;&#1089;&#1083;&#1091;&#1075;&#1080;&#1090;&#1077; &#1087;&#1088;&#1080;
                                        &#1085;&#1072;&#1089;&#1090;&#1098;&#1087;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1086;&#1073;&#1089;&#1090;&#1086;&#1103;&#1090;&#1077;&#1083;&#1089;&#1090;&#1074;&#1072;
                                        &#1080;&#1079;&#1074;&#1098;&#1085; &#1085;&#1077;&#1075;&#1086;&#1074;&#1080;&#1103;
                                        &#1082;&#1086;&#1085;&#1090;&#1088;&#1086;&#1083; &ndash; &#1074;
                                        &#1089;&#1083;&#1091;&#1095;&#1072;&#1080; &#1085;&#1072;
                                        &#1085;&#1077;&#1087;&#1088;&#1077;&#1086;&#1076;&#1086;&#1083;&#1080;&#1084;&#1072;
                                        &#1089;&#1080;&#1083;&#1072;, &#1089;&#1083;&#1091;&#1095;&#1072;&#1081;&#1085;&#1080;
                                        &#1089;&#1098;&#1073;&#1080;&#1090;&#1080;&#1103;,
                                        &#1087;&#1088;&#1086;&#1073;&#1083;&#1077;&#1084;&#1080; &#1074;
                                        &#1075;&#1083;&#1086;&#1073;&#1072;&#1083;&#1085;&#1072;&#1090;&#1072;
                                        &#1084;&#1088;&#1077;&#1078;&#1072; &#1048;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090; &#1080;
                                        &#1074;
                                        &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1103;&#1085;&#1077;&#1090;&#1086;
                                        &#1085;&#1072; &#1091;&#1089;&#1083;&#1091;&#1075;&#1080; &#1080;&#1079;&#1074;&#1098;&#1085;
                                        &#1082;&#1086;&#1085;&#1090;&#1088;&#1086;&#1083;&#1072; &#1085;&#1072;
                                        &#1092;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072;,
                                        &#1087;&#1088;&#1086;&#1073;&#1083;&#1077;&#1084;&#1080;,
                                        &#1076;&#1098;&#1083;&#1078;&#1072;&#1097;&#1080; &#1089;&#1077; &#1085;&#1072;
                                        &#1086;&#1073;&#1086;&#1088;&#1091;&#1076;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086; &#1085;&#1072;
                                        &#1055;&#1054;&#1058;&#1056;&#1045;&#1041;&#1048;&#1058;&#1045;&#1051;&#1071;,
                                        &#1082;&#1072;&#1082;&#1090;&#1086; &#1080; &#1087;&#1088;&#1080;
                                        &#1089;&#1083;&#1091;&#1095;&#1072;&#1081; &#1085;&#1072;
                                        &#1085;&#1077;&#1088;&#1077;&#1075;&#1083;&#1072;&#1084;&#1077;&#1085;&#1090;&#1080;&#1088;&#1072;&#1085;
                                        &#1076;&#1086;&#1089;&#1090;&#1098;&#1087; &#1080;&#1083;&#1080;
                                        &#1080;&#1085;&#1090;&#1077;&#1088;&#1074;&#1077;&#1085;&#1094;&#1080;&#1103; &#1085;&#1072;
                                        &#1090;&#1088;&#1077;&#1090;&#1080; &#1083;&#1080;&#1094;&#1072; &#1074;&#1098;&#1074;
                                        &#1092;&#1091;&#1085;&#1082;&#1094;&#1080;&#1086;&#1085;&#1080;&#1088;&#1072;&#1085;&#1077;&#1090;&#1086;
                                        &#1085;&#1072;
                                        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1086;&#1085;&#1085;&#1072;&#1090;&#1072;
                                        &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1072; &#1080;&#1083;&#1080;
                                        &#1089;&#1098;&#1088;&#1074;&#1098;&#1088;&#1080;&#1090;&#1077; &#1085;&#1072;
                                        &#1092;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072;</span></li>
                                <li class="c12 li-bullet-12"><span
                                        class="c2">&#1048;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086;
                                        &#1085;&#1072; &#1089;&#1072;&#1081;&#1090;&#1072;, &#1082;&#1072;&#1082;&#1090;&#1086; &#1080;
                                        &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1080;&#1103;&#1090;&#1072;,
                                        &#1082;&#1086;&#1080;&#1090;&#1086;
                                        &#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1103;&#1090;
                                        &#1087;&#1088;&#1077;&#1076;&#1087;&#1088;&#1080;&#1077;&#1084;&#1072;
                                        &#1074;&#1089;&#1083;&#1077;&#1076;&#1089;&#1090;&#1074;&#1080;&#1077; &#1085;&#1072;
                                        &#1090;&#1086;&#1074;&#1072;, &#1089;&#1072;
                                        &#1086;&#1090;&#1075;&#1086;&#1074;&#1086;&#1088;&#1085;&#1086;&#1089;&#1090;
                                        &#1080;&#1079;&#1094;&#1103;&#1083;&#1086; &#1085;&#1072;
                                        &#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1103;.</span></li>
                                <li class="c12 li-bullet-14"><span class="c2">&#1055;&#1088;&#1080;
                                        &#1085;&#1080;&#1082;&#1072;&#1082;&#1074;&#1080;
                                        &#1086;&#1073;&#1089;&#1090;&#1086;&#1103;&#1090;&#1077;&#1083;&#1089;&#1090;&#1074;&#1072;
                                        &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; ,
                                        &#1085;&#1077;&#1081;&#1085;&#1080;&#1090;&#1077;
                                        &#1089;&#1083;&#1091;&#1078;&#1080;&#1090;&#1077;&#1083;&#1080;,
                                        &#1084;&#1077;&#1085;&#1080;&#1076;&#1078;&#1098;&#1088;&#1080;,
                                        &#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1095;&#1080;&#1094;&#1080;, &#1080;&#1083;&#1080;
                                        &#1076;&#1088;&#1091;&#1075;&#1080; &#1090;&#1088;&#1077;&#1090;&#1080; &#1083;&#1080;&#1094;&#1072;,
                                        &#1089;&#1087;&#1086;&#1084;&#1077;&#1085;&#1072;&#1090;&#1080; &#1085;&#1072;
                                        &#1090;&#1086;&#1079;&#1080; &#1089;&#1072;&#1081;&#1090; &#1080;&#1083;&#1080;
                                        &#1091;&#1095;&#1072;&#1089;&#1090;&#1074;&#1072;&#1083;&#1080; &#1074;
                                        &#1085;&#1077;&#1075;&#1086;&#1074;&#1086;&#1090;&#1086;
                                        &#1089;&#1098;&#1079;&#1076;&#1072;&#1074;&#1072;&#1085;&#1077;, &#1085;&#1077;
                                        &#1085;&#1086;&#1089;&#1103;&#1090;
                                        &#1086;&#1090;&#1075;&#1086;&#1074;&#1086;&#1088;&#1085;&#1086;&#1089;&#1090; &#1079;&#1072;
                                        &#1082;&#1072;&#1082;&#1074;&#1080;&#1090;&#1086; &#1080; &#1076;&#1072; &#1089;&#1072;
                                        &#1097;&#1077;&#1090;&#1080; (&#1074; &#1090;&#1086;&#1074;&#1072; &#1095;&#1080;&#1089;&#1083;&#1086;,
                                        &#1085;&#1086; &#1085;&#1077; &#1089;&#1072;&#1084;&#1086;, &#1087;&#1088;&#1077;&#1082;&#1080;,
                                        &#1082;&#1086;&#1089;&#1074;&#1077;&#1085;&#1080;,
                                        &#1085;&#1072;&#1082;&#1072;&#1079;&#1072;&#1090;&#1077;&#1083;&#1085;&#1080;,
                                        &#1089;&#1087;&#1077;&#1094;&#1080;&#1072;&#1083;&#1085;&#1080;,
                                        &#1089;&#1083;&#1091;&#1095;&#1072;&#1081;&#1085;&#1080;,
                                        &#1087;&#1088;&#1077;&#1076;&#1080;&#1079;&#1074;&#1080;&#1082;&#1072;&#1085;&#1080;,
                                        &#1087;&#1086;&#1089;&#1083;&#1077;&#1076;&#1074;&#1072;&#1097;&#1080;
                                        &#1074;&#1088;&#1077;&#1076;&#1080;, &#1097;&#1077;&#1090;&#1080; &#1086;&#1090;
                                        &#1087;&#1088;&#1086;&#1087;&#1091;&#1089;&#1085;&#1072;&#1090;&#1080;
                                        &#1087;&#1086;&#1083;&#1079;&#1080;, &#1086;&#1090; &#1079;&#1072;&#1075;&#1091;&#1073;&#1072;
                                        &#1085;&#1072; &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;
                                        &#1080;&#1083;&#1080; &#1087;&#1088;&#1077;&#1082;&#1098;&#1089;&#1074;&#1072;&#1085;&#1077;
                                        &#1085;&#1072; &#1076;&#1077;&#1081;&#1085;&#1086;&#1089;&#1090;&#1090;&#1072; &#1080;&#1083;&#1080;
                                        &#1076;&#1088;&#1091;&#1075;&#1080;), &#1087;&#1088;&#1080;&#1095;&#1080;&#1085;&#1077;&#1085;&#1080;
                                        &#1087;&#1086; &#1082;&#1072;&#1082;&#1098;&#1074;&#1090;&#1086; &#1080; &#1076;&#1072; &#1077;
                                        &#1076;&#1088;&#1091;&#1075; &#1085;&#1072;&#1095;&#1080;&#1085;,
                                        &#1089;&#1074;&#1098;&#1088;&#1079;&#1072;&#1085;&#1080; &#1080;&#1083;&#1080;
                                        &#1087;&#1088;&#1086;&#1080;&#1079;&#1090;&#1077;&#1082;&#1083;&#1080; &#1086;&#1090;
                                        &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086;,
                                        &#1085;&#1077;&#1074;&#1098;&#1079;&#1084;&#1086;&#1078;&#1085;&#1086;&#1089;&#1090;&#1090;&#1072;
                                        &#1076;&#1072; &#1073;&#1098;&#1076;&#1077;
                                        &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085; &#1080;&#1083;&#1080;
                                        &#1083;&#1080;&#1087;&#1089;&#1072;&#1090;&#1072; &#1085;&#1072;
                                        &#1076;&#1086;&#1089;&#1090;&#1098;&#1087; &#1076;&#1086; &#1089;&#1072;&#1081;&#1090;&#1072;
                                        &#1080;&#1083;&#1080; &#1085;&#1072; &#1084;&#1072;&#1090;&#1077;&#1088;&#1080;&#1072;&#1083;,
                                        &#1087;&#1091;&#1073;&#1083;&#1080;&#1082;&#1091;&#1074;&#1072;&#1085; &#1074;
                                        &#1085;&#1077;&#1075;&#1086;, &#1082;&#1072;&#1082;&#1090;&#1086; &#1080;
                                        &#1073;&#1086;&#1088;&#1072;&#1074;&#1077;&#1085;&#1077;&#1090;&#1086; &#1089;
                                        &#1085;&#1077;&#1075;&#1086; &#1080;&#1083;&#1080; &#1085;&#1072; &#1076;&#1088;&#1091;&#1075;&#1080;
                                        &#1089;&#1072;&#1081;&#1090;&#1086;&#1074;&#1077;, &#1082;&#1098;&#1084;
                                        &#1082;&#1086;&#1080;&#1090;&#1086; &#1090;&#1086;&#1079;&#1080; &#1089;&#1072;&#1081;&#1090;
                                        &#1087;&#1088;&#1077;&#1087;&#1088;&#1072;&#1097;&#1072;, &#1082;&#1072;&#1082;&#1090;&#1086; &#1080;
                                        &#1085;&#1072; &#1076;&#1088;&#1091;&#1075;&#1080;
                                        &#1084;&#1072;&#1090;&#1077;&#1088;&#1080;&#1072;&#1083;&#1080;,
                                        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;,
                                        &#1087;&#1088;&#1086;&#1076;&#1091;&#1082;&#1090;&#1080; &#1080;&#1083;&#1080;
                                        &#1091;&#1089;&#1083;&#1091;&#1075;&#1080;, &#1082;&#1086;&#1080;&#1090;&#1086; &#1089;&#1077;
                                        &#1089;&#1098;&#1076;&#1098;&#1088;&#1078;&#1072;&#1090; &#1074; &#1085;&#1103;&#1082;&#1086;&#1080;
                                        &#1086;&#1090; &#1090;&#1077;&#1079;&#1080; &#1089;&#1072;&#1081;&#1090;&#1086;&#1074;&#1077;,
                                        &#1085;&#1077;&#1079;&#1072;&#1074;&#1080;&#1089;&#1080;&#1084;&#1086; &#1086;&#1090;
                                        &#1086;&#1089;&#1085;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;&#1090;&#1086; &#1080;
                                        &#1083;&#1080;&#1087;&#1089;&#1072;&#1090;&#1072; &#1085;&#1072;
                                        &#1087;&#1088;&#1077;&#1076;&#1091;&#1087;&#1088;&#1077;&#1078;&#1076;&#1077;&#1085;&#1080;&#1077;
                                        &#1079;&#1072; &#1077;&#1074;&#1077;&#1085;&#1090;&#1091;&#1072;&#1083;&#1085;&#1086;&#1090;&#1086;
                                        &#1074;&#1098;&#1079;&#1085;&#1080;&#1082;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1074;&#1088;&#1077;&#1076;&#1080; &#1080; &#1076;&#1086;&#1088;&#1080; &#1080;
                                        &#1082;&#1086;&#1075;&#1072;&#1090;&#1086; &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; &nbsp;&#1077;
                                        &#1073;&#1080;&#1083;&#1086; &#1091;&#1074;&#1077;&#1076;&#1086;&#1084;&#1077;&#1085; &#1079;&#1072;
                                        &#1074;&#1077;&#1088;&#1086;&#1103;&#1090;&#1085;&#1086;&#1089;&#1090;&#1090;&#1072; &#1086;&#1090;
                                        &#1085;&#1072;&#1089;&#1090;&#1098;&#1087;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1087;&#1086;&#1076;&#1086;&#1073;&#1085;&#1080; &#1097;&#1077;&#1090;&#1080;.</span></li>
                                <li class="c12 li-bullet-12"><span class="c2">&#1042; &#1089;&#1083;&#1091;&#1095;&#1072;&#1080;&#1090;&#1077;
                                        &#1080;&#1079;&#1074;&#1098;&#1085;
                                        &#1087;&#1086;&#1089;&#1086;&#1095;&#1077;&#1085;&#1080;&#1090;&#1077; &#1090;&#1091;&#1082;,
                                        &#1086;&#1090;&#1075;&#1086;&#1074;&#1086;&#1088;&#1085;&#1086;&#1089;&#1090;&#1090;&#1072;
                                        &#1085;&#1072;&nbsp;&#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; &nbsp;&#1089;&#1077;
                                        &#1086;&#1075;&#1088;&#1072;&#1085;&#1080;&#1095;&#1072;&#1074;&#1072; &#1076;&#1086;
                                        &#1084;&#1072;&#1082;&#1089;&#1080;&#1084;&#1072;&#1083;&#1085;&#1086;
                                        &#1076;&#1086;&#1087;&#1091;&#1089;&#1090;&#1080;&#1084;&#1072;&#1090;&#1072;
                                        &#1089;&#1090;&#1077;&#1087;&#1077;&#1085;,
                                        &#1088;&#1072;&#1079;&#1088;&#1077;&#1096;&#1077;&#1085;&#1072; &#1086;&#1090;
                                        &#1079;&#1072;&#1082;&#1086;&#1085;&#1080;&#1090;&#1077; &#1085;&#1072;
                                        &#1056;&#1077;&#1087;&#1091;&#1073;&#1083;&#1080;&#1082;&#1072;
                                        &#1041;&#1098;&#1083;&#1075;&#1072;&#1088;&#1080;&#1103;.</span></li>
                                <li class="c12 li-bullet-15"><span
                                        class="c2">&#1055;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086; &#1085;&#1072;
                                        &#1090;&#1086;&#1079;&#1080; &#1089;&#1072;&#1081;&#1090; &#1084;&#1086;&#1078;&#1077; &#1076;&#1072;
                                        &#1074;&#1086;&#1076;&#1080; &#1076;&#1086; &#1088;&#1072;&#1079;&#1093;&#1086;&#1076;&#1080;
                                        &#1086;&#1090; &#1089;&#1090;&#1088;&#1072;&#1085;&#1072; &#1085;&#1072;
                                        &#1055;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1103;,
                                        &#1082;&#1072;&#1090;&#1086; &#1090;&#1072;&#1082;&#1089;&#1080; &#1079;&#1072;
                                        &#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1082;&#1072; &#1085;&#1072;
                                        &#1048;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090; &#1074;&#1088;&#1098;&#1079;&#1082;&#1072;,
                                        &#1084;&#1086;&#1073;&#1080;&#1083;&#1077;&#1085; &#1076;&#1086;&#1089;&#1090;&#1098;&#1087;
                                        &#1076;&#1086; &#1048;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090; &#1080;
                                        &#1076;&#1088;&#1091;&#1075;&#1080;. &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; &nbsp;&#1085;&#1077;
                                        &#1076;&#1098;&#1083;&#1078;&#1080; &#1085;&#1072;
                                        &#1055;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1103;
                                        &#1085;&#1080;&#1082;&#1072;&#1082;&#1074;&#1080; &#1089;&#1091;&#1084;&#1080; &#1074;&#1098;&#1074;
                                        &#1074;&#1088;&#1098;&#1079;&#1082;&#1072; &#1089; &#1082;&#1072;&#1082;&#1074;&#1080;&#1090;&#1086;
                                        &#1080; &#1076;&#1072; &#1089;&#1072; &#1088;&#1072;&#1079;&#1093;&#1086;&#1076;&#1080; &#1079;&#1072;
                                        &#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086; &#1085;&#1072;
                                        &#1090;&#1086;&#1079;&#1080; &#1089;&#1072;&#1081;&#1090;.</span></li>
                            </ol>
                            <p class="c0"><span class="c2"></span></p>
                            <ol class="c3 lst-kix_bvc343twfrtj-0 start" start="5">
                                <li class="c7 li-bullet-16"><span
                                        class="c16">&#1055;&#1088;&#1077;&#1087;&#1088;&#1072;&#1090;&#1082;&#1080;</span></li>
                            </ol>
                            <p class="c22"><span class="c2"></span></p>
                            <ol class="c3 lst-kix_4rd468mtj0o6-0 start" start="1">
                                <li class="c12 li-bullet-14"><span class="c2">&#1042;&#1098;&#1079;&#1084;&#1086;&#1078;&#1085;&#1086; &#1077;
                                        &#1074; &#1089;&#1072;&#1081;&#1090;&#1072; &#1076;&#1072; &#1080;&#1084;&#1072;
                                        &#1093;&#1080;&#1087;&#1077;&#1088;&#1074;&#1088;&#1098;&#1079;&#1082;&#1080; &#1082;&#1098;&#1084;
                                        &#1091;&#1077;&#1073;&#1089;&#1072;&#1081;&#1090;&#1086;&#1074;&#1077;,
                                        &#1080;&#1079;&#1090;&#1086;&#1095;&#1085;&#1080;&#1094;&#1080; &#1080;&#1083;&#1080;
                                        &#1088;&#1077;&#1082;&#1083;&#1072;&#1084;&#1086;&#1076;&#1072;&#1090;&#1077;&#1083;&#1080;,
                                        &#1082;&#1086;&#1080;&#1090;&#1086; &#1089;&#1072;
                                        &#1089;&#1086;&#1073;&#1089;&#1090;&#1074;&#1077;&#1085;&#1086;&#1089;&#1090; &#1085;&#1072;
                                        &#1090;&#1088;&#1077;&#1090;&#1080; &#1089;&#1090;&#1088;&#1072;&#1085;&#1080;.
                                        &#1044;&#1086;&#1089;&#1090;&#1072;&#1074;&#1095;&#1080;&#1082;&#1098;&#1090; &#1085;&#1077;
                                        &#1085;&#1072;&#1073;&#1083;&#1102;&#1076;&#1072;&#1074;&#1072; &#1090;&#1077;&#1079;&#1080;
                                        &#1087;&#1088;&#1077;&#1087;&#1088;&#1072;&#1090;&#1082;&#1080; &#1074;
                                        &#1057;&#1072;&#1081;&#1090;&#1072;, &#1090;&#1098;&#1081; &#1082;&#1072;&#1090;&#1086; &#1090;&#1077;
                                        &#1089;&#1072; &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1077;&#1085;&#1080;
                                        &#1089;&#1072;&#1084;&#1086; &#1079;&#1072;
                                        &#1091;&#1083;&#1077;&#1089;&#1085;&#1077;&#1085;&#1080;&#1077; &#1085;&#1072;
                                        &#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1080;&#1090;&#1077;.</span></li>
                                <li class="c12 li-bullet-12"><span
                                        class="c2">&#1048;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1081;&#1082;&#1080;
                                        &#1087;&#1088;&#1077;&#1087;&#1088;&#1072;&#1090;&#1082;&#1080;&#1090;&#1077;,
                                        &#1055;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1103;&#1090; &#1077;
                                        &#1085;&#1072;&#1103;&#1089;&#1085;&#1086;, &#1095;&#1077;
                                        &#1085;&#1072;&#1087;&#1091;&#1089;&#1082;&#1072; &#1090;&#1086;&#1079;&#1080;
                                        &#1089;&#1072;&#1081;&#1090;. &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; &#1085;&#1077;
                                        &#1085;&#1086;&#1089;&#1080;
                                        &#1086;&#1090;&#1075;&#1086;&#1074;&#1086;&#1088;&#1085;&#1086;&#1089;&#1090; &#1079;&#1072;
                                        &#1076;&#1086;&#1089;&#1090;&#1098;&#1087;&#1072; &#1082;&#1098;&#1084; &#1090;&#1077;&#1079;&#1080;
                                        &#1074;&#1098;&#1085;&#1096;&#1085;&#1080; &#1089;&#1072;&#1081;&#1090;&#1086;&#1074;&#1077;,
                                        &#1082;&#1072;&#1082;&#1090;&#1086; &#1080; &#1079;&#1072;
                                        &#1090;&#1103;&#1093;&#1085;&#1086;&#1090;&#1086;
                                        &#1089;&#1098;&#1076;&#1098;&#1088;&#1078;&#1072;&#1085;&#1080;&#1077;,
                                        &#1087;&#1098;&#1083;&#1085;&#1086;&#1090;&#1072;,
                                        &#1072;&#1082;&#1090;&#1091;&#1072;&#1083;&#1085;&#1086;&#1089;&#1090;,
                                        &#1076;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077;&#1088;&#1085;&#1086;&#1089;&#1090; &#1080;
                                        &#1087;&#1086;&#1083;&#1077;&#1079;&#1085;&#1086;&#1089;&#1090; &#1085;&#1072;
                                        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072;,
                                        &#1088;&#1077;&#1082;&#1083;&#1072;&#1084;&#1080;&#1090;&#1077;,
                                        &#1087;&#1088;&#1086;&#1076;&#1091;&#1082;&#1090;&#1080;&#1090;&#1077; &#1080;&#1083;&#1080;
                                        &#1076;&#1088;&#1091;&#1075;&#1080; &#1084;&#1072;&#1090;&#1077;&#1088;&#1080;&#1072;&#1083;&#1080;,
                                        &#1089;&#1090;&#1072;&#1085;&#1072;&#1083;&#1080;
                                        &#1076;&#1086;&#1089;&#1090;&#1098;&#1087;&#1085;&#1080; &#1085;&#1072; &#1080;&#1083;&#1080;
                                        &#1095;&#1088;&#1077;&#1079; &#1090;&#1103;&#1093;.
                                        &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; &#1085;&#1077;
                                        &#1085;&#1086;&#1089;&#1080;
                                        &#1086;&#1090;&#1075;&#1086;&#1074;&#1086;&#1088;&#1085;&#1086;&#1089;&#1090; &#1079;&#1072;
                                        &#1087;&#1086;&#1083;&#1080;&#1090;&#1080;&#1082;&#1072;&#1090;&#1072;
                                        &#1086;&#1090;&#1085;&#1086;&#1089;&#1085;&#1086;
                                        &#1079;&#1072;&#1097;&#1080;&#1090;&#1072;&#1090;&#1072; &#1085;&#1072;
                                        &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1076;&#1072;&#1085;&#1085;&#1080;
                                        &#1080;&#1083;&#1080; &#1089;&#1080;&#1075;&#1091;&#1088;&#1085;&#1086;&#1089;&#1090;&#1090;&#1072;
                                        &#1085;&#1072; &#1090;&#1077;&#1079;&#1080; &#1089;&#1072;&#1081;&#1090;&#1086;&#1074;&#1077;.
                                        &#1055;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086; &#1080;&#1084; &#1077;
                                        &#1086;&#1090;&#1075;&#1086;&#1074;&#1086;&#1088;&#1085;&#1086;&#1089;&#1090;
                                        &#1077;&#1076;&#1080;&#1085;&#1089;&#1090;&#1074;&#1077;&#1085;&#1086; &#1085;&#1072;
                                        &#1055;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1103;.
                                        &#1055;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1080;&#1090;&#1077;
                                        &#1089;&#1083;&#1077;&#1076;&#1074;&#1072; &#1076;&#1072;
                                        &#1087;&#1088;&#1077;&#1076;&#1103;&#1074;&#1103;&#1074;&#1072;&#1090;
                                        &#1087;&#1088;&#1077;&#1090;&#1077;&#1085;&#1094;&#1080;&#1080;&#1090;&#1077; &#1089;&#1080;
                                        &#1082;&#1098;&#1084;
                                        &#1072;&#1076;&#1084;&#1080;&#1085;&#1080;&#1089;&#1090;&#1088;&#1072;&#1090;&#1086;&#1088;&#1072;
                                        &#1080;&#1083;&#1080; &#1091;&#1077;&#1073;&#1084;&#1072;&#1089;&#1090;&#1098;&#1088;&#1072;
                                        &#1085;&#1072; &#1089;&#1098;&#1086;&#1090;&#1074;&#1077;&#1090;&#1085;&#1080;&#1103;
                                        &#1074;&#1098;&#1085;&#1096;&#1077;&#1085; &#1089;&#1072;&#1081;&#1090;.</span></li>
                                <li class="c12 li-bullet-13"><span class="c2">&#1055;&#1088;&#1077;&#1087;&#1088;&#1072;&#1090;&#1082;&#1080;
                                        &#1086;&#1090; &#1090;&#1088;&#1077;&#1090;&#1080;
                                        &#1089;&#1090;&#1088;&#1072;&#1085;&#1080;&#1094;&#1080; &#1082;&#1098;&#1084;
                                        &#1089;&#1072;&#1081;&#1090;&#1072; &#1089;&#1072;
                                        &#1076;&#1086;&#1087;&#1091;&#1089;&#1090;&#1080;&#1084;&#1080;, &#1072;&#1082;&#1086; &#1089;&#1072;
                                        &#1080;&#1079;&#1074;&#1098;&#1088;&#1096;&#1077;&#1085;&#1080; &#1074;
                                        &#1087;&#1086;&#1076;&#1093;&#1086;&#1076;&#1103;&#1097;
                                        &#1082;&#1086;&#1085;&#1090;&#1077;&#1082;&#1089;&#1090;. &#1040;&#1082;&#1086; &#1089;&#1072;
                                        &#1074;&#1080; &#1085;&#1077;&#1086;&#1073;&#1093;&#1086;&#1076;&#1080;&#1084;&#1080;
                                        &#1091;&#1090;&#1086;&#1095;&#1085;&#1077;&#1085;&#1080;&#1103; &#1079;&#1072;
                                        &#1074;&#1098;&#1079;&#1084;&#1086;&#1078;&#1085;&#1086;&#1089;&#1090;&#1090;&#1072; &#1076;&#1072;
                                        &#1087;&#1086;&#1089;&#1090;&#1072;&#1074;&#1080;&#1090;&#1077;
                                        &#1087;&#1088;&#1077;&#1087;&#1088;&#1072;&#1090;&#1082;&#1072; &#1074;&#1098;&#1074;
                                        &#1074;&#1072;&#1096;&#1080;&#1103; &#1091;&#1077;&#1073; &#1089;&#1072;&#1081;&#1090;
                                        &#1082;&#1098;&#1084; &#1089;&#1090;&#1088;&#1072;&#1085;&#1080;&#1094;&#1072; &#1086;&#1090;
                                        &#1090;&#1086;&#1079;&#1080; &#1089;&#1072;&#1081;&#1090;, &#1084;&#1086;&#1083;&#1103;,
                                        &#1089;&#1074;&#1098;&#1088;&#1078;&#1077;&#1090;&#1077; &#1089;&#1077; &#1089;
                                        &#1085;&#1072;&#1089;.</span></li>
                            </ol>
                            <p class="c0"><span class="c2"></span></p>
                            <ol class="c3 lst-kix_ouaijkqvvi5f-0 start" start="6">
                                <li class="c4 li-bullet-15"><span class="c16">&#1040;&#1074;&#1090;&#1086;&#1088;&#1089;&#1082;&#1086;
                                        &#1087;&#1088;&#1072;&#1074;&#1086;</span></li>
                            </ol>
                            <p class="c0"><span class="c2"></span></p>
                            <ol class="c3 lst-kix_6ufkt23yw78-0 start" start="1">
                                <li class="c12 li-bullet-17"><span class="c2">&#1055;&#1088;&#1072;&#1074;&#1072;&#1090;&#1072; &#1085;&#1072;
                                        &#1080;&#1085;&#1090;&#1077;&#1083;&#1077;&#1082;&#1090;&#1091;&#1072;&#1083;&#1085;&#1072;
                                        &#1089;&#1086;&#1073;&#1089;&#1090;&#1074;&#1077;&#1085;&#1086;&#1089;&#1090;
                                        &#1074;&#1098;&#1088;&#1093;&#1091; &#1074;&#1089;&#1080;&#1095;&#1082;&#1080;
                                        &#1077;&#1083;&#1077;&#1084;&#1077;&#1085;&#1090;&#1080; &#1085;&#1072;
                                        &#1089;&#1098;&#1076;&#1098;&#1088;&#1078;&#1072;&#1085;&#1080;&#1077;&#1090;&#1086; &#1085;&#1072;
                                        &#1059;&#1077;&#1073;&#1089;&#1072;&#1081;&#1090;&#1072;,
                                        &#1074;&#1082;&#1083;&#1102;&#1095;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086;
                                        &#1076;&#1080;&#1079;&#1072;&#1081;&#1085;,
                                        &#1089;&#1086;&#1092;&#1090;&#1091;&#1077;&#1088;&#1085;&#1080;
                                        &#1087;&#1088;&#1086;&#1075;&#1088;&#1072;&#1084;&#1080;, &#1073;&#1072;&#1079;&#1080;
                                        &#1076;&#1072;&#1085;&#1085;&#1080;, &#1090;&#1077;&#1082;&#1089;&#1090;,
                                        &#1088;&#1080;&#1089;&#1091;&#1085;&#1082;&#1080;, &#1075;&#1088;&#1072;&#1092;&#1080;&#1082;&#1080;,
                                        &#1089;&#1082;&#1080;&#1094;&#1080; &#1080; &#1076;&#1088;&#1091;&#1075;&#1072;
                                        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103; &#1080;&#1083;&#1080;
                                        &#1077;&#1083;&#1077;&#1084;&#1077;&#1085;&#1090;&#1080;,
                                        &#1087;&#1088;&#1077;&#1076;&#1089;&#1090;&#1072;&#1074;&#1083;&#1103;&#1074;&#1072;&#1090;
                                        &#1086;&#1073;&#1077;&#1082;&#1090; &#1085;&#1072;
                                        &#1072;&#1074;&#1090;&#1086;&#1088;&#1089;&#1082;&#1086; &#1087;&#1088;&#1072;&#1074;&#1086;
                                        &#1087;&#1086; &#1089;&#1084;&#1080;&#1089;&#1098;&#1083;&#1072; &#1085;&#1072;
                                        &#1047;&#1072;&#1082;&#1086;&#1085;&#1072; &#1079;&#1072;
                                        &#1072;&#1074;&#1090;&#1086;&#1088;&#1089;&#1082;&#1086;&#1090;&#1086;
                                        &#1087;&#1088;&#1072;&#1074;&#1086; &#1080; &#1089;&#1088;&#1086;&#1076;&#1085;&#1080;&#1090;&#1077;
                                        &#1084;&#1091; &#1087;&#1088;&#1072;&#1074;&#1072; &#1080;/&#1080;&#1083;&#1080;
                                        &#1047;&#1072;&#1082;&#1086;&#1085;&#1072; &#1079;&#1072;
                                        &#1084;&#1072;&#1088;&#1082;&#1080;&#1090;&#1077; &#1080;
                                        &#1075;&#1077;&#1086;&#1075;&#1088;&#1072;&#1092;&#1089;&#1082;&#1080;&#1090;&#1077;
                                        &#1086;&#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1080;&#1103; &#1080; &#1089;&#1072;
                                        &#1087;&#1088;&#1080;&#1090;&#1077;&#1078;&#1072;&#1085;&#1080;&#1077; &#1085;&#1072;
                                        &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo;
                                        &nbsp;&#1080;/&#1080;&#1083;&#1080; &#1089;&#1098;&#1086;&#1090;&#1074;&#1077;&#1090;&#1085;&#1086;
                                        &#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1086;&#1090;&#1086; &#1083;&#1080;&#1094;&#1077;,
                                        &#1087;&#1088;&#1077;&#1086;&#1090;&#1089;&#1090;&#1098;&#1087;&#1080;&#1083;&#1086;
                                        &#1087;&#1088;&#1072;&#1074;&#1086;&#1090;&#1086; &#1085;&#1072;
                                        &#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; &nbsp;&#1080;
                                        &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1080;&#1083;&#1086; &#1079;&#1072;
                                        &#1087;&#1091;&#1073;&#1083;&#1080;&#1082;&#1091;&#1074;&#1072;&#1085;&#1077;
                                        &#1089;&#1098;&#1086;&#1090;&#1074;&#1077;&#1090;&#1085;&#1080;&#1090;&#1077;
                                        &#1084;&#1072;&#1090;&#1077;&#1088;&#1080;&#1072;&#1083;&#1080;, &#1080; &#1085;&#1077;
                                        &#1084;&#1086;&#1075;&#1072;&#1090; &#1076;&#1072; &#1073;&#1098;&#1076;&#1072;&#1090;
                                        &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1080; &#1074;
                                        &#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1080;&#1077; &#1085;&#1072;
                                        &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1072;&#1097;&#1086;&#1090;&#1086;
                                        &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1090;&#1077;&#1083;&#1089;&#1090;&#1074;&#1086;.
                                        &#1042;&#1089;&#1080;&#1095;&#1082;&#1080; &#1087;&#1088;&#1072;&#1074;&#1072; &#1085;&#1072;
                                        &#1080;&#1085;&#1090;&#1077;&#1083;&#1077;&#1082;&#1090;&#1091;&#1072;&#1083;&#1085;&#1072;
                                        &#1089;&#1086;&#1073;&#1089;&#1090;&#1074;&#1077;&#1085;&#1086;&#1089;&#1090;
                                        &#1074;&#1098;&#1088;&#1093;&#1091;
                                        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1086;&#1085;&#1085;&#1080;&#1090;&#1077;
                                        &#1088;&#1077;&#1089;&#1091;&#1088;&#1089;&#1080; &#1080;
                                        &#1084;&#1072;&#1090;&#1077;&#1088;&#1080;&#1072;&#1083;&#1080;,
                                        &#1087;&#1091;&#1073;&#1083;&#1080;&#1082;&#1091;&#1074;&#1072;&#1085;&#1080; &#1085;&#1072;
                                        &#1089;&#1072;&#1081;&#1090;&#1072; &#1089;&#1072; &#1086;&#1073;&#1077;&#1082;&#1090; &#1085;&#1072;
                                        &#1079;&#1072;&#1082;&#1088;&#1080;&#1083;&#1072; &#1087;&#1086;
                                        &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1072;&#1097;&#1080;&#1103;
                                        &#1047;&#1072;&#1082;&#1086;&#1085; &#1079;&#1072;
                                        &#1072;&#1074;&#1090;&#1086;&#1088;&#1089;&#1082;&#1086;&#1090;&#1086;
                                        &#1087;&#1088;&#1072;&#1074;&#1086; &#1080; &#1089;&#1088;&#1086;&#1076;&#1085;&#1080;&#1090;&#1077;
                                        &#1084;&#1091; &#1087;&#1088;&#1072;&#1074;&#1072; &#1080;/&#1080;&#1083;&#1080;
                                        &#1047;&#1072;&#1082;&#1086;&#1085;&#1072; &#1079;&#1072;
                                        &#1084;&#1072;&#1088;&#1082;&#1080;&#1090;&#1077; &#1080;
                                        &#1075;&#1077;&#1086;&#1075;&#1088;&#1072;&#1092;&#1089;&#1082;&#1080;&#1090;&#1077;
                                        &#1086;&#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1080;&#1103;, &#1072;
                                        &#1085;&#1077;&#1088;&#1077;&#1075;&#1083;&#1072;&#1084;&#1077;&#1085;&#1090;&#1080;&#1088;&#1072;&#1085;&#1086;&#1090;&#1086;
                                        &#1080;&#1084; &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077;
                                        &#1087;&#1088;&#1077;&#1076;&#1089;&#1090;&#1072;&#1074;&#1083;&#1103;&#1074;&#1072;
                                        &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1085;&#1072;&#1088;&#1091;&#1096;&#1077;&#1085;&#1080;&#1077;
                                        &#1080; &#1074;&#1086;&#1076;&#1080; &#1076;&#1086;
                                        &#1075;&#1088;&#1072;&#1078;&#1076;&#1072;&#1085;&#1089;&#1082;&#1072;,
                                        &#1072;&#1076;&#1084;&#1080;&#1085;&#1080;&#1089;&#1090;&#1088;&#1072;&#1090;&#1080;&#1074;&#1085;&#1086;&#1085;&#1072;&#1082;&#1072;&#1079;&#1072;&#1090;&#1077;&#1083;&#1085;&#1072;
                                        &#1080; &#1085;&#1072;&#1082;&#1072;&#1079;&#1072;&#1090;&#1077;&#1083;&#1085;&#1072;
                                        &#1086;&#1090;&#1075;&#1086;&#1074;&#1086;&#1088;&#1085;&#1086;&#1089;&#1090; &#1074;
                                        &#1089;&#1098;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1077; &#1089;
                                        &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1072;&#1097;&#1086;&#1090;&#1086;
                                        &#1073;&#1098;&#1083;&#1075;&#1072;&#1088;&#1089;&#1082;&#1086;
                                        &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1090;&#1077;&#1083;&#1089;&#1090;&#1074;&#1086;.</span>
                                </li>
                                <li class="c12 li-bullet-14"><span class="c2">&#1058;&#1086;&#1079;&#1080; &#1091;&#1077;&#1073;
                                        &#1089;&#1072;&#1081;&#1090; &#1084;&#1086;&#1078;&#1077; &#1076;&#1072;
                                        &#1089;&#1098;&#1076;&#1098;&#1088;&#1078;&#1072;
                                        &#1084;&#1072;&#1090;&#1077;&#1088;&#1080;&#1072;&#1083;&#1080;,
                                        &#1085;&#1072;&#1079;&#1074;&#1072;&#1085;&#1080;&#1103; &#1080;&#1083;&#1080;
                                        &#1090;&#1098;&#1088;&#1075;&#1086;&#1074;&#1089;&#1082;&#1080; &#1084;&#1072;&#1088;&#1082;&#1080;,
                                        &#1082;&#1086;&#1080;&#1090;&#1086; &#1089;&#1072;
                                        &#1089;&#1086;&#1073;&#1089;&#1090;&#1074;&#1077;&#1085;&#1086;&#1089;&#1090; &#1085;&#1072;
                                        &#1076;&#1088;&#1091;&#1075;&#1080; &#1082;&#1086;&#1084;&#1087;&#1072;&#1085;&#1080;&#1080;,
                                        &#1086;&#1088;&#1075;&#1072;&#1085;&#1080;&#1079;&#1072;&#1094;&#1080;&#1080; &#1080;
                                        &#1089;&#1076;&#1088;&#1091;&#1078;&#1077;&#1085;&#1080;&#1103;,
                                        &#1079;&#1072;&#1097;&#1080;&#1090;&#1077;&#1085;&#1080; &#1086;&#1090;
                                        &#1047;&#1072;&#1082;&#1086;&#1085;&#1072; &#1079;&#1072;
                                        &#1072;&#1074;&#1090;&#1086;&#1088;&#1089;&#1082;&#1086;&#1090;&#1086;
                                        &#1087;&#1088;&#1072;&#1074;&#1086; &#1080; &#1089;&#1088;&#1086;&#1076;&#1085;&#1080;&#1090;&#1077;
                                        &#1084;&#1091; &#1087;&#1088;&#1072;&#1074;&#1072; (&#1047;&#1040;&#1055;&#1057;&#1055;).</span></li>
                                <li class="c12 li-bullet-15"><span class="c2">&#1048;&#1079;&#1088;&#1080;&#1095;&#1085;&#1086; &#1085;&#1077;
                                        &#1089;&#1077; &#1088;&#1072;&#1079;&#1088;&#1077;&#1096;&#1072;&#1074;&#1072;
                                        &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086; &#1085;&#1072;
                                        &#1082;&#1072;&#1082;&#1074;&#1086;&#1090;&#1086; &#1080; &#1076;&#1072; &#1077;
                                        &#1057;&#1098;&#1076;&#1098;&#1088;&#1078;&#1072;&#1085;&#1080;&#1077; &#1086;&#1090;
                                        &#1089;&#1072;&#1081;&#1090;&#1072;, &#1086;&#1089;&#1074;&#1077;&#1085; &#1087;&#1086;
                                        &#1085;&#1072;&#1095;&#1080;&#1085;, &#1082;&#1086;&#1081;&#1090;&#1086; &#1085;&#1077;
                                        &#1087;&#1088;&#1086;&#1090;&#1080;&#1074;&#1086;&#1088;&#1077;&#1095;&#1080; &#1085;&#1072;
                                        &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1080;&#1090;&#1077;
                                        &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103; &#1079;&#1072;
                                        &#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077;, &#1073;&#1077;&#1079;
                                        &#1087;&#1080;&#1089;&#1084;&#1077;&#1085;&#1086;&#1090;&#1086;
                                        &#1088;&#1072;&#1079;&#1088;&#1077;&#1096;&#1077;&#1085;&#1080;&#1077; &#1085;&#1072;
                                        &#1089;&#1086;&#1073;&#1089;&#1090;&#1074;&#1077;&#1085;&#1080;&#1082;&#1072;
                                        &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; .</span></li>
                                <li class="c12 li-bullet-14"><span class="c2">&#1044;&#1086;&#1089;&#1090;&#1098;&#1087;&#1098;&#1090;
                                        &#1076;&#1086; &#1090;&#1086;&#1079;&#1080; &#1089;&#1072;&#1081;&#1090;
                                        &#1082;&#1072;&#1082;&#1090;&#1086; &#1080; &#1076;&#1086; &#1074;&#1089;&#1103;&#1082;&#1072;
                                        &#1095;&#1072;&#1089;&#1090; &#1086;&#1090;
                                        &#1089;&#1098;&#1076;&#1098;&#1088;&#1078;&#1072;&#1085;&#1080;&#1077;&#1090;&#1086; &#1084;&#1091;
                                        &#1085;&#1077; &#1076;&#1072;&#1074;&#1072; &#1087;&#1086;
                                        &#1085;&#1080;&#1082;&#1072;&#1082;&#1098;&#1074; &#1085;&#1072;&#1095;&#1080;&#1085;
                                        &#1087;&#1088;&#1072;&#1074;&#1086; &#1079;&#1072;
                                        &#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1080;&#1085;&#1090;&#1077;&#1083;&#1077;&#1082;&#1090;&#1091;&#1072;&#1083;&#1085;&#1072;&#1090;&#1072;
                                        &#1089;&#1086;&#1073;&#1089;&#1090;&#1074;&#1077;&#1085;&#1086;&#1089;&#1090; &#1073;&#1077;&#1079;
                                        &#1087;&#1088;&#1077;&#1076;&#1074;&#1072;&#1088;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086;&#1090;&#1086;
                                        &#1087;&#1080;&#1089;&#1084;&#1077;&#1085;&#1086;
                                        &#1088;&#1072;&#1079;&#1088;&#1077;&#1096;&#1077;&#1085;&#1080;&#1077; &#1085;&#1072;
                                        &#1072;&#1074;&#1090;&#1086;&#1088;&#1072; &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; .</span></li>
                            </ol>
                            <p class="c0"><span class="c2"></span></p>
                            <ol class="c3 lst-kix_9mp9nl8pn289-0 start" start="7">
                                <li class="c7 li-bullet-18"><span
                                        class="c16">&#1055;&#1086;&#1074;&#1077;&#1088;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086;&#1089;&#1090;</span>
                                </li>
                            </ol>
                            <p class="c0"><span class="c2"></span></p>
                            <ol class="c3 lst-kix_rsyew665exm2-0 start" start="1">
                                <li class="c24 li-bullet-19"><span class="c2">&#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; &nbsp;&#1089;&#1077;
                                        &#1087;&#1088;&#1080;&#1076;&#1098;&#1088;&#1078;&#1072; &#1082;&#1098;&#1084;
                                        &#1074;&#1080;&#1089;&#1086;&#1082;&#1080;
                                        &#1089;&#1090;&#1072;&#1085;&#1076;&#1072;&#1088;&#1090;&#1080; &#1079;&#1072;
                                        &#1079;&#1072;&#1097;&#1080;&#1090;&#1072; &#1085;&#1072;
                                        &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1076;&#1072;&#1085;&#1085;&#1080;,
                                        &#1082;&#1072;&#1090;&#1086; &#1089;&#1087;&#1072;&#1079;&#1074;&#1072;
                                        &#1087;&#1088;&#1080;&#1083;&#1086;&#1078;&#1080;&#1084;&#1086;&#1090;&#1086;
                                        &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1090;&#1077;&#1083;&#1089;&#1090;&#1074;&#1086;
                                        &#1074; &#1086;&#1073;&#1083;&#1072;&#1089;&#1090;&#1090;&#1072;,
                                        &#1074;&#1082;&#1083;&#1102;&#1095;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086;
                                        &#1087;&#1088;&#1077;&#1076;&#1087;&#1088;&#1080;&#1077;&#1084;&#1072;
                                        &#1085;&#1077;&#1086;&#1073;&#1093;&#1086;&#1076;&#1080;&#1084;&#1080;&#1090;&#1077;
                                        &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1080;&#1103; &#1074;&#1098;&#1074;
                                        &#1074;&#1088;&#1098;&#1079;&#1082;&#1072; &#1089;
                                        &#1056;&#1077;&#1075;&#1083;&#1072;&#1084;&#1077;&#1085;&#1090; 2016/679 &#1085;&#1072;
                                        &#1045;&#1074;&#1088;&#1086;&#1087;&#1077;&#1081;&#1089;&#1082;&#1080;&#1103;
                                        &#1055;&#1072;&#1088;&#1083;&#1072;&#1084;&#1077;&#1085;&#1090; &#1080; &#1085;&#1072;
                                        &#1057;&#1098;&#1074;&#1077;&#1090;&#1072; &#1086;&#1090; 27 &#1072;&#1087;&#1088;&#1080;&#1083; 2016
                                        &#1075;&#1086;&#1076;&#1080;&#1085;&#1072; &#1086;&#1090;&#1085;&#1086;&#1089;&#1085;&#1086;
                                        &#1079;&#1072;&#1097;&#1080;&#1090;&#1072;&#1090;&#1072; &#1085;&#1072;
                                        &#1092;&#1080;&#1079;&#1080;&#1095;&#1077;&#1089;&#1082;&#1080;&#1090;&#1077;
                                        &#1083;&#1080;&#1094;&#1072; &#1074;&#1098;&#1074; &#1074;&#1088;&#1098;&#1079;&#1082;&#1072; &#1089;
                                        &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086;
                                        &#1085;&#1072; &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080; &#1080;
                                        &#1086;&#1090;&#1085;&#1086;&#1089;&#1085;&#1086;
                                        &#1089;&#1074;&#1086;&#1073;&#1086;&#1076;&#1085;&#1086;&#1090;&#1086;
                                        &#1076;&#1074;&#1080;&#1078;&#1077;&#1085;&#1080;&#1077; &#1085;&#1072;
                                        &#1090;&#1072;&#1082;&#1080;&#1074;&#1072; &#1076;&#1072;&#1085;&#1085;&#1080;
                                        (&bdquo;&#1054;&#1073;&#1097; &#1088;&#1077;&#1075;&#1083;&#1072;&#1084;&#1077;&#1085;&#1090;&ldquo;),
                                        &#1074; &#1089;&#1080;&#1083;&#1072; &#1086;&#1090; 25 &#1084;&#1072;&#1081; 2018 &#1075;..
                                        &#1047;&#1072; &#1076;&#1072; &#1089;&#1077; &#1086;&#1089;&#1080;&#1075;&#1091;&#1088;&#1080;
                                        &#1087;&#1088;&#1080;&#1076;&#1098;&#1088;&#1078;&#1072;&#1085;&#1077; &#1082;&#1098;&#1084; &#1080;
                                        &#1089;&#1087;&#1072;&#1079;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1054;&#1073;&#1097;&#1080;&#1103; &#1088;&#1077;&#1075;&#1083;&#1072;&#1084;&#1077;&#1085;&#1090;
                                        &#1087;&#1088;&#1080;
                                        &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1103;&#1085;&#1077; &#1085;&#1072;
                                        &#1059;&#1089;&#1083;&#1091;&#1075;&#1080;&#1090;&#1077;,
                                        &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1080;&#1090;&#1077;
                                        &#1090;&#1077;&#1082;&#1089;&#1090;&#1086;&#1074;&#1077;, &#1082;&#1072;&#1082;&#1090;&#1086; &#1080;
                                        &#1055;&#1086;&#1083;&#1080;&#1090;&#1080;&#1082;&#1072; &#1079;&#1072;
                                        &#1087;&#1086;&#1074;&#1077;&#1088;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086;&#1089;&#1090;
                                        &#1085;&#1072; &#1092;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072; &#1089;&#1072;
                                        &#1086;&#1073;&#1074;&#1098;&#1088;&#1079;&#1074;&#1072;&#1097;&#1080; &#1079;&#1072;
                                        &#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1080;&#1090;&#1077;
                                        &#1087;&#1088;&#1080; &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077;
                                        &#1085;&#1072; &#1059;&#1089;&#1083;&#1091;&#1075;&#1080;&#1090;&#1077;.</span></li>
                                <li class="c13 li-bullet-0"><span class="c2">&#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; &nbsp;&#1085;&#1077;
                                        &#1085;&#1086;&#1089;&#1080;
                                        &#1086;&#1090;&#1075;&#1086;&#1074;&#1086;&#1088;&#1085;&#1086;&#1089;&#1090; &#1079;&#1072;
                                        &#1083;&#1080;&#1095;&#1085;&#1072;&#1090;&#1072;
                                        &#1080;&#1076;&#1077;&#1085;&#1090;&#1080;&#1092;&#1080;&#1094;&#1080;&#1088;&#1072;&#1097;&#1072;
                                        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;,
                                        &#1082;&#1086;&#1103;&#1090;&#1086;
                                        &#1055;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1103;&#1090;
                                        &#1088;&#1072;&#1079;&#1082;&#1088;&#1080;&#1074;&#1072; &#1087;&#1088;&#1077;&#1076;
                                        &#1076;&#1088;&#1091;&#1075;&#1080; &#1089;&#1072;&#1081;&#1090;&#1086;&#1074;&#1077;
                                        &#1095;&#1088;&#1077;&#1079; &#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1083;&#1080;&#1085;&#1082;&#1086;&#1074;&#1077;&#1090;&#1077; &#1082;&#1098;&#1084;
                                        &#1090;&#1103;&#1093;, &#1085;&#1072;&#1084;&#1080;&#1088;&#1072;&#1097;&#1080; &#1089;&#1077;
                                        &#1085;&#1072; &#1089;&#1072;&#1081;&#1090;&#1072;.</span></li>
                                <li class="c13 li-bullet-20"><span
                                        class="c2">&#1048;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072;,
                                        &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1103;&#1085;&#1072; &#1086;&#1090;
                                        &#1055;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1103; &#1087;&#1088;&#1080;
                                        &#1087;&#1086;&#1089;&#1077;&#1097;&#1077;&#1085;&#1080;&#1077;&#1090;&#1086; &#1085;&#1072;
                                        &#1090;&#1086;&#1079;&#1080; &#1089;&#1072;&#1081;&#1090;, &#1089;&#1077;
                                        &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072; &#1079;&#1072;
                                        &#1086;&#1090;&#1075;&#1086;&#1074;&#1072;&#1088;&#1103;&#1085;&#1077; &#1085;&#1072;
                                        &#1086;&#1090;&#1087;&#1088;&#1072;&#1074;&#1077;&#1085;&#1080;&#1090;&#1077;
                                        &#1079;&#1072;&#1087;&#1080;&#1090;&#1074;&#1072;&#1085;&#1080;&#1103;, &#1079;&#1072;
                                        &#1087;&#1086;&#1076;&#1086;&#1073;&#1088;&#1103;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1089;&#1072;&#1081;&#1090;&#1072;, &#1079;&#1072; &#1074;&#1088;&#1098;&#1079;&#1082;&#1072; &#1089;
                                        &#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1080;&#1090;&#1077;,&#1079;&#1072;
                                        &#1074;&#1088;&#1098;&#1079;&#1082;&#1072; &#1089; &#1085;&#1072;&#1096;&#1080;
                                        &#1087;&#1072;&#1088;&#1090;&#1085;&#1100;&#1086;&#1088;&#1080;, &#1079;&#1072;
                                        &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;&#1086;&#1074;&#1080; &#1080;
                                        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1086;&#1085;&#1085;&#1080;
                                        &#1094;&#1077;&#1083;&#1080;, &#1079;&#1072; &#1087;&#1088;&#1080;&#1077;&#1084;&#1072;&#1085;&#1077;
                                        &#1085;&#1072; &#1079;&#1072;&#1103;&#1074;&#1082;&#1080; &#1080;
                                        &#1080;&#1079;&#1087;&#1088;&#1072;&#1097;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1091;&#1089;&#1083;&#1091;&#1075;&#1080; &#1076;&#1086;
                                        &#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1080;&#1090;&#1077; &#1080;
                                        &#1076;&#1088;. &#1055;&#1086; &#1074;&#1088;&#1077;&#1084;&#1077; &#1085;&#1072;
                                        &#1087;&#1086;&#1089;&#1077;&#1097;&#1077;&#1085;&#1080;&#1077;&#1090;&#1086; &#1085;&#1072;
                                        &#1089;&#1072;&#1081;&#1090;&#1072;,
                                        &#1044;&#1086;&#1089;&#1090;&#1072;&#1074;&#1095;&#1080;&#1082;&#1098;&#1090;
                                        &#1087;&#1086;&#1083;&#1091;&#1095;&#1072;&#1074;&#1072;
                                        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103; &#1079;&#1072;
                                        &#1073;&#1088;&#1086;&#1103; &#1085;&#1072;
                                        &#1087;&#1086;&#1089;&#1077;&#1090;&#1080;&#1090;&#1077;&#1083;&#1080;&#1090;&#1077;, IP
                                        &#1072;&#1076;&#1088;&#1077;&#1089;&#1072; &#1085;&#1072;
                                        &#1055;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1103;,
                                        &#1076;&#1072;&#1090;&#1072;&#1090;&#1072; &#1080; &#1095;&#1072;&#1089;&#1072; &#1085;&#1072;
                                        &#1087;&#1086;&#1089;&#1077;&#1097;&#1077;&#1085;&#1080;&#1077;&#1090;&#1086;,
                                        &#1080;&#1079;&#1090;&#1086;&#1095;&#1085;&#1080;&#1082;&#1072; &#1085;&#1072;
                                        &#1079;&#1072;&#1103;&#1074;&#1082;&#1072;&#1090;&#1072; &#1080; &#1076;&#1088;&#1091;&#1075;&#1080;
                                        &#1076;&#1072;&#1085;&#1085;&#1080;, &#1082;&#1086;&#1080;&#1090;&#1086; &#1084;&#1086;&#1078;&#1077;
                                        &#1076;&#1072; &#1087;&#1086;&#1083;&#1079;&#1074;&#1072; &#1079;&#1072;
                                        &#1094;&#1077;&#1083;&#1080;&#1090;&#1077; &#1085;&#1072; &#1089;&#1074;&#1086;&#1103;&#1090;&#1072;
                                        &#1074;&#1098;&#1090;&#1088;&#1077;&#1096;&#1085;&#1072;
                                        &#1089;&#1090;&#1072;&#1090;&#1080;&#1089;&#1090;&#1080;&#1082;&#1072; &#1080;
                                        &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1085;&#1086;
                                        &#1072;&#1076;&#1084;&#1080;&#1085;&#1080;&#1089;&#1090;&#1088;&#1080;&#1088;&#1072;&#1085;&#1077;.</span>
                                </li>
                                <li class="c13 li-bullet-21"><span class="c2">&#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                        &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; &nbsp;&#1080;&#1084;&#1072;
                                        &#1087;&#1088;&#1072;&#1074;&#1086; &#1076;&#1072; &#1087;&#1088;&#1086;&#1084;&#1077;&#1085;&#1103;
                                        &#1087;&#1086;&#1083;&#1080;&#1090;&#1080;&#1082;&#1072;&#1090;&#1072; &#1079;&#1072;
                                        &#1087;&#1086;&#1074;&#1077;&#1088;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086;&#1089;&#1090;,
                                        &#1082;&#1072;&#1082;&#1090;&#1086; &#1054;&#1073;&#1097;&#1080;&#1090;&#1077;
                                        &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103; &#1079;&#1072;
                                        &#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1089;&#1072;&#1081;&#1090;&#1072; &#1087;&#1086; &#1074;&#1089;&#1103;&#1082;&#1086;
                                        &#1074;&#1088;&#1077;&#1084;&#1077;.
                                        &#1047;&#1072;&#1076;&#1098;&#1083;&#1078;&#1077;&#1085;&#1080;&#1077; &#1085;&#1072;
                                        &#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1103; &#1077; &#1076;&#1072;
                                        &#1089;&#1077; &#1079;&#1072;&#1087;&#1086;&#1079;&#1085;&#1072;&#1074;&#1072; &#1089;&#1098;&#1089;
                                        &#1057;&#1098;&#1076;&#1098;&#1088;&#1078;&#1072;&#1085;&#1080;&#1077;&#1090;&#1086; &#1080;
                                        &#1085;&#1072;&#1089;&#1090;&#1098;&#1087;&#1080;&#1083;&#1080;&#1090;&#1077;
                                        &#1087;&#1088;&#1086;&#1084;&#1077;&#1085;&#1080; &#1074; &#1085;&#1077;&#1075;&#1086;.
                                        &#1044;&#1072;&#1090;&#1072;&#1090;&#1072; &#1085;&#1072;
                                        &#1087;&#1086;&#1089;&#1083;&#1077;&#1076;&#1085;&#1072;
                                        &#1072;&#1082;&#1090;&#1091;&#1072;&#1083;&#1080;&#1079;&#1072;&#1094;&#1080;&#1103; &#1085;&#1072;
                                        &#1090;&#1072;&#1079;&#1080; &#1089;&#1090;&#1088;&#1072;&#1085;&#1080;&#1094;&#1072; &#1077;
                                        &#1086;&#1090;&#1073;&#1077;&#1083;&#1103;&#1079;&#1072;&#1085;&#1072; &#1074;
                                        &#1085;&#1072;&#1095;&#1072;&#1083;&#1086;&#1090;&#1086; &#1085;&#1072;
                                        &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;&#1090;&#1072; &#1079;&#1072;
                                        &#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1089;&#1072;&#1081;&#1090;&#1072;.</span></li>
                            </ol>
                            <p class="c1"><span class="c25"></span></p>
                            <ol class="c3 lst-kix_xofdqbw97f46-0 start" start="8">
                                <li class="c17 li-bullet-0"><span
                                        class="c16">&#1055;&#1056;&#1045;&#1050;&#1056;&#1040;&#1058;&#1071;&#1042;&#1040;&#1053;&#1045;
                                        &#1053;&#1040; &#1044;&#1054;&#1043;&#1054;&#1042;&#1054;&#1056;&#1040; </span></li>
                            </ol>
                            <p class="c8"><span class="c2"></span></p>
                            <ol class="c3 lst-kix_h2vt4kiguu7a-0 start" start="1">
                                <li class="c15 li-bullet-0"><span class="c2">&#1054;&#1089;&#1074;&#1077;&#1085; &#1074;
                                        &#1089;&#1083;&#1091;&#1095;&#1072;&#1080;&#1090;&#1077;,
                                        &#1087;&#1088;&#1077;&#1076;&#1074;&#1080;&#1076;&#1077;&#1085;&#1080; &#1074;
                                        &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1080;&#1090;&#1077; &#1054;&#1073;&#1097;&#1080;
                                        &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;,
                                        &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1098;&#1090; &#1084;&#1077;&#1078;&#1076;&#1091;
                                        &#1089;&#1090;&#1088;&#1072;&#1085;&#1080;&#1090;&#1077; &#1089;&#1077;
                                        &#1087;&#1088;&#1077;&#1082;&#1088;&#1072;&#1090;&#1103;&#1074;&#1072; &#1080; &#1087;&#1088;&#1080;
                                        &#1085;&#1072;&#1089;&#1090;&#1098;&#1087;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1085;&#1103;&#1082;&#1086;&#1077; &#1086;&#1090;
                                        &#1089;&#1083;&#1077;&#1076;&#1085;&#1080;&#1090;&#1077;
                                        &#1086;&#1073;&#1089;&#1090;&#1086;&#1103;&#1090;&#1077;&#1083;&#1089;&#1090;&#1074;&#1072;:</span></li>
                            </ol>
                            <ol class="c3 lst-kix_igzi9at7zush-0 start" start="1">
                                <li class="c20 li-bullet-8"><span
                                        class="c2">&#1087;&#1088;&#1077;&#1091;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1103;&#1074;&#1072;&#1085;&#1077;
                                        &#1085;&#1072; &#1076;&#1077;&#1081;&#1085;&#1086;&#1089;&#1090;&#1090;&#1072; &#1085;&#1072;
                                        &#1092;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072;.</span></li>
                                <li class="c20 li-bullet-7"><span
                                        class="c2">&#1087;&#1088;&#1077;&#1082;&#1088;&#1072;&#1090;&#1103;&#1074;&#1072;&#1085;&#1077;
                                        &#1085;&#1072; &#1087;&#1086;&#1076;&#1076;&#1098;&#1088;&#1078;&#1072;&#1085;&#1077;&#1090;&#1086;
                                        &#1085;&#1072; &#1059;&#1077;&#1073;&#1089;&#1072;&#1081;&#1090;&#1072;;</span></li>
                            </ol>
                            <ol class="c3 lst-kix_h2vt4kiguu7a-0" start="2">
                                <li class="c29 li-bullet-22"><span class="c2">&#1042; &#1089;&#1083;&#1091;&#1095;&#1072;&#1081; &#1085;&#1072;
                                        &#1087;&#1088;&#1077;&#1082;&#1088;&#1072;&#1090;&#1103;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072;
                                        &#1092;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072; &#1085;&#1077;
                                        &#1085;&#1086;&#1089;&#1080;
                                        &#1086;&#1090;&#1075;&#1086;&#1074;&#1086;&#1088;&#1085;&#1086;&#1089;&#1090; &#1079;&#1072;
                                        &#1087;&#1088;&#1077;&#1090;&#1098;&#1088;&#1087;&#1077;&#1085;&#1080;
                                        &#1074;&#1088;&#1077;&#1076;&#1080; &#1080;
                                        &#1087;&#1088;&#1086;&#1087;&#1091;&#1089;&#1085;&#1072;&#1090;&#1080;
                                        &#1087;&#1086;&#1083;&#1079;&#1080; &#1086;&#1090;
                                        &#1055;&#1054;&#1058;&#1056;&#1045;&#1041;&#1048;&#1058;&#1045;&#1051;&#1071; &#1080;&#1083;&#1080;
                                        &#1090;&#1088;&#1077;&#1090;&#1080; &#1083;&#1080;&#1094;&#1072;,
                                        &#1085;&#1072;&#1089;&#1090;&#1098;&#1087;&#1080;&#1083;&#1080;
                                        &#1074;&#1089;&#1083;&#1077;&#1076;&#1089;&#1090;&#1074;&#1080;&#1077; &#1085;&#1072;
                                        &#1089;&#1087;&#1080;&#1088;&#1072;&#1085;&#1077;&#1090;&#1086; &#1085;&#1072;
                                        &#1076;&#1086;&#1089;&#1090;&#1098;&#1087;&#1072; &#1085;&#1072;
                                        &#1055;&#1054;&#1058;&#1056;&#1045;&#1041;&#1048;&#1058;&#1045;&#1051;&#1071; &#1076;&#1086;
                                        &#1089;&#1072;&#1081;&#1090;&#1072; &#1080;/&#1080;&#1083;&#1080;
                                        &#1092;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072;,
                                        &#1082;&#1072;&#1082;&#1090;&#1086; &#1080;
                                        &#1074;&#1089;&#1083;&#1077;&#1076;&#1089;&#1090;&#1074;&#1080;&#1077;
                                        &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1103;&#1085;&#1077;&#1090;&#1086;
                                        &#1085;&#1072; &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;
                                        &#1080;&#1083;&#1080; &#1080;&#1079;&#1087;&#1098;&#1083;&#1085;&#1077;&#1085;&#1080;&#1077;
                                        &#1085;&#1072;
                                        &#1088;&#1072;&#1079;&#1087;&#1086;&#1088;&#1077;&#1078;&#1076;&#1072;&#1085;&#1080;&#1103;
                                        &#1085;&#1072;
                                        &#1082;&#1086;&#1084;&#1087;&#1077;&#1090;&#1077;&#1085;&#1090;&#1085;&#1080;&#1090;&#1077;
                                        &#1076;&#1098;&#1088;&#1078;&#1072;&#1074;&#1085;&#1080;
                                        &#1086;&#1088;&#1075;&#1072;&#1085;&#1080;.</span></li>
                            </ol>
                            <p class="c5"><span class="c2"></span></p>
                            <ol class="c3 lst-kix_xofdqbw97f46-0" start="9">
                                <li class="c17 li-bullet-20"><span class="c16">&#1044;&#1088;&#1091;&#1075;&#1080;</span></li>
                            </ol>
                            <p class="c8"><span class="c2"></span></p>
                            <ol class="c3 lst-kix_3qp0d4hi1611-0 start" start="1">
                                <li class="c10 li-bullet-23"><span
                                        class="c2">&#1055;&#1088;&#1077;&#1076;&#1074;&#1080;&#1076;&#1077;&#1085;&#1080;&#1090;&#1077; &#1074;
                                        &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1080;&#1090;&#1077; &#1054;&#1073;&#1097;&#1080;
                                        &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103; &#1087;&#1080;&#1089;&#1084;&#1077;&#1085;&#1080;
                                        &#1080;&#1083;&#1080; &#1077;&#1083;&#1077;&#1082;&#1088;&#1090;&#1086;&#1085;&#1085;&#1080;
                                        &#1080;&#1079;&#1103;&#1074;&#1083;&#1077;&#1085;&#1080;&#1103; &#1080;
                                        &#1089;&#1098;&#1086;&#1073;&#1097;&#1077;&#1085;&#1080;&#1103; &#1089;&#1077;
                                        &#1089;&#1095;&#1080;&#1090;&#1072;&#1090; &#1079;&#1072;
                                        &#1074;&#1072;&#1083;&#1080;&#1076;&#1085;&#1086;
                                        &#1080;&#1079;&#1074;&#1098;&#1088;&#1096;&#1077;&#1085;&#1080;, &#1072;&#1082;&#1086; &#1089;&#1072;
                                        &#1085;&#1072;&#1087;&#1088;&#1072;&#1074;&#1077;&#1085;&#1080; &#1087;&#1086;&#1076;
                                        &#1092;&#1086;&#1088;&#1084;&#1072;&#1090;&#1072; &#1085;&#1072; &#1087;&#1080;&#1089;&#1084;&#1086;
                                        &#1089; &#1086;&#1073;&#1088;&#1072;&#1090;&#1085;&#1072;
                                        &#1088;&#1072;&#1079;&#1087;&#1080;&#1089;&#1082;&#1072;,
                                        &#1092;&#1072;&#1082;&#1089;&#1080;&#1084;&#1080;&#1083;&#1085;&#1086;
                                        &#1089;&#1098;&#1086;&#1073;&#1097;&#1077;&#1085;&#1080;&#1077;,
                                        &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1072; &#1087;&#1086;&#1097;&#1072;,
                                        &#1085;&#1072;&#1090;&#1080;&#1089;&#1082;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1074;&#1080;&#1088;&#1090;&#1091;&#1072;&#1083;&#1077;&#1085; &#1073;&#1091;&#1090;&#1086;&#1085;
                                        &#1074; &#1059;&#1077;&#1073;&#1089;&#1072;&#1081;&#1090;&#1072; &#1080;
                                        &#1076;&#1088;&#1091;&#1075;&#1080; &#1087;&#1086;&#1076;&#1086;&#1073;&#1085;&#1080;,
                                        &#1076;&#1086;&#1082;&#1086;&#1083;&#1082;&#1086;&#1090;&#1086;
                                        &#1080;&#1079;&#1103;&#1074;&#1083;&#1077;&#1085;&#1080;&#1077;&#1090;&#1086; &#1077;
                                        &#1079;&#1072;&#1087;&#1080;&#1089;&#1072;&#1085;&#1086;
                                        &#1090;&#1077;&#1093;&#1085;&#1080;&#1095;&#1077;&#1089;&#1082;&#1080; &#1087;&#1086;
                                        &#1085;&#1072;&#1095;&#1080;&#1085;, &#1082;&#1086;&#1081;&#1090;&#1086; &#1076;&#1072;&#1074;&#1072;
                                        &#1074;&#1098;&#1079;&#1084;&#1086;&#1078;&#1085;&#1086;&#1089;&#1090; &#1076;&#1072;
                                        &#1073;&#1098;&#1076;&#1077;
                                        &#1074;&#1098;&#1079;&#1087;&#1088;&#1086;&#1080;&#1079;&#1074;&#1077;&#1076;&#1077;&#1085;&#1086;.
                                    </span></li>
                                <li class="c10 li-bullet-24" id="h.gjdgxs"><span class="c2">&#1057;
                                        &#1087;&#1088;&#1080;&#1077;&#1084;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1080;&#1090;&#1077; &#1054;&#1073;&#1097;&#1080;
                                        &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103; &#1080;
                                        &#1089;&#1082;&#1083;&#1102;&#1095;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088; &#1087;&#1086;&#1084;&#1077;&#1078;&#1076;&#1091;
                                        &#1089;&#1080;, &#1089;&#1090;&#1088;&#1072;&#1085;&#1080;&#1090;&#1077;
                                        &#1080;&#1079;&#1088;&#1072;&#1079;&#1103;&#1074;&#1072;&#1090;
                                        &#1089;&#1098;&#1075;&#1083;&#1072;&#1089;&#1080;&#1077;&#1090;&#1086; &#1089;&#1080; &#1076;&#1072;
                                        &#1089;&#1095;&#1080;&#1090;&#1072;&#1090;
                                        &#1086;&#1090;&#1087;&#1088;&#1072;&#1074;&#1077;&#1085;&#1080;&#1090;&#1077;
                                        &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1080;
                                        &#1080;&#1079;&#1103;&#1074;&#1083;&#1077;&#1085;&#1080;&#1103;
                                        &#1087;&#1086;&#1084;&#1077;&#1078;&#1076;&#1091; &#1080;&#1084; &#1079;&#1072;
                                        &#1087;&#1086;&#1083;&#1091;&#1095;&#1077;&#1085;&#1080; &#1089;
                                        &#1087;&#1086;&#1089;&#1090;&#1098;&#1087;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086; &#1080;&#1084;
                                        &#1074; &#1087;&#1086;&#1089;&#1086;&#1095;&#1077;&#1085;&#1072;&#1090;&#1072; &#1086;&#1090;
                                        &#1072;&#1076;&#1088;&#1077;&#1089;&#1072;&#1090;&#1072;
                                        &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1086;&#1085;&#1085;&#1072;
                                        &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1072;, &#1073;&#1077;&#1079; &#1079;&#1072;
                                        &#1090;&#1086;&#1074;&#1072; &#1076;&#1072; &#1077;
                                        &#1085;&#1077;&#1086;&#1073;&#1093;&#1086;&#1076;&#1080;&#1084;&#1086;
                                        &#1080;&#1079;&#1088;&#1080;&#1095;&#1085;&#1086;
                                        &#1087;&#1086;&#1090;&#1074;&#1098;&#1088;&#1078;&#1076;&#1072;&#1074;&#1072;&#1085;&#1077;. &#1042;
                                        &#1089;&#1083;&#1091;&#1095;&#1072;&#1081; &#1095;&#1077;
                                        &#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1103;&#1090; &#1077;
                                        &#1087;&#1086;&#1089;&#1086;&#1095;&#1080;&#1083;
                                        &#1085;&#1077;&#1074;&#1072;&#1083;&#1080;&#1076;&#1085;&#1072;
                                        &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1072;
                                        &#1087;&#1086;&#1097;&#1077;&#1085;&#1089;&#1082;&#1072; &#1082;&#1091;&#1090;&#1080;&#1103;,
                                        &#1090;&#1086; &#1080;&#1079;&#1103;&#1074;&#1083;&#1077;&#1085;&#1080;&#1077;&#1090;&#1086;
                                        &#1097;&#1077; &#1089;&#1077; &#1089;&#1095;&#1080;&#1090;&#1072; &#1079;&#1072;
                                        &#1087;&#1086;&#1083;&#1091;&#1095;&#1077;&#1085;&#1086; &#1089;&#1072;&#1084;&#1086; &#1089;
                                        &#1080;&#1079;&#1087;&#1088;&#1072;&#1097;&#1072;&#1085;&#1077;&#1090;&#1086; &#1084;&#1091;
                                        &#1086;&#1090; &#1092;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072;,
                                        &#1076;&#1086;&#1088;&#1080; &#1072;&#1082;&#1086; &#1085;&#1077; &#1077; &#1073;&#1080;&#1083;&#1086;
                                        &#1087;&#1086;&#1083;&#1091;&#1095;&#1077;&#1085;&#1086;. </span></li>
                                <li class="c10 li-bullet-24"><span class="c2">&#1057;&#1090;&#1088;&#1072;&#1085;&#1080;&#1090;&#1077;
                                        &#1089;&#1077; &#1089;&#1098;&#1075;&#1083;&#1072;&#1089;&#1103;&#1074;&#1072;&#1090;, &#1095;&#1077;
                                        &#1074; &#1089;&#1083;&#1091;&#1095;&#1072;&#1081; &#1095;&#1077; &#1085;&#1103;&#1082;&#1086;&#1103;
                                        &#1086;&#1090; &#1082;&#1083;&#1072;&#1091;&#1079;&#1080;&#1090;&#1077; &#1087;&#1086;
                                        &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1080;&#1090;&#1077; &#1054;&#1073;&#1097;&#1080;
                                        &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103; &#1089;&#1077; &#1086;&#1082;&#1072;&#1078;&#1077;
                                        &#1085;&#1077;&#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1080;&#1090;&#1077;&#1083;&#1085;&#1072;,
                                        &#1090;&#1086;&#1074;&#1072; &#1085;&#1103;&#1084;&#1072; &#1076;&#1072;
                                        &#1074;&#1083;&#1077;&#1095;&#1077;
                                        &#1085;&#1077;&#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086;&#1089;&#1090;
                                        &#1085;&#1072; &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072;, &#1085;&#1072;
                                        &#1076;&#1088;&#1091;&#1075;&#1080; &#1082;&#1083;&#1072;&#1091;&#1079;&#1080; &#1080;&#1083;&#1080;
                                        &#1085;&#1077;&#1075;&#1086;&#1074;&#1080; &#1095;&#1072;&#1089;&#1090;&#1080;.
                                        &#1053;&#1077;&#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1080;&#1090;&#1077;&#1083;&#1085;&#1072;&#1090;&#1072;
                                        &#1082;&#1083;&#1072;&#1091;&#1079;&#1072; &#1097;&#1077; &#1073;&#1098;&#1076;&#1077;
                                        &#1079;&#1072;&#1084;&#1077;&#1089;&#1090;&#1077;&#1085;&#1072; &#1086;&#1090;
                                        &#1087;&#1086;&#1074;&#1077;&#1083;&#1080;&#1090;&#1077;&#1083;&#1085;&#1080;&#1090;&#1077;
                                        &#1085;&#1086;&#1088;&#1084;&#1080; &#1085;&#1072; &#1079;&#1072;&#1082;&#1086;&#1085;&#1072;
                                        &#1080;&#1083;&#1080;
                                        &#1091;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1077;&#1085;&#1072;&#1090;&#1072;
                                        &#1087;&#1088;&#1072;&#1082;&#1090;&#1080;&#1082;&#1072;. </span></li>
                                <li class="c10 li-bullet-20"><span class="c2">&#1047;&#1072; &#1074;&#1089;&#1080;&#1095;&#1082;&#1080;
                                        &#1085;&#1077;&#1091;&#1088;&#1077;&#1076;&#1077;&#1085;&#1080; &#1086;&#1090;
                                        &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1080;&#1103;
                                        &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088; &#1074;&#1098;&#1087;&#1088;&#1086;&#1089;&#1080;
                                        &#1089;&#1077; &#1087;&#1088;&#1080;&#1083;&#1072;&#1075;&#1072;&#1090;
                                        &#1088;&#1072;&#1079;&#1087;&#1086;&#1088;&#1077;&#1076;&#1073;&#1080;&#1090;&#1077; &#1085;&#1072;
                                        &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1072;&#1097;&#1086;&#1090;&#1086;
                                        &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1090;&#1077;&#1083;&#1089;&#1090;&#1074;&#1086;
                                        &#1085;&#1072; &#1056;&#1077;&#1087;&#1091;&#1073;&#1083;&#1080;&#1082;&#1072;
                                        &#1041;&#1098;&#1083;&#1075;&#1072;&#1088;&#1080;&#1103;. </span></li>
                                <li class="c11 li-bullet-25"><span class="c2">&#1042;&#1089;&#1080;&#1095;&#1082;&#1080;
                                        &#1089;&#1087;&#1086;&#1088;&#1086;&#1074;&#1077; &#1084;&#1077;&#1078;&#1076;&#1091;
                                        &#1089;&#1090;&#1088;&#1072;&#1085;&#1080;&#1090;&#1077; &#1089;&#1077;
                                        &#1088;&#1077;&#1096;&#1072;&#1074;&#1072;&#1090; &#1074; &#1076;&#1091;&#1093; &#1085;&#1072;
                                        &#1088;&#1072;&#1079;&#1073;&#1080;&#1088;&#1072;&#1090;&#1077;&#1083;&#1089;&#1090;&#1074;&#1086;
                                        &#1080; &#1076;&#1086;&#1073;&#1088;&#1072; &#1074;&#1086;&#1083;&#1103;. &#1042;
                                        &#1089;&#1083;&#1091;&#1095;&#1072;&#1081; &#1095;&#1077;
                                        &#1089;&#1098;&#1075;&#1083;&#1072;&#1089;&#1080;&#1077; &#1085;&#1077; &#1073;&#1098;&#1076;&#1077;
                                        &#1087;&#1086;&#1089;&#1090;&#1080;&#1075;&#1085;&#1072;&#1090;&#1086;,
                                        &#1074;&#1089;&#1080;&#1095;&#1082;&#1080;
                                        &#1085;&#1077;&#1088;&#1072;&#1079;&#1088;&#1077;&#1096;&#1077;&#1085;&#1080;
                                        &#1089;&#1087;&#1086;&#1088;&#1086;&#1074;&#1077;,
                                        &#1087;&#1086;&#1088;&#1086;&#1076;&#1077;&#1085;&#1080; &#1086;&#1090;
                                        &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072; &#1084;&#1077;&#1078;&#1076;&#1091;
                                        &#1089;&#1090;&#1088;&#1072;&#1085;&#1080;&#1090;&#1077; &#1080;&#1083;&#1080;
                                        &#1086;&#1090;&#1085;&#1072;&#1089;&#1103;&#1097;&#1080; &#1089;&#1077; &#1076;&#1086;
                                        &#1085;&#1077;&#1075;&#1086;,
                                        &#1074;&#1082;&#1083;&#1102;&#1095;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086;
                                        &#1089;&#1087;&#1086;&#1088;&#1086;&#1074;&#1077;,
                                        &#1087;&#1086;&#1088;&#1086;&#1076;&#1077;&#1085;&#1080; &#1080;&#1083;&#1080;
                                        &#1086;&#1090;&#1085;&#1072;&#1089;&#1103;&#1097;&#1080; &#1089;&#1077; &#1076;&#1086;
                                        &#1085;&#1077;&#1075;&#1086;&#1074;&#1086;&#1090;&#1086;
                                        &#1090;&#1098;&#1083;&#1082;&#1091;&#1074;&#1072;&#1085;&#1077;,
                                        &#1085;&#1077;&#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086;&#1089;&#1090;,
                                        &#1080;&#1079;&#1087;&#1098;&#1083;&#1085;&#1077;&#1085;&#1080;&#1077; &#1080;&#1083;&#1080;
                                        &#1087;&#1088;&#1077;&#1082;&#1088;&#1072;&#1090;&#1103;&#1074;&#1072;&#1085;&#1077;,
                                        &#1082;&#1072;&#1082;&#1090;&#1086; &#1080; &#1089;&#1087;&#1086;&#1088;&#1086;&#1074;&#1077;
                                        &#1079;&#1072; &#1087;&#1086;&#1087;&#1098;&#1083;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                        &#1087;&#1088;&#1072;&#1079;&#1085;&#1086;&#1090;&#1080; &#1074;
                                        &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1072; &#1080;&#1083;&#1080;
                                        &#1087;&#1088;&#1080;&#1089;&#1087;&#1086;&#1089;&#1086;&#1073;&#1103;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086;
                                        &#1084;&#1091; &#1082;&#1098;&#1084;
                                        &#1085;&#1086;&#1074;&#1086;&#1074;&#1098;&#1079;&#1085;&#1080;&#1082;&#1085;&#1072;&#1083;&#1080;
                                        &#1086;&#1073;&#1089;&#1090;&#1086;&#1103;&#1090;&#1077;&#1083;&#1089;&#1090;&#1074;&#1072;,
                                        &#1097;&#1077; &#1073;&#1098;&#1076;&#1072;&#1090;
                                        &#1088;&#1072;&#1079;&#1088;&#1077;&#1096;&#1072;&#1074;&#1072;&#1085;&#1080; &#1086;&#1090;
                                        &#1082;&#1086;&#1084;&#1087;&#1077;&#1090;&#1077;&#1085;&#1090;&#1085;&#1080;&#1103;
                                        &#1073;&#1098;&#1083;&#1075;&#1072;&#1088;&#1089;&#1082;&#1080; &#1089;&#1098;&#1076; &ndash;
                                        &#1075;&#1088;. &#1057;&#1086;&#1092;&#1080;&#1103;.</span></li>
                            </ol>
                            <p class="c19"><span class="c27"></span></p>
                            </div>
                                )
                                : (
                                <div class="en ccontent">
                                    <ol class="c3 lst-kix_kx2brxvekfev-0 start" start="1">
                                        <li class="c4 li-bullet-0"><span class="c1">General provisions</span></li>
                                    </ol>
                                    <p class="c13"><span class="c2"></span></p>
                                    <ol class="c3 lst-kix_2qbxv6hdtyws-0 start" start="1">
                                        <li class="c11 li-bullet-1"><span class="c2">These General Terms and Conditions apply in relations with the
                                                Users of the website of the joint-stock company &quot;Bridges of Trust&quot; Foundation.</span></li>
                                        <li class="c11 li-bullet-1"><span class="c2">Please read these Terms of Use carefully before using the Bridges
                                                of Trust Foundation website. These General Terms and Conditions constitute an AGREEMENT between you and
                                                the &quot;Bridges of Trust&quot; Foundation, by which you are granted the right to use the services of
                                                the site subject to the conditions below.</span></li>
                                        <li class="c17 li-bullet-2"><span class="c26">Any person, referred to for short as a &quot;user&quot;, who
                                                accesses a website with an address:</span><span class="c26 c28">https://www.mnd.bg/,</span><span
                                                class="c2">&nbsp;hereinafter referred to as the &quot;Site&quot;, and to the information and services
                                                therein, referred to for short as &quot;Content&quot;, which Bridges of Trust Foundation offers, agrees
                                                and accepts the following General Terms and Conditions for the use of this site.</span></li>
                                        <li class="c17 li-bullet-2"><span class="c2">The user using this site undertakes to comply with these General
                                                Terms and Conditions, as well as all provisions of Bulgarian and international legislation. In case of
                                                disagreement with the General Terms and Conditions, the User is obliged to immediately stop using the
                                                site. The provider is not responsible if the user has not familiarized himself with these terms and
                                                conditions.</span></li>
                                    </ol>
                                    <p class="c13"><span class="c2"></span></p>
                                    <ol class="c3 lst-kix_kx2brxvekfev-0" start="2">
                                        <li class="c7 li-bullet-0"><span class="c1">Basic concepts</span></li>
                                    </ol>
                                    <p class="c13"><span class="c2"></span></p>
                                    <ol class="c3 lst-kix_1jdc1cdd6929-0 start" start="1">
                                        <li class="c17 li-bullet-2"><span class="c2">For the purposes of these General Terms and Conditions, the listed
                                                terms are used with the following meaning:</span></li>
                                    </ol>
                                    <ol class="c3 lst-kix_hnckzlyl3s0k-0 start" start="1">
                                        <li class="c0 li-bullet-3"><span class="c2">&quot;USER&quot; is any natural person who uses any of the services
                                                of the Bridges of Trust Foundation website.</span></li>
                                        <li class="c0 li-bullet-3"><span class="c2">&quot;PARTNER&quot; is any person with whom Bridges of Trust
                                                Foundation has a contractual relationship and on whose behalf Bridges of Trust Foundation has the right
                                                to provide additional information about its goods/services to registered Users of the site who are
                                                consent to this.</span></li>
                                        <li class="c0 li-bullet-3"><span class="c2">&quot;Electronic link&quot; is a link designated on the
                                                Foundation&#39;s website that allows automated referral to another Internet page, information resource
                                                or object through standardized protocols.</span></li>
                                        <li class="c0 li-bullet-3"><span class="c2">&quot;Site&quot; is the website of the Foundation, which is the
                                                designated place on the global Internet, accessible through its unified address (URL) under the HTTP or
                                                HTTPS protocol and containing files, programs, text, sound, picture, image, Electronic references or
                                                other materials and resources.</span></li>
                                        <li class="c0 li-bullet-3"><span class="c2">&quot;IP Address&quot; (&quot;IP address&quot;) is a unique
                                                identification number associating a device, Internet page or resource of the user in a way that allows
                                                locating them in the global Internet network.</span></li>
                                        <li class="c0 li-bullet-3"><span class="c2">&quot;Information system&quot; is a device or a system of connected
                                                devices which or any of which is designed to store, send or receive electronic documents.</span></li>
                                        <li class="c0 li-bullet-3"><span class="c2">&quot;Server&quot; is a device or system of connected devices on
                                                which, or on any of which, system software is installed to perform tasks related to the storage,
                                                processing, reception or transmission of information.</span></li>
                                        <li class="c0 li-bullet-3"><span class="c2">&quot;Information system&quot; is a device or a system of connected
                                                devices which or any of which is designed to store, send or receive electronic documents.</span></li>
                                    </ol>
                                    <p class="c13"><span class="c2"></span></p>
                                    <p class="c13"><span class="c2"></span></p>
                                    <ol class="c3 lst-kix_kx2brxvekfev-0" start="3">
                                        <li class="c7 li-bullet-0"><span class="c1">General conditions for using the site</span></li>
                                    </ol>
                                    <p class="c13"><span class="c2"></span></p>
                                    <ol class="c3 lst-kix_o26h6mo9mx00-0 start" start="1">
                                        <li class="c11 li-bullet-4"><span class="c2">The user has the right to use this site, as well as the information
                                                and materials contained in it, only for purposes permitted by law, without infringing the rights and
                                                limiting access to this site of a third party.</span></li>
                                        <li class="c11 li-bullet-4"><span class="c2">The use of the site is on the initiative, desire and at the expense
                                                of the User, subject to the restrictions in the ZAPSP in favor of the copyright holder - &quot;Bridges
                                                of Trust&quot; Foundation or a third party.</span></li>
                                        <li class="c11 li-bullet-4"><span class="c2">The user is prohibited from:</span></li>
                                    </ol>
                                    <ol class="c3 lst-kix_sbgnmifq4okn-0 start" start="1">
                                        <li class="c5 li-bullet-5"><span class="c2">To modify, copy, reproduce (in whole or in part), publish, send (in
                                                any way), borrow, sell, create secondary materials based on the content on the site, use for commercial
                                                purposes or distribute in any way is otherwise any element of the content of this site without the prior
                                                written permission of the Bridges of Trust Foundation.</span></li>
                                        <li class="c5 li-bullet-6"><span class="c2">To misuse the site or its content (including, but not limited to,
                                                sending or transmitting material with threatening, false, misleading, abusive, insulting,
                                                anti-competitive, defamatory, indecent or otherwise illegal content or any are materials that represent
                                                or incite behavior that can be classified as a crime, lead to civil or criminal liability or otherwise
                                                violate the laws of the Republic of Bulgaria).</span></li>
                                        <li class="c5 li-bullet-5"><span class="c2">The use of any software or devices to access and bulk copy/download
                                                the content on the site is expressly prohibited.</span></li>
                                        <li class="c5 li-bullet-5"><span class="c2">Use the Site to gain unauthorized access to other computer and
                                                information systems or networks.</span></li>
                                    </ol>
                                    <ol class="c3 lst-kix_o26h6mo9mx00-0" start="4">
                                        <li class="c29 li-bullet-7"><span class="c2">In view of the periodic addition and update of the Services, their
                                                improvement and expansion, as well as in connection with possible legislative changes that reflect on
                                                them, the General Terms and Conditions can be changed unilaterally by the foundation. This change can
                                                also be made in the event of a change in the type, nature or technology of the Services provided, in the
                                                termination of the provision of certain Services, as well as in the event of a change in economic
                                                conditions.</span></li>
                                        <li class="c29 li-bullet-8"><span class="c2">When making changes to the General Terms and Conditions, the
                                                Foundation brings them to the attention of users by publishing them on the Website. The Foundation
                                                provides users with a two-week period to familiarize themselves with the changes to the General Terms
                                                and Conditions, after which they come into effect.</span></li>
                                    </ol>
                                    <p class="c12"><span class="c2"></span></p>
                                    <ol class="c3 lst-kix_dm38neumaoqj-0 start" start="4">
                                        <li class="c31 li-bullet-9"><span class="c1">Organizing responsibility</span></li>
                                    </ol>
                                    <p class="c24"><span class="c2"></span></p>
                                    <ol class="c3 lst-kix_qkca50h8w38s-0 start" start="1">
                                        <li class="c10 li-bullet-10"><span class="c2">The complete content of the site is provided &quot;as is&quot;.
                                                Bridges of Trust Foundation has taken all necessary measures to ensure the reliability, truthfulness and
                                                completeness of the information here, as well as the absence of misleading data.</span></li>
                                        <li class="c10 li-bullet-11"><span class="c2">The Foundation is not responsible for non-provision of services in
                                                the event of circumstances beyond its control - in cases of force majeure, random events, problems in
                                                the global Internet network and in the provision of services beyond the control of the Foundation,
                                                problems due to the USER&#39;s equipment, as well as and in the event of unauthorized access or
                                                intervention by third parties in the functioning of the foundation&#39;s information system or
                                                servers</span></li>
                                        <li class="c10 li-bullet-12"><span class="c2">The use of the site, as well as the actions that the user takes as
                                                a result, are the sole responsibility of the user.</span></li>
                                        <li class="c10 li-bullet-13"><span class="c2">Under no circumstances shall the Bridges of Trust Foundation, its
                                                employees, managers, suppliers, or other third parties mentioned on this site or involved in its
                                                creation be liable for any damages (including, but not limited to, direct, indirect, punitive, special,
                                                incidental, consequential, consequential damages, lost profits, loss of information or business
                                                interruption or otherwise) caused in any other way, related to or arising out of the use, inability to
                                                be used or the lack of access to the site or material published on it, and the handling of it or other
                                                sites to which this site links, as well as other materials, information, products or services contained
                                                in any of these sites, regardless of the basis and lack of warning of the possible occurrence of damages
                                                and even when Bridges of Trust Foundation has been advised of the possibility of such damages.</span>
                                        </li>
                                        <li class="c10 li-bullet-13"><span class="c2">In cases other than those specified here, the responsibility of
                                                the &quot;Bridges of Trust&quot; Foundation is limited to the maximum extent permitted by the laws of
                                                the Republic of Bulgaria.</span></li>
                                        <li class="c10 li-bullet-11"><span class="c2">The use of this site may lead to costs on the part of the User,
                                                such as fees for the delivery of an Internet connection, mobile Internet access and others. The
                                                &quot;Bridges of Trust&quot; Foundation does not owe the User any sums in connection with any costs for
                                                the use of this site.</span></li>
                                    </ol>
                                    <p class="c12"><span class="c2"></span></p>
                                    <ol class="c3 lst-kix_bvc343twfrtj-0 start" start="5">
                                        <li class="c15 li-bullet-9"><span class="c1">References</span></li>
                                    </ol>
                                    <p class="c24"><span class="c2"></span></p>
                                    <ol class="c3 lst-kix_4rd468mtj0o6-0 start" start="1">
                                        <li class="c10 li-bullet-14"><span class="c2">The Site may contain hyperlinks to websites, sources or
                                                advertisers that are owned by third parties. The Provider does not monitor these links on the Site, as
                                                they are provided only for the convenience of users.</span></li>
                                        <li class="c10 li-bullet-15"><span class="c2">By using the links, the User is aware that he is leaving this
                                                site. Bridges of Trust Foundation is not responsible for access to these external sites, nor for their
                                                content, completeness, timeliness, reliability and usefulness of the information, advertisements,
                                                products or other materials made available on or through them. Bridges of Trust Foundation is not
                                                responsible for the privacy or security policies of these sites. Their use is the sole responsibility of
                                                the User. Users should submit their claims to the administrator or webmaster of the relevant external
                                                site.</span></li>
                                        <li class="c10 li-bullet-16"><span class="c2">Links from third-party pages to the site are permissible if they
                                                are made in an appropriate context. If you need clarification on the possibility of placing a link on
                                                your website to a page from this site, please contact us.</span></li>
                                    </ol>
                                    <p class="c12"><span class="c2"></span></p>
                                    <ol class="c3 lst-kix_ouaijkqvvi5f-0 start" start="6">
                                        <li class="c27 li-bullet-17"><span class="c1">Copyright</span></li>
                                    </ol>
                                    <p class="c12"><span class="c2"></span></p>
                                    <ol class="c3 lst-kix_6ufkt23yw78-0 start" start="1">
                                        <li class="c10 li-bullet-12"><span class="c2">The intellectual property rights in all elements of the content of
                                                the Website, including design, software programs, databases, text, drawings, graphics, sketches and
                                                other information or elements, are the subject of copyright within the meaning of the Copyright and
                                                Related Rights Act and/or the Law on Trademarks and Geographical Indications and are the property of the
                                                Bridges of Trust Foundation and/or the respective designated person who assigned the right of use to the
                                                Bridges of Trust Foundation and provided the relevant materials for publication, and may not be used in
                                                violation of current legislation. All intellectual property rights on the information resources and
                                                materials published on the site are subject to protection under the current Copyright and Related Rights
                                                Act and/or the Trademarks and Geographical Indications Act, and their unregulated use constitutes a
                                                violation of the law and leads to civil, administrative penalties and criminal liability in accordance
                                                with current Bulgarian legislation.</span></li>
                                        <li class="c10 li-bullet-16"><span class="c2">This website may contain material, names or trademarks that are
                                                the property of other companies, organizations and associations protected by the Copyright and Related
                                                Rights Act (CPA).</span></li>
                                        <li class="c10 li-bullet-10"><span class="c2">Use of any Site Content, except in a manner consistent with these
                                                Terms of Use, is expressly prohibited without the written permission of the owner, Bridges of Trust
                                                Foundation.</span></li>
                                        <li class="c10 li-bullet-18"><span class="c2">Access to this site, as well as to any part of its content, does
                                                not in any way give the right to use the intellectual property without the prior written permission of
                                                the author Bridges of Trust Foundation.</span></li>
                                    </ol>
                                    <p class="c12"><span class="c2"></span></p>
                                    <ol class="c3 lst-kix_9mp9nl8pn289-0 start" start="7">
                                        <li class="c15 li-bullet-19"><span class="c1">Confidentiality</span></li>
                                    </ol>
                                    <p class="c12"><span class="c2"></span></p>
                                    <ol class="c3 lst-kix_rsyew665exm2-0 start" start="1">
                                        <li class="c23 li-bullet-20"><span class="c2">The &quot;Bridges of Trust&quot; Foundation adheres to high
                                                standards for the protection of personal data, complying with the applicable legislation in the field,
                                                including taking the necessary actions in relation to Regulation 2016/679 of the European Parliament and
                                                of the Council of April 27, 2016 on the protection of physical persons in connection with the processing
                                                of personal data and regarding the free movement of such data (&quot;General Regulation&quot;),
                                                effective from May 25, 2018. In order to ensure adherence to and compliance with the General Regulation
                                                when providing the Services, these texts, as well as the Foundation&#39;s Privacy Policy are binding on
                                                users when using the Services.</span></li>
                                        <li class="c7 li-bullet-0"><span class="c2">The &quot;Bridges of Trust&quot; Foundation is not responsible for
                                                the personal identifying information that the User discloses to other sites by using the links to them
                                                located on the site.</span></li>
                                        <li class="c7 li-bullet-21"><span class="c2">The information provided by the User when visiting this site is
                                                used to respond to inquiries, to improve the site, to contact users, to contact our partners, for
                                                marketing and informational purposes, to accept requests and send services to consumers, etc. During the
                                                visit to the site, the Provider receives information about the number of visitors, the IP address of the
                                                User, the date and time of the visit, the source of the request and other data that it can use for the
                                                purposes of its internal statistics and system administration.</span></li>
                                        <li class="c7 li-bullet-22"><span class="c2">Bridges of Trust Foundation has the right to change the Privacy
                                                Policy and the Site Terms of Use at any time. It is the user&#39;s responsibility to become familiar
                                                with the Content and any changes made to it. The date this page was last updated is noted at the
                                                beginning of the site&#39;s terms of use.</span></li>
                                    </ol>
                                    <p class="c13"><span class="c26 c30"></span></p>
                                    <ol class="c3 lst-kix_xofdqbw97f46-0 start" start="8">
                                        <li class="c18 li-bullet-21"><span class="c1">TERMINATION</span></li>
                                    </ol>
                                    <p class="c8"><span class="c2"></span></p>
                                    <ol class="c3 lst-kix_h2vt4kiguu7a-0 start" start="1">
                                        <li class="c14 li-bullet-23"><span class="c2">Apart from the cases provided for in these General Terms and
                                                Conditions, the contract between the parties is also terminated upon the occurrence of any of the
                                                following circumstances:</span></li>
                                    </ol>
                                    <ol class="c3 lst-kix_igzi9at7zush-0 start" start="1">
                                        <li class="c16 li-bullet-24"><span class="c2">suspension of the foundation&#39;s activities.</span></li>
                                        <li class="c16 li-bullet-24"><span class="c2">ceasing to maintain the Website;</span></li>
                                    </ol>
                                    <ol class="c3 lst-kix_h2vt4kiguu7a-0" start="2">
                                        <li class="c19 li-bullet-25"><span class="c2">In case of termination of the contract, the foundation is not
                                                responsible for damages suffered and lost benefits by the USER or third parties, which occurred as a
                                                result of the suspension of the USER&#39;s access to the site and/or the foundation, as well as as a
                                                result of the provision of information or execution of orders of the competent state authorities
                                                organs.</span></li>
                                    </ol>
                                    <p class="c25"><span class="c2"></span></p>
                                    <ol class="c3 lst-kix_xofdqbw97f46-0" start="9">
                                        <li class="c18 li-bullet-21"><span class="c1">Others</span></li>
                                    </ol>
                                    <p class="c8"><span class="c2"></span></p>
                                    <ol class="c3 lst-kix_3qp0d4hi1611-0 start" start="1">
                                        <li class="c6 li-bullet-0"><span class="c2">The written or electronic statements and messages provided for in
                                                these General Terms and Conditions are considered to be validly made if they are made in the form of a
                                                letter with return receipt, facsimile message, e-mail, pressing a virtual button on the Website and the
                                                like, insofar as the statement is technically recorded by way that enables it to be reproduced.</span>
                                        </li>
                                        <li class="c6 li-bullet-26" id="h.gjdgxs"><span class="c2">By accepting these General Terms and Conditions and
                                                concluding a contract with each other, the parties express their agreement to consider the electronic
                                                statements made between them as received upon their entry into the information system indicated by the
                                                addressee, without the need for explicit confirmation. In case the user has specified an invalid e-mail
                                                box, the statement will be considered received only when it is sent by the foundation, even if it has
                                                not been received.</span></li>
                                        <li class="c6 li-bullet-27"><span class="c2">The parties agree that in the event that any of the clauses under
                                                these General Terms and Conditions turns out to be invalid, this will not invalidate the contract, other
                                                clauses or its parts. The invalid clause will be superseded by the mandatory rules of law or established
                                                practice.</span></li>
                                        <li class="c6 li-bullet-0"><span class="c2">The provisions of the current legislation of the Republic of
                                                Bulgaria apply to all issues not settled by this contract.</span></li>
                                        <li class="c20 li-bullet-28"><span class="c2">All disputes between the parties shall be resolved in a spirit of
                                                understanding and goodwill. In the event that no agreement is reached, all unresolved disputes arising
                                                out of or relating to the contract between the parties, including disputes arising out of or relating to
                                                its interpretation, invalidity, performance or termination, and disputes to fill gaps in the contract or
                                                its adjustment to new circumstances will be resolved by the competent Bulgarian court - the city of
                                                Sofia.</span></li>
                                    </ol>
                                    <p class="c9"><span class="c22"></span></p>
                                </div>
                                )
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default TermsOfService;