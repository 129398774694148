import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
    const { t, i18n } = useTranslation();

    return (
        <>
        <SEO title={t('linksPrivacyPolicy')} />
            <Layout>
                <BreadcrumbOne 
                    title={t('linksPrivacyPolicy')}
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Privacy Policy"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            {
                                i18n.language === 'bg' ? (
                                    <div className='bg content'>
                                        <p class="c54"><span class="c20"></span></p>
                                        <p class="c45"><span class="c1">&#1042;
                                                &#1089;&#1098;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1077; &#1089;
                                                &#1080;&#1079;&#1080;&#1089;&#1082;&#1074;&#1072;&#1085;&#1080;&#1103;&#1090;&#1072; &#1085;&#1072;
                                                &#1056;&#1077;&#1075;&#1083;&#1072;&#1084;&#1077;&#1085;&#1090; (&#1045;&#1057;) 2016/679 &#1085;&#1072;
                                                &#1045;&#1057; &#1086;&#1090; 27.04.2016 &#1075;. &#1086;&#1090;&#1085;&#1086;&#1089;&#1085;&#1086;
                                                &#1079;&#1072;&#1097;&#1080;&#1090;&#1072;&#1090;&#1072; &#1085;&#1072;
                                                &#1092;&#1080;&#1079;&#1080;&#1095;&#1077;&#1089;&#1082;&#1080;&#1090;&#1077; &#1083;&#1080;&#1094;&#1072;
                                                &#1074;&#1098;&#1074; &#1074;&#1088;&#1098;&#1079;&#1082;&#1072; &#1089;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086; &#1085;&#1072;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080; &#1080;
                                                &#1086;&#1090;&#1085;&#1086;&#1089;&#1085;&#1086;
                                                &#1089;&#1074;&#1086;&#1073;&#1086;&#1076;&#1085;&#1086;&#1090;&#1086;
                                                &#1076;&#1074;&#1080;&#1078;&#1077;&#1085;&#1080;&#1077; &#1085;&#1072;
                                                &#1090;&#1072;&#1082;&#1080;&#1074;&#1072; &#1076;&#1072;&#1085;&#1085;&#1080; &#1080; &#1079;&#1072;
                                                &#1086;&#1090;&#1084;&#1103;&#1085;&#1072; &#1085;&#1072;
                                                &#1044;&#1080;&#1088;&#1077;&#1082;&#1090;&#1080;&#1074;&#1072; 95/46/&#1045;&#1054;
                                                (&#1087;&#1086;-&#1076;&#1086;&#1083;&#1091; &#1089;&#1072;&#1084;&#1086;
                                                &bdquo;&#1056;&#1077;&#1075;&#1083;&#1072;&#1084;&#1077;&#1085;&#1090; 2016/679&ldquo; &#1080;&#1083;&#1080;
                                                &bdquo;&#1054;&#1056;&#1047;&#1044;&ldquo;) &#1080; &#1076;&#1088;&#1091;&#1075;&#1080;&#1090;&#1077;
                                                &#1087;&#1088;&#1080;&#1083;&#1086;&#1078;&#1080;&#1084;&#1080;&#1090;&#1077;
                                                &#1072;&#1082;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                                &#1045;&#1074;&#1088;&#1086;&#1087;&#1077;&#1081;&#1089;&#1082;&#1080;&#1103; &#1089;&#1098;&#1102;&#1079;
                                                &#1080; &#1085;&#1072; &#1056;&#1077;&#1087;&#1091;&#1073;&#1083;&#1080;&#1082;&#1072;
                                                &#1041;&#1098;&#1083;&#1075;&#1072;&#1088;&#1080;&#1103;, &#1089;
                                                &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1086;&#1090;&#1086;
                                                &#1089;&#1098;&#1086;&#1073;&#1097;&#1077;&#1085;&#1080;&#1077;
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                                &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                                &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; &#1074;&#1080;
                                                &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1080;&#1088;&#1072; &#1079;&#1072;
                                                &#1080;&#1079;&#1074;&#1098;&#1088;&#1096;&#1074;&#1072;&#1085;&#1080;&#1090;&#1077; &#1086;&#1090;
                                                &#1085;&#1077;&#1075;&#1086; &#1076;&#1077;&#1081;&#1085;&#1086;&#1089;&#1090;&#1080; &#1087;&#1086;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;,
                                                &#1094;&#1077;&#1083;&#1080;&#1090;&#1077;, &#1079;&#1072; &#1082;&#1086;&#1080;&#1090;&#1086;
                                                &#1089;&#1077; &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1090;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;&#1090;&#1077;, &#1084;&#1077;&#1088;&#1082;&#1080;&#1090;&#1077; &#1080;
                                                &#1075;&#1072;&#1088;&#1072;&#1085;&#1094;&#1080;&#1080;&#1090;&#1077; &#1079;&#1072;
                                                &#1079;&#1072;&#1097;&#1080;&#1090;&#1072;&#1090;&#1072; &#1085;&#1072;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1085;&#1080;&#1090;&#1077;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;, &#1087;&#1088;&#1072;&#1074;&#1072;&#1090;&#1072; &#1074;&#1080;
                                                &#1080; &#1085;&#1072;&#1095;&#1080;&#1085;&#1072;, &#1087;&#1086; &#1082;&#1086;&#1081;&#1090;&#1086;
                                                &#1084;&#1086;&#1078;&#1077; &#1076;&#1072; &#1075;&#1080;
                                                &#1091;&#1087;&#1088;&#1072;&#1078;&#1085;&#1080;&#1090;&#1077;.</span></p>
                                        <p class="c30"><span class="c3"></span></p>
                                        <ol class="c18 lst-kix_list_10-0 start" start="1">
                                            <li class="c26 li-bullet-0" id="h.gjdgxs"><span
                                                    class="c33">&#1042;&#1098;&#1074;&#1077;&#1076;&#1077;&#1085;&#1080;&#1077;</span></li>
                                        </ol>
                                        <p class="c12"><span class="c1">&#1057; &#1090;&#1072;&#1079;&#1080;
                                                &#1087;&#1086;&#1083;&#1080;&#1090;&#1080;&#1082;&#1072; &#1094;&#1077;&#1083;&#1080;&#1084; &#1076;&#1072;
                                                &#1042;&#1080; &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1080;&#1088;&#1072;&#1084;&#1077;
                                                &#1086;&#1090;&#1085;&#1086;&#1089;&#1085;&#1086;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1082;&#1072;&#1090;&#1072; &#1085;&#1072;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;, &#1082;&#1086;&#1080;&#1090;&#1086;
                                                &#1089;&#1098;&#1073;&#1080;&#1088;&#1072;&#1084;&#1077; &#1074;&#1098;&#1074;
                                                &#1074;&#1088;&#1098;&#1079;&#1082;&#1072; &#1089;
                                                &#1080;&#1079;&#1074;&#1098;&#1088;&#1096;&#1074;&#1072;&#1085;&#1072;&#1090;&#1072; &#1086;&#1090;
                                                &#1085;&#1072;&#1089; &#1076;&#1077;&#1081;&#1085;&#1086;&#1089;&#1090;.
                                                &#1051;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1076;&#1072;&#1085;&#1085;&#1080;
                                                &#1086;&#1073;&#1093;&#1074;&#1072;&#1097;&#1072;&#1090; &#1094;&#1103;&#1083;&#1072;&#1090;&#1072;
                                                &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;,
                                                &#1089;&#1074;&#1098;&#1088;&#1079;&#1072;&#1085;&#1072; &#1089;
                                                &#1080;&#1076;&#1077;&#1085;&#1090;&#1080;&#1092;&#1080;&#1094;&#1080;&#1088;&#1072;&#1085;&#1086;
                                                &#1080;&#1083;&#1080; &#1087;&#1086;&#1076;&#1083;&#1077;&#1078;&#1072;&#1097;&#1086; &#1085;&#1072;
                                                &#1080;&#1076;&#1077;&#1085;&#1090;&#1080;&#1092;&#1080;&#1094;&#1080;&#1088;&#1072;&#1085;&#1077;
                                                &#1092;&#1080;&#1079;&#1080;&#1095;&#1077;&#1089;&#1082;&#1086; &#1083;&#1080;&#1094;&#1077;.
                                                &#1058;&#1086;&#1074;&#1072; &#1074;&#1082;&#1083;&#1102;&#1095;&#1074;&#1072;
                                                &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103; &#1082;&#1072;&#1090;&#1086;
                                                &#1080;&#1084;&#1077;, &#1072;&#1076;&#1088;&#1077;&#1089;,
                                                &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1072; &#1087;&#1086;&#1097;&#1072;
                                                &#1080;&#1083;&#1080; &#1090;&#1077;&#1083;&#1077;&#1092;&#1086;&#1085;&#1077;&#1085;
                                                &#1085;&#1086;&#1084;&#1077;&#1088;. &#1048;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;,
                                                &#1082;&#1086;&#1103;&#1090;&#1086; &#1085;&#1077; &#1077; &#1087;&#1088;&#1103;&#1082;&#1086;
                                                &#1089;&#1074;&#1098;&#1088;&#1079;&#1072;&#1085;&#1072; &#1089; &#1042;&#1072;&#1096;&#1072;&#1090;&#1072;
                                                &#1089;&#1072;&#1084;&#1086;&#1083;&#1080;&#1095;&#1085;&#1086;&#1089;&#1090;, &#1085;&#1072;&#1087;&#1088;.
                                                &#1073;&#1088;&#1086;&#1103;&#1090; &#1085;&#1072;
                                                &#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1080;&#1090;&#1077; &#1085;&#1072;
                                                &#1080;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090;
                                                &#1089;&#1090;&#1088;&#1072;&#1085;&#1080;&#1094;&#1072;&#1090;&#1072;, &#1085;&#1077;
                                                &#1087;&#1086;&#1087;&#1072;&#1076;&#1072; &#1074; &#1090;&#1086;&#1079;&#1080;
                                                &#1086;&#1073;&#1093;&#1074;&#1072;&#1090;.</span></p>
                                        <p class="c12"><span class="c1">&#1055;&#1086;&#1074;&#1077;&#1095;&#1077;&#1090;&#1086; &#1086;&#1090;
                                                &#1085;&#1072;&#1096;&#1080;&#1090;&#1077; &#1091;&#1089;&#1083;&#1091;&#1075;&#1080; &#1085;&#1077;
                                                &#1080;&#1079;&#1080;&#1089;&#1082;&#1074;&#1072;&#1090; &#1085;&#1080;&#1082;&#1072;&#1082;&#1074;&#1072;
                                                &#1092;&#1086;&#1088;&#1084;&#1072; &#1085;&#1072;
                                                &#1088;&#1077;&#1075;&#1080;&#1089;&#1090;&#1088;&#1072;&#1094;&#1080;&#1103;. &#1053;&#1080;&#1077;
                                                &#1091;&#1074;&#1072;&#1078;&#1072;&#1074;&#1072;&#1084;&#1077; &#1080;
                                                &#1079;&#1072;&#1095;&#1080;&#1090;&#1072;&#1084;&#1077;
                                                &#1085;&#1077;&#1087;&#1088;&#1080;&#1082;&#1086;&#1089;&#1085;&#1086;&#1074;&#1077;&#1085;&#1086;&#1089;&#1090;&#1090;&#1072;
                                                &#1085;&#1072; &#1083;&#1080;&#1095;&#1085;&#1080;&#1103; &#1078;&#1080;&#1074;&#1086;&#1090; &#1085;&#1072;
                                                &#1074;&#1089;&#1080;&#1095;&#1082;&#1080;
                                                &#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1080; &#1085;&#1072;
                                                &#1085;&#1072;&#1096;&#1072;&#1090;&#1072;
                                                &#1091;&#1077;&#1073;-&#1089;&#1090;&#1088;&#1072;&#1085;&#1080;&#1094;&#1072;.
                                                &#1053;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1072;&#1090;&#1072;
                                                &#1087;&#1086;&#1083;&#1080;&#1090;&#1080;&#1082;&#1072; &#1079;&#1072;
                                                &#1087;&#1086;&#1074;&#1077;&#1088;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086;&#1089;&#1090;
                                                &#1086;&#1087;&#1080;&#1089;&#1074;&#1072; &#1085;&#1072;&#1095;&#1080;&#1085;&#1080;&#1090;&#1077; &#1080;
                                                &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;&#1090;&#1072;, &#1087;&#1088;&#1080;
                                                &#1082;&#1086;&#1080;&#1090;&#1086; &#1089;&#1077;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1090; &#1080;
                                                &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1090; &#1042;&#1072;&#1096;&#1080;&#1090;&#1077;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;,
                                                &#1089;&#1098;&#1073;&#1088;&#1072;&#1085;&#1080; &#1087;&#1088;&#1080;
                                                &#1087;&#1086;&#1089;&#1077;&#1097;&#1077;&#1085;&#1080;&#1077;&#1090;&#1086; &#1080;&#1083;&#1080;
                                                &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                &#1091;&#1077;&#1073;-&#1089;&#1090;&#1088;&#1072;&#1085;&#1080;&#1094;&#1072;&#1090;&#1072;.
                                                &#1055;&#1088;&#1077;&#1087;&#1086;&#1088;&#1098;&#1095;&#1074;&#1072;&#1084;&#1077; &#1042;&#1080;
                                                &#1076;&#1072; &#1087;&#1088;&#1086;&#1095;&#1077;&#1090;&#1077;&#1090;&#1077; &#1090;&#1072;&#1079;&#1080;
                                                &#1055;&#1086;&#1083;&#1080;&#1090;&#1080;&#1082;&#1072; &#1079;&#1072;
                                                &#1087;&#1086;&#1074;&#1077;&#1088;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086;&#1089;&#1090;, &#1079;&#1072;
                                                &#1076;&#1072; &#1087;&#1086;&#1083;&#1091;&#1095;&#1080;&#1090;&#1077;
                                                &#1087;&#1086;&#1074;&#1077;&#1095;&#1077;
                                                &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;
                                                &#1086;&#1090;&#1085;&#1086;&#1089;&#1085;&#1086;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1082;&#1072;&#1090;&#1072; &#1080;
                                                &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086; &#1085;&#1072;
                                                &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1042;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;.</span>
                                        </p>
                                        <ol class="c18 lst-kix_list_10-0" start="2">
                                            <li class="c26 li-bullet-0"><span class="c27">&#1054;&#1089;&#1085;&#1086;&#1074;&#1085;&#1080;
                                                    &#1087;&#1086;&#1085;&#1103;&#1090;&#1080;&#1103; &#1079;&#1072;
                                                    &nbsp;&#1087;&#1086;-&#1076;&#1086;&#1073;&#1088;&#1086;&#1090;&#1086;
                                                    &#1088;&#1072;&#1079;&#1073;&#1080;&#1088;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                    &#1090;&#1072;&#1079;&#1080; &#1087;&#1086;&#1083;&#1080;&#1090;&#1080;&#1082;&#1072;</span></li>
                                        </ol>
                                        <p class="c12"><span class="c1">&bdquo;&#1051;&#1080;&#1095;&#1085;&#1080;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;&ldquo; &#1087;&#1086; &#1089;&#1084;&#1080;&#1089;&#1098;&#1083;&#1072;
                                                &#1085;&#1072; &#1090;&#1072;&#1079;&#1080; &#1087;&#1086;&#1083;&#1080;&#1090;&#1080;&#1082;&#1072;
                                                &#1089;&#1072; &#1076;&#1072;&#1085;&#1085;&#1080;,
                                                &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1077;&#1085;&#1080; &#1086;&#1090;
                                                &#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1080; &#1080;
                                                &nbsp;&#1082;&#1083;&#1080;&#1077;&#1085;&#1090;&#1080; &#1085;&#1072;
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                                &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                                &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; &nbsp;&#1074;&#1098;&#1074;
                                                &#1074;&#1088;&#1098;&#1079;&#1082;&#1072; &#1089;
                                                (&#1074;&#1082;&#1083;&#1102;&#1095;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086; &#1085;&#1086; &#1085;&#1077;
                                                &#1089;&#1072;&#1084;&#1086;):&nbsp;</span></p>
                                        <ol class="c18 lst-kix_list_1-0 start" start="1">
                                            <li class="c8 c14 li-bullet-0"><span
                                                    class="c1">&#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                    &#1091;&#1077;&#1073; &#1089;&#1090;&#1088;&#1072;&#1085;&#1080;&#1094;&#1080;&#1090;&#1077;
                                                    &#1080;/&#1080;&#1083;&#1080; &#1086;&#1085;&#1083;&#1072;&#1081;&#1085;
                                                    &#1072;&#1087;&#1083;&#1080;&#1082;&#1072;&#1094;&#1080;&#1080;&#1090;&#1077;,
                                                    &#1087;&#1088;&#1080;&#1090;&#1077;&#1078;&#1072;&#1074;&#1072;&#1085;&#1080; &#1080;
                                                    &#1072;&#1076;&#1084;&#1080;&#1085;&#1080;&#1089;&#1090;&#1088;&#1080;&#1088;&#1072;&#1085;&#1080;
                                                    &#1086;&#1090; &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                                    &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                                    &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo;
                                                    &nbsp;(&#1074;&#1082;&#1083;&#1102;&#1095;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086;
                                                    &#1086;&#1092;&#1080;&#1094;&#1080;&#1072;&#1083;&#1085;&#1080;&#1090;&#1077;
                                                    &#1089;&#1090;&#1088;&#1072;&#1085;&#1080;&#1094;&#1080; &#1085;&#1072;
                                                    &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072; &#1074;
                                                    &#1089;&#1086;&#1094;&#1080;&#1072;&#1083;&#1085;&#1080;&#1090;&#1077;
                                                    &#1084;&#1088;&#1077;&#1078;&#1080; &ndash; Facebook; Instagram, Linkedin &#1080;
                                                    &#1076;&#1088;.)&nbsp;</span></li>
                                            <li class="c8 c14 li-bullet-0"><span
                                                    class="c1">&#1087;&#1086;&#1083;&#1091;&#1095;&#1072;&#1074;&#1072;&#1085;&#1077; &#1080;
                                                    &#1080;&#1079;&#1087;&#1088;&#1072;&#1097;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                    &#1083;&#1080;&#1095;&#1085;&#1072;
                                                    &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103; &#1079;&#1072;
                                                    &#1080;&#1085;&#1080;&#1094;&#1080;&#1072;&#1090;&#1080;&#1074;&#1080; &#1080;&#1083;&#1080;
                                                    &#1076;&#1088;&#1091;&#1075;&#1080;
                                                    &#1084;&#1077;&#1088;&#1086;&#1087;&#1088;&#1080;&#1103;&#1090;&#1080;&#1103;,
                                                    &#1086;&#1088;&#1075;&#1072;&#1085;&#1080;&#1079;&#1080;&#1088;&#1072;&#1085;&#1080;,
                                                    &#1087;&#1086;&#1076;&#1082;&#1088;&#1077;&#1087;&#1103;&#1085;&#1080; &#1080;&#1083;&#1080;
                                                    &#1092;&#1080;&#1085;&#1072;&#1085;&#1089;&#1080;&#1088;&#1072;&#1085;&#1080; &#1086;&#1090;
                                                    &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                                    &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                                    &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; ;&nbsp;</span></li>
                                            <li class="c8 c14 li-bullet-0"><span
                                                    class="c1">&#1082;&#1072;&#1085;&#1076;&#1080;&#1076;&#1072;&#1090;&#1089;&#1090;&#1074;&#1072;&#1085;&#1080;&#1103;
                                                    &#1087;&#1086; &#1086;&#1073;&#1103;&#1074;&#1077;&#1085;&#1080;&#1090;&#1077;
                                                    &#1089;&#1074;&#1086;&#1073;&#1086;&#1076;&#1085;&#1080;
                                                    &#1087;&#1086;&#1079;&#1080;&#1094;&#1080;&#1080; &#1079;&#1072;
                                                    &#1088;&#1072;&#1073;&#1086;&#1090;&#1072; &#1085;&#1072; &#1089;&#1072;&#1081;&#1090;&#1072;
                                                    &#1085;&#1072; &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072;..</span></li>
                                            <li class="c8 c14 li-bullet-0"><span class="c1">&#1074;&#1098;&#1074; &#1074;&#1088;&#1098;&#1079;&#1082;&#1072;
                                                    &#1089; &#1091;&#1087;&#1088;&#1072;&#1078;&#1085;&#1103;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                    &#1087;&#1088;&#1072;&#1074;&#1072; &#1085;&#1072; &#1089;&#1091;&#1073;&#1077;&#1082;&#1090;&#1080;
                                                    &#1085;&#1072; &#1076;&#1072;&#1085;&#1085;&#1080;.</span></li>
                                        </ol>
                                        <p class="c12"><span class="c1">&bdquo;&#1054;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1082;&#1072;
                                                &#1085;&#1072; &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;&rdquo; &#1085;&#1072;
                                                &#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083; &#1077;
                                                &#1074;&#1089;&#1103;&#1082;&#1072; &#1086;&#1087;&#1077;&#1088;&#1072;&#1094;&#1080;&#1103;
                                                &#1080;&#1083;&#1080; &#1089;&#1098;&#1074;&#1082;&#1091;&#1087;&#1085;&#1086;&#1089;&#1090; &#1086;&#1090;
                                                &#1086;&#1087;&#1077;&#1088;&#1072;&#1094;&#1080;&#1080;,
                                                &#1080;&#1079;&#1074;&#1098;&#1088;&#1096;&#1074;&#1072;&#1085;&#1072; &#1089;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080; &#1080;&#1083;&#1080;
                                                &#1085;&#1072;&#1073;&#1086;&#1088; &#1086;&#1090; &#1083;&#1080;&#1095;&#1085;&#1080;
                                                &#1076;&#1072;&#1085;&#1085;&#1080; &#1095;&#1088;&#1077;&#1079;
                                                &#1072;&#1074;&#1090;&#1086;&#1084;&#1072;&#1090;&#1080;&#1095;&#1085;&#1080; &#1080;&#1083;&#1080;
                                                &#1076;&#1088;&#1091;&#1075;&#1080; &#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074;&#1072;
                                                &#1082;&#1072;&#1090;&#1086; &#1089;&#1098;&#1073;&#1080;&#1088;&#1072;&#1085;&#1077;,
                                                &#1079;&#1072;&#1087;&#1080;&#1089;&#1074;&#1072;&#1085;&#1077;,
                                                &#1086;&#1088;&#1075;&#1072;&#1085;&#1080;&#1079;&#1080;&#1088;&#1072;&#1085;&#1077;,
                                                &#1089;&#1090;&#1088;&#1091;&#1082;&#1090;&#1091;&#1088;&#1080;&#1088;&#1072;&#1085;&#1077;,
                                                &#1089;&#1098;&#1093;&#1088;&#1072;&#1085;&#1077;&#1085;&#1080;&#1077;,
                                                &#1072;&#1076;&#1072;&#1087;&#1090;&#1080;&#1088;&#1072;&#1085;&#1077; &#1080;&#1083;&#1080;
                                                &#1087;&#1088;&#1086;&#1084;&#1103;&#1085;&#1072;,
                                                &#1080;&#1079;&#1074;&#1083;&#1080;&#1095;&#1072;&#1085;&#1077;,
                                                &#1082;&#1086;&#1085;&#1089;&#1091;&#1083;&#1090;&#1080;&#1088;&#1072;&#1085;&#1077;,
                                                &#1091;&#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1072;,
                                                &#1088;&#1072;&#1079;&#1082;&#1088;&#1080;&#1074;&#1072;&#1085;&#1077; &#1095;&#1088;&#1077;&#1079;
                                                &#1087;&#1088;&#1077;&#1076;&#1072;&#1074;&#1072;&#1085;&#1077;,
                                                &#1088;&#1072;&#1079;&#1087;&#1088;&#1086;&#1089;&#1090;&#1088;&#1072;&#1085;&#1103;&#1074;&#1072;&#1085;&#1077;
                                                &#1080;&#1083;&#1080; &#1076;&#1088;&#1091;&#1075; &#1085;&#1072;&#1095;&#1080;&#1085;, &#1087;&#1086;
                                                &#1082;&#1086;&#1081;&#1090;&#1086; &#1076;&#1072;&#1085;&#1085;&#1080;&#1090;&#1077;
                                                &#1089;&#1090;&#1072;&#1074;&#1072;&#1090; &#1076;&#1086;&#1089;&#1090;&#1098;&#1087;&#1085;&#1080;,
                                                &#1087;&#1086;&#1076;&#1088;&#1077;&#1078;&#1076;&#1072;&#1085;&#1077; &#1080;&#1083;&#1080;
                                                &#1082;&#1086;&#1084;&#1073;&#1080;&#1085;&#1080;&#1088;&#1072;&#1085;&#1077;,
                                                &#1086;&#1075;&#1088;&#1072;&#1085;&#1080;&#1095;&#1072;&#1074;&#1072;&#1085;&#1077;,
                                                &#1080;&#1079;&#1090;&#1088;&#1080;&#1074;&#1072;&#1085;&#1077; &#1080;&#1083;&#1080;
                                                &#1091;&#1085;&#1080;&#1097;&#1086;&#1078;&#1072;&#1074;&#1072;&#1085;&#1077;</span></p>
                                        <p class="c12"><span
                                                class="c1">&bdquo;&#1040;&#1076;&#1084;&#1080;&#1085;&#1080;&#1089;&#1090;&#1088;&#1072;&#1090;&#1086;&#1088;&ldquo;
                                                &#1086;&#1079;&#1085;&#1072;&#1095;&#1072;&#1074;&#1072;
                                                &#1092;&#1080;&#1079;&#1080;&#1095;&#1077;&#1089;&#1082;&#1086; &#1080;&#1083;&#1080;
                                                &#1102;&#1088;&#1080;&#1076;&#1080;&#1095;&#1077;&#1089;&#1082;&#1086; &#1083;&#1080;&#1094;&#1077;,
                                                &#1087;&#1091;&#1073;&#1083;&#1080;&#1095;&#1077;&#1085; &#1086;&#1088;&#1075;&#1072;&#1085;,
                                                &#1072;&#1075;&#1077;&#1085;&#1094;&#1080;&#1103; &#1080;&#1083;&#1080; &#1076;&#1088;&#1091;&#1075;&#1072;
                                                &#1089;&#1090;&#1088;&#1091;&#1082;&#1090;&#1091;&#1088;&#1072;, &#1082;&#1086;&#1103;&#1090;&#1086;
                                                &#1089;&#1072;&#1084;&#1072; &#1080;&#1083;&#1080;
                                                &#1089;&#1098;&#1074;&#1084;&#1077;&#1089;&#1090;&#1085;&#1086; &#1089; &#1076;&#1088;&#1091;&#1075;&#1080;
                                                &#1086;&#1087;&#1088;&#1077;&#1076;&#1077;&#1083;&#1103; &#1094;&#1077;&#1083;&#1080;&#1090;&#1077; &#1080;
                                                &#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074;&#1072;&#1090;&#1072; &#1079;&#1072;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086; &#1085;&#1072;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;,
                                                &#1082;&#1086;&#1075;&#1072;&#1090;&#1086; &#1094;&#1077;&#1083;&#1080;&#1090;&#1077; &#1080;
                                                &#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074;&#1072;&#1090;&#1072; &#1079;&#1072;
                                                &#1090;&#1086;&#1074;&#1072; &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1085;&#1077;
                                                &#1089;&#1077; &#1086;&#1087;&#1088;&#1077;&#1076;&#1077;&#1083;&#1103;&#1090; &#1086;&#1090;
                                                &#1087;&#1088;&#1072;&#1074;&#1086;&#1090;&#1086; &#1085;&#1072; &#1057;&#1098;&#1102;&#1079;&#1072;
                                                &#1080;&#1083;&#1080; &#1087;&#1088;&#1072;&#1074;&#1086;&#1090;&#1086; &#1085;&#1072;
                                                &#1076;&#1098;&#1088;&#1078;&#1072;&#1074;&#1072; &#1095;&#1083;&#1077;&#1085;&#1082;&#1072;,
                                                &#1072;&#1076;&#1084;&#1080;&#1085;&#1080;&#1089;&#1090;&#1088;&#1072;&#1090;&#1086;&#1088;&#1098;&#1090;
                                                &#1080;&#1083;&#1080; &#1089;&#1087;&#1077;&#1094;&#1080;&#1072;&#1083;&#1085;&#1080;&#1090;&#1077;
                                                &#1082;&#1088;&#1080;&#1090;&#1077;&#1088;&#1080;&#1080; &#1079;&#1072;
                                                &#1085;&#1077;&#1075;&#1086;&#1074;&#1086;&#1090;&#1086;
                                                &#1086;&#1087;&#1088;&#1077;&#1076;&#1077;&#1083;&#1103;&#1085;&#1077; &#1084;&#1086;&#1075;&#1072;&#1090;
                                                &#1076;&#1072; &#1073;&#1098;&#1076;&#1072;&#1090;
                                                &#1091;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1077;&#1085;&#1080; &#1074;
                                                &#1087;&#1088;&#1072;&#1074;&#1086;&#1090;&#1086; &#1085;&#1072; &#1057;&#1098;&#1102;&#1079;&#1072;
                                                &#1080;&#1083;&#1080; &#1074; &#1087;&#1088;&#1072;&#1074;&#1086;&#1090;&#1086; &#1085;&#1072;
                                                &#1076;&#1098;&#1088;&#1078;&#1072;&#1074;&#1072; &#1095;&#1083;&#1077;&#1085;&#1082;&#1072;.&nbsp;</span>
                                        </p>
                                        <p class="c12"><span class="c1">&bdquo;&#1057;&#1098;&#1075;&#1083;&#1072;&#1089;&#1080;&#1077; &#1085;&#1072;
                                                &#1089;&#1091;&#1073;&#1077;&#1082;&#1090;&#1072; &#1085;&#1072;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;&#1090;&#1077;&ldquo;
                                                &#1086;&#1079;&#1085;&#1072;&#1095;&#1072;&#1074;&#1072; &#1074;&#1089;&#1103;&#1082;&#1086;
                                                &#1089;&#1074;&#1086;&#1073;&#1086;&#1076;&#1085;&#1086;
                                                &#1080;&#1079;&#1088;&#1072;&#1079;&#1077;&#1085;&#1086;,
                                                &#1082;&#1086;&#1085;&#1082;&#1088;&#1077;&#1090;&#1085;&#1086;,
                                                &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1080;&#1088;&#1072;&#1085;&#1086; &#1080;
                                                &#1085;&#1077;&#1076;&#1074;&#1091;&#1089;&#1084;&#1080;&#1089;&#1083;&#1077;&#1085;&#1086;
                                                &#1091;&#1082;&#1072;&#1079;&#1072;&#1085;&#1080;&#1077; &#1079;&#1072;
                                                &#1074;&#1086;&#1083;&#1103;&#1090;&#1072; &#1085;&#1072; &#1089;&#1091;&#1073;&#1077;&#1082;&#1090;&#1072;
                                                &#1085;&#1072; &#1076;&#1072;&#1085;&#1085;&#1080;&#1090;&#1077;,
                                                &#1087;&#1086;&#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074;&#1086;&#1084;
                                                &#1080;&#1079;&#1103;&#1074;&#1083;&#1077;&#1085;&#1080;&#1077; &#1080;&#1083;&#1080;
                                                &#1103;&#1089;&#1085;&#1086;
                                                &#1087;&#1086;&#1090;&#1074;&#1098;&#1088;&#1078;&#1076;&#1072;&#1074;&#1072;&#1097;&#1086;
                                                &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1080;&#1077;, &#1082;&#1086;&#1077;&#1090;&#1086;
                                                &#1080;&#1079;&#1088;&#1072;&#1079;&#1103;&#1074;&#1072;
                                                &#1089;&#1098;&#1075;&#1083;&#1072;&#1089;&#1080;&#1077;&#1090;&#1086; &#1084;&#1091;
                                                &#1089;&#1074;&#1098;&#1088;&#1079;&#1072;&#1085;&#1080;&#1090;&#1077; &#1089; &#1085;&#1077;&#1075;&#1086;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080; &#1076;&#1072;
                                                &#1073;&#1098;&#1076;&#1072;&#1090;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1077;&#1085;&#1080;;</span></p>
                                        <p class="c12"><span class="c1">&bdquo;&#1054;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1097;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;&ldquo;
                                                &#1086;&#1079;&#1085;&#1072;&#1095;&#1072;&#1074;&#1072;
                                                &#1092;&#1080;&#1079;&#1080;&#1095;&#1077;&#1089;&#1082;&#1086; &#1080;&#1083;&#1080;
                                                &#1102;&#1088;&#1080;&#1076;&#1080;&#1095;&#1077;&#1089;&#1082;&#1086; &#1083;&#1080;&#1094;&#1077;,
                                                &#1087;&#1091;&#1073;&#1083;&#1080;&#1095;&#1077;&#1085; &#1086;&#1088;&#1075;&#1072;&#1085;,
                                                &#1072;&#1075;&#1077;&#1085;&#1094;&#1080;&#1103; &#1080;&#1083;&#1080; &#1076;&#1088;&#1091;&#1075;&#1072;
                                                &#1089;&#1090;&#1088;&#1091;&#1082;&#1090;&#1091;&#1088;&#1072;, &#1082;&#1086;&#1103;&#1090;&#1086;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072; &#1083;&#1080;&#1095;&#1085;&#1080;
                                                &#1076;&#1072;&#1085;&#1085;&#1080; &#1086;&#1090; &#1080;&#1084;&#1077;&#1090;&#1086; &#1085;&#1072;
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                                &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                                &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; .&nbsp;</span></p>
                                        <p class="c12"><span class="c1">&bdquo;&#1058;&#1088;&#1077;&#1090;&#1072;
                                                &#1089;&#1090;&#1088;&#1072;&#1085;&#1072;&ldquo; &#1086;&#1079;&#1085;&#1072;&#1095;&#1072;&#1074;&#1072;
                                                &#1092;&#1080;&#1079;&#1080;&#1095;&#1077;&#1089;&#1082;&#1086; &#1080;&#1083;&#1080;
                                                &#1102;&#1088;&#1080;&#1076;&#1080;&#1095;&#1077;&#1089;&#1082;&#1086; &#1083;&#1080;&#1094;&#1077;,
                                                &#1087;&#1091;&#1073;&#1083;&#1080;&#1095;&#1077;&#1085; &#1086;&#1088;&#1075;&#1072;&#1085;,
                                                &#1072;&#1075;&#1077;&#1085;&#1094;&#1080;&#1103; &#1080;&#1083;&#1080; &#1076;&#1088;&#1091;&#1075;
                                                &#1086;&#1088;&#1075;&#1072;&#1085;, &#1088;&#1072;&#1079;&#1083;&#1080;&#1095;&#1077;&#1085; &#1086;&#1090;
                                                &#1089;&#1091;&#1073;&#1077;&#1082;&#1090;&#1072; &#1085;&#1072;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;&#1090;&#1077;,
                                                &#1072;&#1076;&#1084;&#1080;&#1085;&#1080;&#1089;&#1090;&#1088;&#1072;&#1090;&#1086;&#1088;&#1072;,
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1097;&#1080;&#1103;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080; &#1080;
                                                &#1083;&#1080;&#1094;&#1072;&#1090;&#1072;, &#1082;&#1086;&#1080;&#1090;&#1086; &#1087;&#1086;&#1076;
                                                &#1087;&#1088;&#1103;&#1082;&#1086;&#1090;&#1086;
                                                &#1088;&#1098;&#1082;&#1086;&#1074;&#1086;&#1076;&#1089;&#1090;&#1074;&#1086; &#1085;&#1072;
                                                &#1072;&#1076;&#1084;&#1080;&#1085;&#1080;&#1089;&#1090;&#1088;&#1072;&#1090;&#1086;&#1088;&#1072;
                                                &#1080;&#1083;&#1080; &#1085;&#1072;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1097;&#1080;&#1103;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080; &#1080;&#1084;&#1072;&#1090;
                                                &#1087;&#1088;&#1072;&#1074;&#1086; &#1076;&#1072;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1090;
                                                &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1076;&#1072;&#1085;&#1085;&#1080;.</span></p>
                                        <p class="c12"><span class="c1">&bdquo;&#1057;&#1091;&#1073;&#1077;&#1082;&#1090; &#1085;&#1072;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;&ldquo; &#1086;&#1079;&#1085;&#1072;&#1095;&#1072;&#1074;&#1072;
                                                &#1092;&#1080;&#1079;&#1080;&#1095;&#1077;&#1089;&#1082;&#1086; &#1083;&#1080;&#1094;&#1077;,
                                                &#1082;&#1086;&#1077;&#1090;&#1086; &#1084;&#1086;&#1078;&#1077; &#1076;&#1072; &#1073;&#1098;&#1076;&#1077;
                                                &#1080;&#1076;&#1077;&#1085;&#1090;&#1080;&#1092;&#1080;&#1094;&#1080;&#1088;&#1072;&#1085;&#1086;
                                                &#1087;&#1088;&#1103;&#1082;&#1086; &#1080;&#1083;&#1080; &#1085;&#1077;&#1087;&#1088;&#1103;&#1082;&#1086;,
                                                &#1087;&#1086;-&#1089;&#1087;&#1077;&#1094;&#1080;&#1072;&#1083;&#1085;&#1086; &#1095;&#1088;&#1077;&#1079;
                                                &#1080;&#1076;&#1077;&#1085;&#1090;&#1080;&#1092;&#1080;&#1082;&#1072;&#1090;&#1086;&#1088;
                                                &#1082;&#1072;&#1090;&#1086; &#1080;&#1084;&#1077;,
                                                &#1080;&#1076;&#1077;&#1085;&#1090;&#1080;&#1092;&#1080;&#1082;&#1072;&#1094;&#1080;&#1086;&#1085;&#1077;&#1085;
                                                &#1085;&#1086;&#1084;&#1077;&#1088;, &#1076;&#1072;&#1085;&#1085;&#1080; &#1079;&#1072;
                                                &#1084;&#1077;&#1089;&#1090;&#1086;&#1085;&#1072;&#1093;&#1086;&#1078;&#1076;&#1077;&#1085;&#1080;&#1077;,
                                                &#1086;&#1085;&#1083;&#1072;&#1081;&#1085;
                                                &#1080;&#1076;&#1077;&#1085;&#1090;&#1080;&#1092;&#1080;&#1082;&#1072;&#1090;&#1086;&#1088;
                                                &#1080;&#1083;&#1080; &#1087;&#1086; &#1077;&#1076;&#1080;&#1085; &#1080;&#1083;&#1080;
                                                &#1087;&#1086;&#1074;&#1077;&#1095;&#1077; &#1087;&#1088;&#1080;&#1079;&#1085;&#1072;&#1094;&#1080;,
                                                &#1089;&#1087;&#1077;&#1094;&#1080;&#1092;&#1080;&#1095;&#1085;&#1080; &#1079;&#1072;
                                                &#1092;&#1080;&#1079;&#1080;&#1095;&#1077;&#1089;&#1082;&#1072;&#1090;&#1072;,
                                                &#1092;&#1080;&#1079;&#1080;&#1086;&#1083;&#1086;&#1075;&#1080;&#1095;&#1085;&#1072;&#1090;&#1072;,
                                                &#1075;&#1077;&#1085;&#1077;&#1090;&#1080;&#1095;&#1085;&#1072;&#1090;&#1072;,
                                                &#1087;&#1089;&#1080;&#1093;&#1080;&#1095;&#1077;&#1089;&#1082;&#1072;&#1090;&#1072;,
                                                &#1091;&#1084;&#1089;&#1090;&#1074;&#1077;&#1085;&#1072;&#1090;&#1072;,
                                                &#1080;&#1082;&#1086;&#1085;&#1086;&#1084;&#1080;&#1095;&#1077;&#1089;&#1082;&#1072;&#1090;&#1072;,
                                                &#1082;&#1091;&#1083;&#1090;&#1091;&#1088;&#1085;&#1072;&#1090;&#1072; &#1080;&#1083;&#1080;
                                                &#1089;&#1086;&#1094;&#1080;&#1072;&#1083;&#1085;&#1072;
                                                &#1080;&#1076;&#1077;&#1085;&#1090;&#1080;&#1095;&#1085;&#1086;&#1089;&#1090; &#1085;&#1072;
                                                &#1090;&#1086;&#1074;&#1072; &#1092;&#1080;&#1079;&#1080;&#1095;&#1077;&#1089;&#1082;&#1086;
                                                &#1083;&#1080;&#1094;&#1077;.</span></p>
                                        <p class="c12"><span class="c1">&bdquo;&#1055;&#1088;&#1080;&#1083;&#1086;&#1078;&#1080;&#1084;&#1086;
                                                &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1090;&#1077;&#1083;&#1089;&#1090;&#1074;&#1086;&rdquo;
                                                &#1086;&#1079;&#1085;&#1072;&#1095;&#1072;&#1074;&#1072;
                                                &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1090;&#1077;&#1083;&#1089;&#1090;&#1074;&#1086;
                                                &#1085;&#1072; &#1045;&#1074;&#1088;&#1086;&#1087;&#1077;&#1081;&#1089;&#1082;&#1080;&#1103;
                                                &#1089;&#1098;&#1102;&#1079; &#1080; &#1085;&#1072;
                                                &#1056;&#1077;&#1087;&#1091;&#1073;&#1083;&#1080;&#1082;&#1072;
                                                &#1041;&#1098;&#1083;&#1075;&#1072;&#1088;&#1080;&#1103;, &#1082;&#1086;&#1077;&#1090;&#1086; &#1077;
                                                &#1086;&#1090;&#1085;&#1086;&#1089;&#1080;&#1084;&#1086; &#1082;&#1098;&#1084;
                                                &#1079;&#1072;&#1097;&#1080;&#1090;&#1072;&#1090;&#1072; &#1085;&#1072;
                                                &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1076;&#1072;&#1085;&#1085;&#1080;
                                                (&#1047;&#1072;&#1082;&#1086;&#1085; &#1079;&#1072; &#1079;&#1072;&#1097;&#1080;&#1090;&#1072;
                                                &#1085;&#1072; &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1076;&#1072;&#1085;&#1085;&#1080; &#1080;
                                                &#1076;&#1088;.);&nbsp;</span></p>
                                        <p class="c12"><span class="c1">&bdquo;&#1056;&#1077;&#1075;&#1083;&#1072;&#1084;&#1077;&#1085;&#1090;
                                                (&#1045;&#1057;) 2016/679&ldquo; &#1086;&#1079;&#1085;&#1072;&#1095;&#1072;&#1074;&#1072;
                                                &#1056;&#1077;&#1075;&#1083;&#1072;&#1084;&#1077;&#1085;&#1090; (&#1045;&#1057;) 2016/679 &#1085;&#1072;
                                                &#1045;&#1074;&#1088;&#1086;&#1087;&#1077;&#1081;&#1089;&#1082;&#1080;&#1103;
                                                &#1087;&#1072;&#1088;&#1083;&#1072;&#1084;&#1077;&#1085;&#1090; &#1080; &#1085;&#1072;
                                                &#1057;&#1098;&#1074;&#1077;&#1090;&#1072; &#1086;&#1090; 27 &#1072;&#1087;&#1088;&#1080;&#1083; 2016
                                                &#1075;. &#1086;&#1090;&#1085;&#1086;&#1089;&#1085;&#1086;
                                                &#1079;&#1072;&#1097;&#1080;&#1090;&#1072;&#1090;&#1072; &#1085;&#1072;
                                                &#1092;&#1080;&#1079;&#1080;&#1095;&#1077;&#1089;&#1082;&#1080;&#1090;&#1077; &#1083;&#1080;&#1094;&#1072;
                                                &#1074;&#1098;&#1074; &#1074;&#1088;&#1098;&#1079;&#1082;&#1072; &#1089;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086; &#1085;&#1072;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080; &#1080;
                                                &#1086;&#1090;&#1085;&#1086;&#1089;&#1085;&#1086;
                                                &#1089;&#1074;&#1086;&#1073;&#1086;&#1076;&#1085;&#1086;&#1090;&#1086;
                                                &#1076;&#1074;&#1080;&#1078;&#1077;&#1085;&#1080;&#1077; &#1085;&#1072;
                                                &#1090;&#1072;&#1082;&#1080;&#1074;&#1072; &#1076;&#1072;&#1085;&#1085;&#1080; &#1080; &#1079;&#1072;
                                                &#1086;&#1090;&#1084;&#1103;&#1085;&#1072; &#1085;&#1072;
                                                &#1044;&#1080;&#1088;&#1077;&#1082;&#1090;&#1080;&#1074;&#1072; 95/46/&#1045;&#1054; (&#1054;&#1073;&#1097;
                                                &#1088;&#1077;&#1075;&#1083;&#1072;&#1084;&#1077;&#1085;&#1090;
                                                &#1086;&#1090;&#1085;&#1086;&#1089;&#1085;&#1086; &#1079;&#1072;&#1097;&#1080;&#1090;&#1072;&#1090;&#1072;
                                                &#1085;&#1072; &#1076;&#1072;&#1085;&#1085;&#1080;&#1090;&#1077;),
                                                &#1086;&#1073;&#1085;&#1072;&#1088;&#1086;&#1076;&#1074;&#1072;&#1085; &#1074;
                                                &#1054;&#1092;&#1080;&#1094;&#1080;&#1072;&#1083;&#1077;&#1085;
                                                &#1074;&#1077;&#1089;&#1090;&#1085;&#1080;&#1082; &#1085;&#1072;
                                                &#1045;&#1074;&#1088;&#1086;&#1087;&#1077;&#1081;&#1089;&#1082;&#1080;&#1103; &#1089;&#1098;&#1102;&#1079;
                                                &#1085;&#1072; 4 &#1084;&#1072;&#1081; 2016 &#1075;, &#1085;&#1072;&#1088;&#1080;&#1095;&#1072;&#1085;
                                                &#1087;&#1086;-&#1076;&#1086;&#1083;&#1091; &#1079;&#1072;
                                                &#1082;&#1088;&#1072;&#1090;&#1082;&#1086;&#1089;&#1090;
                                                &bdquo;&#1056;&#1077;&#1075;&#1083;&#1072;&#1084;&#1077;&#1085;&#1090;&#1098;&#1090;&ldquo;.&nbsp;</span>
                                        </p>
                                        <ol class="c18 lst-kix_list_10-0" start="3">
                                            <li class="c12 c32 li-bullet-0"><span class="c11">&#1062;&#1077;&#1083;&#1080; &#1085;&#1072;
                                                    &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1082;&#1072;&#1090;&#1072; &#1085;&#1072;
                                                    &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;</span></li>
                                        </ol>
                                        <p class="c12"><span class="c1">&#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                                &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                                &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo;
                                                &nbsp;&#1089;&#1098;&#1073;&#1080;&#1088;&#1072; &#1095;&#1088;&#1077;&#1079;
                                                &#1091;&#1077;&#1073;&#1089;&#1072;&#1081;&#1090;&#1072; &#1080;
                                                &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072; &#1042;&#1072;&#1096;&#1080;&#1090;&#1077;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080; &#1079;&#1072;
                                                &#1089;&#1083;&#1077;&#1076;&#1085;&#1080;&#1090;&#1077; &#1094;&#1077;&#1083;&#1080;:</span></p>
                                        <ul class="c18 lst-kix_list_11-0 start">
                                            <li class="c15 c49"><span
                                                    class="c3">&#1055;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1103;&#1085;&#1077;
                                                    &#1085;&#1072; &#1087;&#1086;&#1080;&#1089;&#1082;&#1072;&#1085;&#1080; &#1086;&#1090;
                                                    &#1074;&#1072;&#1089; &#1091;&#1089;&#1083;&#1091;&#1075;&#1080;:</span></li>
                                        </ul>
                                        <p class="c8"><span class="c1 c42">&#1055;&#1086;&#1095;&#1090;&#1080; &#1074;&#1089;&#1080;&#1095;&#1082;&#1080;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;, &#1082;&#1086;&#1080;&#1090;&#1086;
                                                &#1087;&#1086;&#1087;&#1098;&#1083;&#1074;&#1072;&#1090;&#1077; &#1074;
                                                &#1088;&#1077;&#1075;&#1080;&#1089;&#1090;&#1088;&#1072;&#1094;&#1080;&#1086;&#1085;&#1085;&#1080;&#1090;&#1077;
                                                &#1092;&#1086;&#1088;&#1084;&#1080; &#1080;&#1083;&#1080; &#1042;&#1072;&#1096;&#1080;&#1103;
                                                &#1087;&#1088;&#1086;&#1092;&#1080;&#1083;, &#1089;&#1072;
                                                &#1085;&#1077;&#1086;&#1073;&#1093;&#1086;&#1076;&#1080;&#1084;&#1080;, &#1079;&#1072; &#1076;&#1072;
                                                &#1086;&#1090;&#1075;&#1086;&#1074;&#1086;&#1088;&#1080;&#1084; &#1085;&#1072; &#1042;&#1072;&#1096;&#1072;
                                                &#1085;&#1091;&#1078;&#1076;&#1072; &#1080;&#1083;&#1080;
                                                &#1087;&#1086;&#1080;&#1089;&#1082;&#1072;&#1085;&#1072; &#1086;&#1090; &#1042;&#1072;&#1089;
                                                &#1091;&#1089;&#1083;&#1091;&#1075;&#1072;, &#1082;&#1086;&#1103;&#1090;&#1086;
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                                &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                                &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo;
                                                &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1103;. &#1047;&#1072; &#1076;&#1072;
                                                &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1103;&#1084;&#1077;
                                                &#1082;&#1072;&#1095;&#1077;&#1089;&#1090;&#1074;&#1077;&#1085;&#1086;
                                                &#1091;&#1089;&#1083;&#1091;&#1075;&#1080;&#1090;&#1077; &#1089;&#1080;
                                                &#1086;&#1085;&#1083;&#1072;&#1081;&#1085; &#1080; &#1086;&#1092;&#1083;&#1072;&#1081;&#1085;,
                                                &#1085;&#1080;&#1077; &#1089;&#1077; &#1085;&#1091;&#1078;&#1076;&#1072;&#1077;&#1084; &#1086;&#1090;
                                                &#1042;&#1072;&#1096;&#1080;
                                                &#1080;&#1076;&#1077;&#1085;&#1090;&#1080;&#1092;&#1080;&#1082;&#1072;&#1094;&#1080;&#1086;&#1085;&#1085;&#1080;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;, &#1082;&#1072;&#1090;&#1086;
                                                &#1090;&#1077;&#1093;&#1085;&#1080;&#1103;&#1090; &#1086;&#1073;&#1093;&#1074;&#1072;&#1090;
                                                &#1074;&#1072;&#1088;&#1080;&#1088;&#1072; &#1074;
                                                &#1079;&#1072;&#1074;&#1080;&#1089;&#1080;&#1084;&#1086;&#1089;&#1090; &#1086;&#1090;
                                                &#1074;&#1080;&#1076;&#1072; &#1085;&#1072; &#1080;&#1089;&#1082;&#1072;&#1085;&#1072;&#1090;&#1072;
                                                &#1091;&#1089;&#1083;&#1091;&#1075;&#1072; &#1080;
                                                &#1087;&#1088;&#1080;&#1083;&#1086;&#1078;&#1080;&#1084;&#1080;&#1090;&#1077; &#1082;&#1098;&#1084;
                                                &#1085;&#1077;&#1103; &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1074;&#1080;
                                                &#1080;&#1079;&#1080;&#1089;&#1082;&#1074;&#1072;&#1085;&#1080;&#1103;.</span></p>
                                        <p class="c6"><span class="c1 c42"></span></p>
                                        <ul class="c18 lst-kix_list_11-0">
                                            <li class="c8 c15"><span
                                                    class="c3">&#1052;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;&#1086;&#1074;&#1072;
                                                    &#1076;&#1077;&#1081;&#1085;&#1086;&#1089;&#1090;:</span></li>
                                        </ul>
                                        <p class="c8"><span class="c1">&#1051;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;, &#1082;&#1086;&#1080;&#1090;&#1086; &#1042;&#1080;&#1077;
                                                &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1103;&#1090;&#1077;,
                                                &#1084;&#1086;&#1078;&#1077; &#1076;&#1072; &#1073;&#1098;&#1076;&#1072;&#1090;
                                                &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1080;, &#1079;&#1072; &#1076;&#1072;
                                                &#1042;&#1080; &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1080;&#1088;&#1072;&#1084;&#1077; &#1079;&#1072;
                                                &#1085;&#1086;&#1074;&#1080; &#1091;&#1089;&#1083;&#1091;&#1075;&#1080;,
                                                &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1103;&#1085;&#1080; &#1086;&#1090;
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                                &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                                &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo;.
                                                &#1048;&#1079;&#1087;&#1088;&#1072;&#1097;&#1072;&#1085;&#1080;&#1090;&#1077; &#1086;&#1090;
                                                &#1085;&#1072;&#1089; &#1089;&#1098;&#1086;&#1073;&#1097;&#1077;&#1085;&#1080;&#1103; &#1097;&#1077;
                                                &#1073;&#1098;&#1076;&#1072;&#1090; &#1089;&#1098;&#1086;&#1073;&#1088;&#1072;&#1079;&#1077;&#1085;&#1080;
                                                &#1089; &#1087;&#1088;&#1080;&#1083;&#1086;&#1078;&#1080;&#1084;&#1086;&#1090;&#1086;
                                                &#1073;&#1098;&#1083;&#1075;&#1072;&#1088;&#1089;&#1082;&#1086; &#1080;
                                                &#1077;&#1074;&#1088;&#1086;&#1087;&#1077;&#1081;&#1089;&#1082;&#1086;
                                                &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1090;&#1077;&#1083;&#1089;&#1090;&#1074;&#1086;.</span>
                                        </p>
                                        <p class="c6"><span class="c1"></span></p>
                                        <p class="c6"><span class="c1"></span></p>
                                        <p class="c8"><span class="c1">&#1042;&#1080;&#1077; &#1084;&#1086;&#1078;&#1077;&#1090;&#1077; &#1076;&#1072;
                                                &#1074;&#1098;&#1079;&#1088;&#1072;&#1079;&#1080;&#1090;&#1077; &#1087;&#1086;
                                                &#1083;&#1077;&#1089;&#1077;&#1085; &#1080; &#1091;&#1076;&#1086;&#1073;&#1077;&#1085;
                                                &#1085;&#1072;&#1095;&#1080;&#1085; &#1085;&#1072;
                                                &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086; &#1085;&#1072;
                                                &#1042;&#1072;&#1096;&#1080;&#1090;&#1077; &#1076;&#1072;&#1085;&#1085;&#1080; &#1079;&#1072;
                                                &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;, &#1082;&#1086;&#1075;&#1072;&#1090;&#1086;
                                                &#1086;&#1089;&#1085;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;&#1090;&#1086; &#1079;&#1072;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1082;&#1072; &#1077;
                                                &#1079;&#1072;&#1082;&#1086;&#1085;&#1077;&#1085; &#1080;&#1085;&#1090;&#1077;&#1088;&#1077;&#1089;, &#1080;
                                                &#1085;&#1080;&#1077; &#1097;&#1077; &#1075;&#1086;
                                                &#1087;&#1088;&#1077;&#1082;&#1088;&#1072;&#1090;&#1080;&#1084;
                                                &#1085;&#1077;&#1079;&#1072;&#1073;&#1072;&#1074;&#1085;&#1086;;
                                                &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072; &#1082;&#1072;&#1082;
                                                &#1076;&#1072; &#1086;&#1090;&#1090;&#1077;&#1075;&#1083;&#1080;&#1090;&#1077;
                                                &#1074;&#1077;&#1076;&#1085;&#1098;&#1078; &#1076;&#1072;&#1076;&#1077;&#1085;&#1086;
                                                &#1089;&#1098;&#1075;&#1083;&#1072;&#1089;&#1080;&#1077; &#1080;&#1083;&#1080; &#1076;&#1072;
                                                &#1074;&#1098;&#1079;&#1088;&#1072;&#1079;&#1080;&#1090;&#1077; &#1087;&#1088;&#1080;
                                                &#1087;&#1086;&#1083;&#1091;&#1095;&#1072;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                &#1085;&#1077;&#1078;&#1077;&#1083;&#1072;&#1085;&#1086;
                                                &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;&#1086;&#1074;&#1086;
                                                &#1089;&#1098;&#1086;&#1073;&#1097;&#1077;&#1085;&#1080;&#1077; &#1097;&#1077; &#1073;&#1098;&#1076;&#1077;
                                                &#1087;&#1086;&#1089;&#1090;&#1072;&#1074;&#1077;&#1085;&#1072; &#1074;&#1080;&#1085;&#1072;&#1075;&#1080;
                                                &#1085;&#1072; &#1074;&#1080;&#1076;&#1085;&#1086; &#1084;&#1103;&#1089;&#1090;&#1086; &#1074;
                                                &#1089;&#1098;&#1086;&#1073;&#1097;&#1077;&#1085;&#1080;&#1077;&#1090;&#1086;.</span></p>
                                        <p class="c6"><span class="c1"></span></p>
                                        <p class="c8"><span class="c1">&#1047;&#1072; &#1094;&#1077;&#1083;&#1080;&#1090;&#1077; &#1085;&#1072;
                                                &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;&#1086;&#1074;&#1072;&#1090;&#1072;
                                                &#1076;&#1077;&#1081;&#1085;&#1086;&#1089;&#1090;
                                                &#1080;&#1079;&#1074;&#1098;&#1088;&#1096;&#1074;&#1072;&#1084;&#1077;
                                                &#1072;&#1085;&#1072;&#1083;&#1080;&#1079; &#1085;&#1072;
                                                &#1087;&#1086;&#1089;&#1077;&#1097;&#1072;&#1077;&#1084;&#1086;&#1089;&#1090;&#1090;&#1072; &#1080;
                                                &#1087;&#1088;&#1086;&#1089;&#1083;&#1077;&#1076;&#1103;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                &#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1089;&#1082;&#1086;&#1090;&#1086;
                                                &#1087;&#1086;&#1074;&#1077;&#1076;&#1077;&#1085;&#1080;&#1077; &#1085;&#1072;
                                                &#1091;&#1077;&#1073;&#1089;&#1072;&#1081;&#1090;&#1072; &#1095;&#1088;&#1077;&#1079;
                                                &#1082;&#1086;&#1076;&#1086;&#1074;&#1077; &#1086;&#1090; &#1076;&#1086;&#1084;&#1077;&#1081;&#1085;&#1080;
                                                &#1085;&#1072; &#1090;&#1088;&#1077;&#1090;&#1080; &#1089;&#1090;&#1088;&#1072;&#1085;&#1080;,
                                                &#1087;&#1088;&#1080; &#1082;&#1086;&#1077;&#1090;&#1086;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1084;&#1077; &#1042;&#1072;&#1096;&#1080;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080; &#1082;&#1072;&#1090;&#1086;
                                                &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1084;&#1077;
                                                &#1073;&#1080;&#1089;&#1082;&#1074;&#1080;&#1090;&#1082;&#1080;. &#1055;&#1086;&#1074;&#1077;&#1095;&#1077;
                                                &#1079;&#1072; &#1090;&#1103;&#1093; &#1084;&#1086;&#1078;&#1077;&#1090;&#1077; &#1076;&#1072;
                                                &#1087;&#1088;&#1086;&#1095;&#1077;&#1090;&#1077;&#1090;&#1077; &#1074;
                                                &#1085;&#1072;&#1096;&#1072;&#1090;&#1072;
                                                &#1087;&#1086;&#1083;&#1080;&#1090;&#1080;&#1082;&#1072;&#1090;&#1072; &#1079;&#1072;
                                                &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                &#1073;&#1080;&#1089;&#1082;&#1074;&#1080;&#1090;&#1082;&#1080;.</span></p>
                                        <p class="c6"><span class="c7"></span></p>
                                        <ul class="c18 lst-kix_list_11-0">
                                            <li class="c8 c15"><span class="c3">&#1055;&#1086;&#1076;&#1073;&#1086;&#1088; &#1085;&#1072;
                                                    &#1089;&#1083;&#1091;&#1078;&#1080;&#1090;&#1077;&#1083;&#1080;,
                                                    &#1082;&#1072;&#1085;&#1076;&#1080;&#1076;&#1072;&#1090;&#1089;&#1090;&#1074;&#1072;&#1083;&#1080;
                                                    &#1087;&#1086; &#1086;&#1073;&#1103;&#1074;&#1080; &#1079;&#1072;
                                                    &#1088;&#1072;&#1073;&#1086;&#1090;&#1072;:</span></li>
                                        </ul>
                                        <p class="c8"><span class="c1 c42">&#1063;&#1088;&#1077;&#1079; &#1085;&#1072;&#1096;&#1080;&#1103;
                                                &#1091;&#1077;&#1073;&#1089;&#1072;&#1081;&#1090; &#1085;&#1080;&#1077;
                                                &#1087;&#1088;&#1077;&#1076;&#1083;&#1072;&#1075;&#1072;&#1084;&#1077;
                                                &#1091;&#1076;&#1086;&#1073;&#1085;&#1072;
                                                &#1074;&#1098;&#1079;&#1084;&#1086;&#1078;&#1085;&#1086;&#1089;&#1090; &#1076;&#1072; &#1089;&#1077;
                                                &#1079;&#1072;&#1087;&#1086;&#1079;&#1085;&#1072;&#1077;&#1090;&#1077; &#1089;
                                                &#1086;&#1073;&#1103;&#1074;&#1077;&#1085;&#1080; &#1089;&#1074;&#1086;&#1073;&#1086;&#1076;&#1085;&#1080;
                                                &#1087;&#1086;&#1079;&#1080;&#1094;&#1080;&#1080; &#1079;&#1072; &#1088;&#1072;&#1073;&#1086;&#1090;&#1072;
                                                &#1074; &#1087;&#1072;&#1088;&#1090;&#1085;&#1100;&#1086;&#1088;&#1089;&#1082;&#1080;
                                                &#1082;&#1086;&#1084;&#1087;&#1072;&#1085;&#1080;&#1080;,
                                                &#1089;&#1082;&#1083;&#1102;&#1095;&#1080;&#1083;&#1080;
                                                &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1080; &#1089;
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                                &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                                &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; &nbsp;&#1080; &#1076;&#1072;
                                                &#1082;&#1072;&#1085;&#1076;&#1080;&#1076;&#1072;&#1090;&#1089;&#1090;&#1074;&#1072;&#1090;&#1077;
                                                &#1087;&#1086; &#1080;&#1079;&#1073;&#1088;&#1072;&#1085;&#1072; &#1086;&#1090; &#1042;&#1072;&#1089;
                                                &#1087;&#1086;&#1079;&#1080;&#1094;&#1080;&#1103;. &#1058;&#1072;&#1082;&#1072; &#1095;&#1088;&#1077;&#1079;
                                                &#1091;&#1077;&#1073;&#1089;&#1072;&#1081;&#1090;&#1072; &#1084;&#1086;&#1078;&#1077;&#1090;&#1077;
                                                &#1076;&#1072; &#1087;&#1086;&#1076;&#1072;&#1076;&#1077;&#1090;&#1077;
                                                &#1089;&#1074;&#1086;&#1080;&#1090;&#1077; &#1076;&#1086;&#1082;&#1091;&#1084;&#1077;&#1085;&#1090;&#1080;,
                                                &#1082;&#1072;&#1090;&#1086; &#1079;&#1072; &#1094;&#1077;&#1083;&#1080;&#1090;&#1077; &#1085;&#1072;
                                                &#1087;&#1086;&#1076;&#1073;&#1086;&#1088;&#1072; &#1085;&#1080;&#1077; &#1097;&#1077; &#1042;&#1080;
                                                &#1080;&#1076;&#1077;&#1085;&#1090;&#1080;&#1092;&#1080;&#1094;&#1080;&#1088;&#1072;&#1084;&#1077; &#1080;
                                                &#1097;&#1077; &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1080;&#1084;
                                                &#1080;&#1079;&#1087;&#1088;&#1072;&#1090;&#1077;&#1085;&#1072;&#1090;&#1072; &#1086;&#1090;
                                                &#1042;&#1072;&#1089; &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103; &#1080;
                                                &#1042;&#1072;&#1096;&#1080; &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;.</span>
                                        </p>
                                        <p class="c6"><span class="c7"></span></p>
                                        <ol class="c18 lst-kix_list_10-0" start="4">
                                            <li class="c37 c32 li-bullet-0"><span class="c16">&#1055;&#1088;&#1080;&#1085;&#1094;&#1080;&#1087;&#1080;
                                                    &#1085;&#1072; &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1085;&#1077;
                                                    &#1085;&#1072; &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;</span></li>
                                        </ol>
                                        <p class="c8"><span class="c1">&#1051;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1076;&#1072;&#1085;&#1085;&#1080;
                                                &#1089;&#1077; &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1090;
                                                &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1089;&#1098;&#1086;&#1073;&#1088;&#1072;&#1079;&#1085;&#1086;,
                                                &#1076;&#1086;&#1073;&#1088;&#1086;&#1089;&#1098;&#1074;&#1077;&#1089;&#1090;&#1085;&#1086; &#1080;
                                                &#1087;&#1088;&#1086;&#1079;&#1088;&#1072;&#1095;&#1085;&#1086; &#1087;&#1088;&#1080;
                                                &#1089;&#1087;&#1072;&#1079;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                &#1089;&#1083;&#1077;&#1076;&#1085;&#1080;&#1090;&#1077;
                                                &#1087;&#1088;&#1080;&#1085;&#1094;&#1080;&#1087;&#1080;: </span></p>
                                        <p class="c8"><span class="c1">(1) &#1057;&#1091;&#1073;&#1077;&#1082;&#1090;&#1098;&#1090; &#1085;&#1072;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;&#1090;&#1077; &#1089;&#1077;
                                                &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1080;&#1088;&#1072;
                                                &#1087;&#1088;&#1077;&#1076;&#1074;&#1072;&#1088;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086; &#1079;&#1072;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086; &#1085;&#1072;
                                                &#1085;&#1077;&#1075;&#1086;&#1074;&#1080;&#1090;&#1077; &#1083;&#1080;&#1095;&#1085;&#1080;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;;</span></p>
                                        <p class="c8"><span class="c1">(2) &#1051;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077;
                                                &#1076;&#1072;&#1085;&#1085;&#1080; &#1089;&#1077; &#1089;&#1098;&#1073;&#1080;&#1088;&#1072;&#1090;
                                                &#1079;&#1072; &#1082;&#1086;&#1085;&#1082;&#1088;&#1077;&#1090;&#1085;&#1080;,
                                                &#1090;&#1086;&#1095;&#1085;&#1086; &#1086;&#1087;&#1088;&#1077;&#1076;&#1077;&#1083;&#1077;&#1085;&#1080;
                                                &#1080; &#1079;&#1072;&#1082;&#1086;&#1085;&#1085;&#1080; &#1094;&#1077;&#1083;&#1080; &#1080;
                                                &#1085;&#1077; &#1089;&#1077; &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1090;
                                                &#1076;&#1086;&#1087;&#1098;&#1083;&#1085;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086; &#1087;&#1086;
                                                &#1085;&#1072;&#1095;&#1080;&#1085;,
                                                &#1085;&#1077;&#1089;&#1098;&#1074;&#1084;&#1077;&#1089;&#1090;&#1080;&#1084; &#1089;
                                                &#1090;&#1077;&#1079;&#1080; &#1094;&#1077;&#1083;&#1080;;</span></p>
                                        <p class="c8"><span class="c1">(3) &#1051;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;
                                                &#1089;&#1098;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1072;&#1090; &#1085;&#1072;
                                                &#1094;&#1077;&#1083;&#1080;&#1090;&#1077;, &#1079;&#1072; &#1082;&#1086;&#1080;&#1090;&#1086;
                                                &#1089;&#1077; &#1089;&#1098;&#1073;&#1080;&#1088;&#1072;&#1090;;</span></p>
                                        <p class="c8"><span class="c1">(4) &#1051;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077;
                                                &#1076;&#1072;&#1085;&#1085;&#1080; &#1090;&#1088;&#1103;&#1073;&#1074;&#1072; &#1076;&#1072; &#1089;&#1072;
                                                &#1090;&#1086;&#1095;&#1085;&#1080; &#1080; &#1087;&#1088;&#1080;
                                                &#1085;&#1077;&#1086;&#1073;&#1093;&#1086;&#1076;&#1080;&#1084;&#1086;&#1089;&#1090; &#1076;&#1072;
                                                &#1089;&#1077; &#1072;&#1082;&#1090;&#1091;&#1072;&#1083;&#1080;&#1079;&#1080;&#1088;&#1072;&#1090;;</span>
                                        </p>
                                        <p class="c8"><span class="c1">(5) &#1051;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077;
                                                &#1076;&#1072;&#1085;&#1085;&#1080; &#1089;&#1077;
                                                &#1079;&#1072;&#1083;&#1080;&#1095;&#1072;&#1074;&#1072;&#1090; &#1080;&#1083;&#1080;
                                                &#1082;&#1086;&#1088;&#1080;&#1075;&#1080;&#1088;&#1072;&#1090;, &#1082;&#1086;&#1075;&#1072;&#1090;&#1086;
                                                &#1089;&#1077; &#1091;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1080;, &#1095;&#1077; &#1089;&#1072;
                                                &#1085;&#1077;&#1090;&#1086;&#1095;&#1085;&#1080; &#1080;&#1083;&#1080; &#1085;&#1077;
                                                &#1089;&#1098;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1072;&#1090; &#1085;&#1072;
                                                &#1094;&#1077;&#1083;&#1080;&#1090;&#1077;, &#1079;&#1072; &#1082;&#1086;&#1080;&#1090;&#1086;
                                                &#1089;&#1077; &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1090;;</span></p>
                                        <p class="c8"><span class="c1">(6) &#1051;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077;
                                                &#1076;&#1072;&#1085;&#1085;&#1080; &#1089;&#1077;
                                                &#1087;&#1086;&#1076;&#1076;&#1098;&#1088;&#1078;&#1072;&#1090; &#1074;&#1098;&#1074; &#1074;&#1080;&#1076;,
                                                &#1082;&#1086;&#1081;&#1090;&#1086; &#1087;&#1086;&#1079;&#1074;&#1086;&#1083;&#1103;&#1074;&#1072;
                                                &#1080;&#1076;&#1077;&#1085;&#1090;&#1080;&#1092;&#1080;&#1094;&#1080;&#1088;&#1072;&#1085;&#1077;
                                                &#1085;&#1072; &#1089;&#1098;&#1086;&#1090;&#1074;&#1077;&#1090;&#1085;&#1080;&#1090;&#1077;
                                                &#1092;&#1080;&#1079;&#1080;&#1095;&#1077;&#1089;&#1082;&#1080; &#1083;&#1080;&#1094;&#1072; &#1079;&#1072;
                                                &#1087;&#1077;&#1088;&#1080;&#1086;&#1076;, &#1085;&#1077;
                                                &#1087;&#1086;-&#1076;&#1098;&#1083;&#1098;&#1075; &#1086;&#1090;
                                                &#1085;&#1077;&#1086;&#1073;&#1093;&#1086;&#1076;&#1080;&#1084;&#1080;&#1103;, &#1079;&#1072;
                                                &#1094;&#1077;&#1083;&#1080;&#1090;&#1077;, &#1079;&#1072; &#1082;&#1086;&#1080;&#1090;&#1086;
                                                &#1090;&#1077;&#1079;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080; &#1089;&#1077;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1090;.</span></p>
                                        <p class="c35"><span class="c1"></span></p>
                                        <p class="c6 c43"><span class="c1"></span></p>
                                        <ol class="c18 lst-kix_list_10-0" start="5">
                                            <li class="c32 c37 li-bullet-0"><span
                                                    class="c11">&#1054;&#1089;&#1085;&#1086;&#1074;&#1072;&#1085;&#1080;&#1103; &#1079;&#1072;
                                                    &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1082;&#1072; &#1085;&#1072;
                                                    &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;</span></li>
                                        </ol>
                                        <p class="c17"><span class="c1">&#1050;&#1086;&#1075;&#1072;&#1090;&#1086;
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                                &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                                &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo;
                                                &nbsp;&#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;
                                                &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1042;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;
                                                &#1079;&#1072; &#1094;&#1077;&#1083;&#1080;&#1090;&#1077; &#1085;&#1072;
                                                &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1103;&#1085;&#1077; &#1085;&#1072;
                                                &#1091;&#1089;&#1083;&#1091;&#1075;&#1080;, &#1082;&#1086;&#1080;&#1090;&#1086; &#1089;&#1090;&#1077;
                                                &#1079;&#1072;&#1103;&#1074;&#1080;&#1083;&#1080; &#1095;&#1088;&#1077;&#1079;
                                                &#1085;&#1072;&#1096;&#1080;&#1103; &#1091;&#1077;&#1073;&#1089;&#1072;&#1081;&#1090;, &#1085;&#1080;&#1077;
                                                &#1085;&#1072;&#1081;-&#1095;&#1077;&#1089;&#1090;&#1086;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1084;&#1077;
                                                &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1042;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;
                                                &#1085;&#1072; &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1085;&#1086;
                                                &#1086;&#1089;&#1085;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;
                                                &#1089;&#1098;&#1075;&#1083;&#1072;&#1089;&#1085;&#1086; &#1095;&#1083;. 6,
                                                &#1087;&#1072;&#1088;&#1072;&#1075;&#1088;&#1072;&#1092; 1, &#1073;&#1091;&#1082;&#1074;&#1072;
                                                &quot;&#1073;&quot; &#1086;&#1090; &nbsp;&#1056;&#1077;&#1075;&#1083;&#1072;&#1084;&#1077;&#1085;&#1090;
                                                (&#1045;&#1057;) 2016/679 (GDPR). </span></p>
                                        <p class="c17"><span class="c1">&#1042; &#1089;&#1083;&#1091;&#1095;&#1072;&#1081;, &#1095;&#1077;
                                                &#1082;&#1072;&#1085;&#1076;&#1080;&#1076;&#1072;&#1090;&#1089;&#1090;&#1074;&#1072;&#1090;&#1077;
                                                &#1087;&#1086; &#1086;&#1073;&#1103;&#1074;&#1077;&#1085;&#1072;
                                                &#1087;&#1086;&#1079;&#1080;&#1094;&#1080;&#1103; &#1095;&#1088;&#1077;&#1079;
                                                &#1091;&#1077;&#1073;&#1089;&#1072;&#1081;&#1090;&#1072; &#1085;&#1072;
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                                &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                                &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; , &#1085;&#1080;&#1077;
                                                &#1089;&#1098;&#1073;&#1080;&#1088;&#1072;&#1084;&#1077; &#1076;&#1072;&#1085;&#1085;&#1080;&#1090;&#1077;
                                                &#1079;&#1072; &#1094;&#1077;&#1083;&#1080;&#1090;&#1077; &#1085;&#1072;
                                                &#1087;&#1086;&#1076;&#1073;&#1086;&#1088;&#1072; &#1085;&#1072;
                                                &#1086;&#1089;&#1085;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;
                                                &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1077;&#1085;&#1086;&#1090;&#1086;
                                                &#1042;&#1072;&#1096;&#1077; &#1089;&#1098;&#1075;&#1083;&#1072;&#1089;&#1080;&#1077; &#1079;&#1072;
                                                &#1090;&#1086;&#1074;&#1072; &#1074;
                                                &#1089;&#1098;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1077; &#1089; &#1095;&#1083;.
                                                6, &#1087;&#1072;&#1088;&#1072;&#1075;&#1088;&#1072;&#1092; 1, &#1073;&#1091;&#1082;&#1074;&#1072;
                                                &quot;&#1072;&quot; &#1086;&#1090; &#1056;&#1077;&#1075;&#1083;&#1072;&#1084;&#1077;&#1085;&#1090;
                                                (&#1045;&#1057;) 2016/679 (GDPR).</span></p>
                                        <p class="c17"><span class="c1">&#1047;&#1072; &#1094;&#1077;&#1083;&#1080;&#1090;&#1077; &#1085;&#1072;
                                                &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;&#1086;&#1074;&#1080;&#1090;&#1077;
                                                &#1076;&#1077;&#1081;&#1085;&#1086;&#1089;&#1090;&#1080; &#1085;&#1080;&#1077;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1084;&#1077;
                                                &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1042;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;,
                                                &#1079;&#1072;&#1097;&#1086;&#1090;&#1086; &#1080;&#1084;&#1072;&#1084;&#1077;
                                                &#1079;&#1072;&#1082;&#1086;&#1085;&#1077;&#1085; &#1080;&#1085;&#1090;&#1077;&#1088;&#1077;&#1089;
                                                &#1087;&#1086; &#1089;&#1084;&#1080;&#1089;&#1098;&#1083;&#1072; &#1085;&#1072; &#1095;&#1083;. 6,
                                                &#1087;&#1072;&#1088;&#1072;&#1075;&#1088;&#1072;&#1092; 1, &#1073;&#1091;&#1082;&#1074;&#1072;
                                                &quot;&#1077;&quot; &#1086;&#1090; &#1056;&#1077;&#1075;&#1083;&#1072;&#1084;&#1077;&#1085;&#1090;
                                                (&#1045;&#1057;) 2016/679 (GDPR) &#1080;&#1083;&#1080; &#1079;&#1072;&#1097;&#1086;&#1090;&#1086;
                                                &#1042;&#1080;&#1077; &#1089;&#1090;&#1077; &#1085;&#1080;
                                                &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1080;&#1083;&#1080;
                                                &#1089;&#1098;&#1075;&#1083;&#1072;&#1089;&#1080;&#1077;&#1090;&#1086; &#1089;&#1080; &#1079;&#1072;
                                                &#1090;&#1086;&#1074;&#1072; &#1085;&#1072; &#1086;&#1089;&#1085;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;
                                                &#1095;&#1083;. 6, &#1087;&#1072;&#1088;&#1072;&#1075;&#1088;&#1072;&#1092; 1,
                                                &#1073;&#1091;&#1082;&#1074;&#1072; &quot;&#1072;&quot; &#1086;&#1090;
                                                &#1056;&#1077;&#1075;&#1083;&#1072;&#1084;&#1077;&#1085;&#1090; (&#1045;&#1057;) 2016/679 (GDPR).</span></p>
                                        <ol class="c18 lst-kix_list_10-0" start="6">
                                            <li class="c28 c32 li-bullet-0"><span class="c27">&#1050;&#1072;&#1082;&#1074;&#1080;
                                                    &#1042;&#1072;&#1096;&#1080; &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;
                                                    &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1084;&#1077;?&nbsp;</span></li>
                                        </ol>
                                        <p class="c8"><span class="c1">&#1053;&#1080;&#1077; &#1089;&#1098;&#1073;&#1080;&#1088;&#1072;&#1084;&#1077;
                                                &#1080; &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1084;&#1077;
                                                &#1042;&#1072;&#1096;&#1080; &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;
                                                &#1095;&#1088;&#1077;&#1079; &#1085;&#1072;&#1096;&#1072;&#1090;&#1072;
                                                &#1091;&#1077;&#1073;&#1089;&#1090;&#1088;&#1072;&#1085;&#1080;&#1094;&#1072;, &#1074;
                                                &#1090;&#1086;&#1074;&#1072; &#1095;&#1080;&#1089;&#1083;&#1086; &#1095;&#1088;&#1077;&#1079;:
                                                &#1086;&#1085;&#1083;&#1072;&#1081;&#1085; &#1092;&#1086;&#1088;&#1084;&#1080;,
                                                &#1087;&#1086;&#1076;&#1072;&#1074;&#1072;&#1085;&#1080; &#1086;&#1090; &#1042;&#1072;&#1089; &#1085;&#1072;
                                                &#1091;&#1077;&#1073;&#1089;&#1090;&#1088;&#1072;&#1085;&#1080;&#1094;&#1072;&#1090;&#1072;
                                                &#1085;&#1080;.</span></p>
                                        <p class="c8"><span class="c1">&#1053;&#1080;&#1077; &#1084;&#1086;&#1078;&#1077;&#1084; &#1076;&#1072;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1084;&#1077;
                                                &#1089;&#1083;&#1077;&#1076;&#1085;&#1080;&#1090;&#1077;
                                                &#1082;&#1072;&#1090;&#1077;&#1075;&#1086;&#1088;&#1080;&#1080; &#1083;&#1080;&#1095;&#1085;&#1080;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;
                                                (&#1080;&#1079;&#1073;&#1088;&#1086;&#1103;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086; &#1077;
                                                &#1087;&#1088;&#1080;&#1084;&#1077;&#1088;&#1085;&#1086; &#1080;
                                                &#1085;&#1077;&#1080;&#1079;&#1095;&#1077;&#1088;&#1087;&#1072;&#1090;&#1077;&#1083;&#1085;&#1086;),
                                                &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1077;&#1085;&#1080; &#1086;&#1090;
                                                &#1042;&#1072;&#1089; &#1085;&#1072;
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072;.:&nbsp;</span></p>
                                        <p class="c2"><span class="c1"></span></p>
                                        <p class="c9"><span class="c3">&#1040;) &#1050;&#1072;&#1090;&#1077;&#1075;&#1086;&#1088;&#1080;&#1103;
                                                &bdquo;&#1054;&#1073;&#1097;&#1080; &#1083;&#1080;&#1095;&#1085;&#1080;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;&ldquo;</span></p>
                                        <p class="c9"><span class="c34">&nbsp;</span></p>
                                        <p class="c9"><span class="c1">1. &#1054;&#1073;&#1080;&#1082;&#1085;&#1086;&#1074;&#1077;&#1085;&#1080;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;</span></p>
                                        <ul class="c18 lst-kix_list_11-0">
                                            <li class="c9 c15"><span class="c1">&#1058;&#1088;&#1080; &#1080;&#1084;&#1077;&#1085;&#1072; &#1080;
                                                    &#1080;&#1085;&#1080;&#1094;&#1080;&#1072;&#1083;&#1080;</span></li>
                                            <li class="c9 c15"><span class="c1">&#1040;&#1076;&#1088;&#1077;&#1089;</span></li>
                                            <li class="c9 c15"><span class="c1">&#1045;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1072;
                                                    &#1087;&#1086;&#1097;&#1072;</span></li>
                                            <li class="c9 c15"><span class="c1">&#1058;&#1077;&#1083;&#1077;&#1092;&#1086;&#1085;</span></li>
                                        </ul>
                                        <p class="c2"><span class="c1"></span></p>
                                        <p class="c9"><span class="c1">2. &#1057;&#1087;&#1077;&#1094;&#1080;&#1092;&#1080;&#1095;&#1085;&#1080;
                                                &#1080;&#1076;&#1077;&#1085;&#1090;&#1080;&#1092;&#1080;&#1082;&#1072;&#1094;&#1080;&#1086;&#1085;&#1085;&#1080;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;</span></p>
                                        <ul class="c18 lst-kix_list_21-0 start">
                                            <li class="c9 c46 li-bullet-1"><span class="c1">&#1045;&#1076;&#1080;&#1085;&#1077;&#1085;
                                                    &#1075;&#1088;&#1072;&#1078;&#1076;&#1072;&#1085;&#1089;&#1082;&#1080;
                                                    &#1085;&#1086;&#1084;&#1077;&#1088;;</span></li>
                                            <li class="c9 c46 li-bullet-1"><span class="c1">&#1044;&#1072;&#1085;&#1085;&#1080;&#1090;&#1077; &#1086;&#1090;
                                                    &#1076;&#1086;&#1082;&#1091;&#1084;&#1077;&#1085;&#1090; &#1079;&#1072;
                                                    &#1089;&#1072;&#1084;&#1086;&#1083;&#1080;&#1095;&#1085;&#1086;&#1089;&#1090; &#1085;&#1072;
                                                    &#1083;&#1080;&#1094;&#1077;&#1090;&#1086;;</span></li>
                                            <li class="c9 c46 li-bullet-1"><span class="c42 c52">&#1044;&#1072;&#1085;&#1085;&#1080; &#1079;&#1072;
                                                    &#1084;&#1077;&#1089;&#1090;&#1086;&#1088;&#1072;&#1073;&#1086;&#1090;&#1072;,
                                                    &#1087;&#1088;&#1086;&#1092;&#1077;&#1089;&#1080;&#1103;/&#1076;&#1083;&#1098;&#1078;&#1085;&#1086;&#1089;&#1090;,
                                                    &#1090;&#1088;&#1091;&#1076;&#1086;&#1074; &#1089;&#1090;&#1072;&#1078; &#1080;
                                                    &#1086;&#1073;&#1088;&#1072;&#1079;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077;;</span></li>
                                        </ul>
                                        <p class="c2"><span class="c1"></span></p>
                                        <p class="c9"><span class="c1">3. &#1044;&#1072;&#1085;&#1085;&#1080; &#1079;&#1072;
                                                &#1079;&#1076;&#1088;&#1072;&#1074;&#1086;&#1089;&#1083;&#1086;&#1074;&#1085;&#1086;&#1090;&#1086;
                                                &#1074;&#1080; &#1089;&#1098;&#1089;&#1090;&#1086;&#1103;&#1085;&#1080;&#1077;</span></p>
                                        <ul class="c18 lst-kix_list_22-0 start">
                                            <li class="c9 c44 li-bullet-2"><span class="c1">&#1058;&#1045;&#1051;&#1050;
                                                    &#1088;&#1077;&#1096;&#1077;&#1085;&#1080;&#1103;,
                                                    &#1084;&#1077;&#1076;&#1080;&#1094;&#1080;&#1085;&#1089;&#1082;&#1080;
                                                    &#1089;&#1074;&#1080;&#1076;&#1077;&#1090;&#1077;&#1083;&#1089;&#1090;&#1074;&#1072;,
                                                    &#1073;&#1086;&#1083;&#1085;&#1080;&#1095;&#1085;&#1080;
                                                    &#1083;&#1080;&#1089;&#1090;&#1086;&#1074;&#1077; &#1080; &#1076;&#1088;.</span></li>
                                        </ul>
                                        <p class="c12"><span class="c1">4. &#1048;&#1079;&#1088;&#1072;&#1079;&#1077;&#1085;&#1080;
                                                &#1080;&#1085;&#1090;&#1077;&#1088;&#1077;&#1089;&#1080;,
                                                &#1087;&#1088;&#1077;&#1076;&#1087;&#1086;&#1095;&#1080;&#1090;&#1072;&#1085;&#1080;&#1103;,
                                                &#1084;&#1085;&#1077;&#1085;&#1080;&#1103;, &#1082;&#1086;&#1084;&#1077;&#1085;&#1090;&#1072;&#1088;&#1080;,
                                                &#1087;&#1086;&#1079;&#1080;&#1094;&#1080;&#1080; &#1080; &#1076;&#1088;&#1091;&#1075;&#1080;
                                                &#1087;&#1086;&#1076;&#1086;&#1073;&#1085;&#1080; &#1074;&#1098;&#1074;
                                                &#1074;&#1088;&#1098;&#1079;&#1082;&#1072; &#1089;
                                                &#1076;&#1077;&#1081;&#1085;&#1086;&#1089;&#1090;&#1090;&#1072; &#1085;&#1072;
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                                &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                                &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; , &#1074;
                                                &#1090;&#1086;&#1074;&#1072; &#1095;&#1080;&#1089;&#1083;&#1086; &#1079;&#1072;
                                                &#1082;&#1086;&#1085;&#1082;&#1088;&#1077;&#1090;&#1085;&#1080;
                                                &#1082;&#1072;&#1084;&#1087;&#1072;&#1085;&#1080;&#1080;,
                                                &#1080;&#1085;&#1080;&#1094;&#1080;&#1072;&#1090;&#1080;&#1074;&#1080; &#1080;&#1083;&#1080;
                                                &#1076;&#1088;&#1091;&#1075;&#1080;
                                                &#1084;&#1077;&#1088;&#1086;&#1087;&#1088;&#1080;&#1103;&#1090;&#1080;&#1103;,
                                                &#1086;&#1088;&#1075;&#1072;&#1085;&#1080;&#1079;&#1080;&#1088;&#1072;&#1085;&#1080;,
                                                &#1087;&#1086;&#1076;&#1082;&#1088;&#1077;&#1087;&#1103;&#1085;&#1080; &#1080;&#1083;&#1080;
                                                &#1092;&#1080;&#1085;&#1072;&#1085;&#1089;&#1080;&#1088;&#1072;&#1085;&#1080; &#1086;&#1090;
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                                &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                                &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo;</span></p>
                                        <p class="c31"><span class="c34">&#1041;) &#1044;&#1088;&#1091;&#1075;&#1080;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;&nbsp;</span></p>
                                        <p class="c17"><span class="c1">&#1042;&#1089;&#1103;&#1082;&#1072;&#1082;&#1074;&#1072;
                                                &#1076;&#1088;&#1091;&#1075;&#1072; &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;,
                                                &#1082;&#1086;&#1103;&#1090;&#1086; &#1084;&#1086;&#1078;&#1077; &#1076;&#1072; &#1085;&#1080;
                                                &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1080;&#1090;&#1077;
                                                &#1076;&#1086;&#1073;&#1088;&#1086;&#1074;&#1086;&#1083;&#1085;&#1086; &#1095;&#1088;&#1077;&#1079;
                                                &#1076;&#1086;&#1089;&#1090;&#1098;&#1087; &#1076;&#1086; &#1080; / &#1080;&#1083;&#1080;
                                                &#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072; &#1085;&#1072;&#1096;&#1080;&#1103;
                                                &#1091;&#1077;&#1073;&#1089;&#1072;&#1081;&#1090;,
                                                &#1074;&#1082;&#1083;&#1102;&#1095;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086;
                                                &#1087;&#1088;&#1080;&#1083;&#1086;&#1078;&#1077;&#1085;&#1080;&#1103;,
                                                &#1086;&#1087;&#1077;&#1088;&#1080;&#1088;&#1072;&#1097;&#1080; &#1085;&#1072; &#1085;&#1077;&#1075;&#1086;,
                                                &#1092;&#1086;&#1088;&#1084;&#1080; &#1079;&#1072;
                                                &#1087;&#1086;&#1087;&#1098;&#1083;&#1074;&#1072;&#1085;&#1077; &#1080; &#1090;.&#1085;.,
                                                &#1080;&#1083;&#1080; &#1095;&#1088;&#1077;&#1079; &#1076;&#1086;&#1089;&#1090;&#1098;&#1087; &#1076;&#1086;
                                                &#1080; / &#1080;&#1083;&#1080; &#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                &#1086;&#1092;&#1080;&#1094;&#1080;&#1072;&#1083;&#1085;&#1080;&#1090;&#1077;
                                                &#1089;&#1090;&#1088;&#1072;&#1085;&#1080;&#1094;&#1080; &#1085;&#1072;
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072; &#1074;
                                                &#1089;&#1086;&#1094;&#1080;&#1072;&#1083;&#1085;&#1080;&#1090;&#1077; &#1084;&#1088;&#1077;&#1078;&#1080;
                                                &ndash; Facebook; Instagram; Twitter, Linkedin, Youtube &#1080; &#1076;&#1088;.).</span></p>
                                        <p class="c25"><span class="c1"></span></p>
                                        <ol class="c18 lst-kix_list_10-0" start="7">
                                            <li class="c28 c32 li-bullet-3"><span class="c27">&#1050;&#1086;&#1081; &#1080;&#1084;&#1072;
                                                    &#1076;&#1086;&#1089;&#1090;&#1098;&#1087; &#1076;&#1086; &#1042;&#1072;&#1096;&#1072;&#1090;&#1072;
                                                    &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;?</span></li>
                                        </ol>
                                        <p class="c12"><span class="c1">&#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                                &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                                &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; &#1077;
                                                &#1072;&#1076;&#1084;&#1080;&#1085;&#1080;&#1089;&#1090;&#1088;&#1072;&#1090;&#1086;&#1088; &#1085;&#1072;
                                                &#1042;&#1072;&#1096;&#1080;&#1090;&#1077; &#1083;&#1080;&#1095;&#1085;&#1080;
                                                &#1076;&#1072;&#1085;&#1085;&#1080; &#1080; &#1074;
                                                &#1082;&#1072;&#1095;&#1077;&#1089;&#1090;&#1074;&#1086;&#1090;&#1086; &#1089;&#1080; &#1085;&#1072;
                                                &#1090;&#1072;&#1082;&#1098;&#1074; &#1085;&#1103;&#1084;&#1072; &#1076;&#1072;
                                                &#1089;&#1087;&#1086;&#1076;&#1077;&#1083;&#1103;, &#1087;&#1088;&#1077;&#1076;&#1072;&#1074;&#1072;,
                                                &#1088;&#1072;&#1079;&#1082;&#1088;&#1080;&#1074;&#1072; &#1080;&#1083;&#1080;
                                                &#1088;&#1072;&#1079;&#1087;&#1088;&#1086;&#1089;&#1090;&#1088;&#1072;&#1085;&#1103;&#1074;&#1072;
                                                &#1042;&#1072;&#1096;&#1080;&#1090;&#1077; &#1083;&#1080;&#1095;&#1085;&#1080;
                                                &#1076;&#1072;&#1085;&#1085;&#1080; &#1085;&#1072; &#1090;&#1088;&#1077;&#1090;&#1080;
                                                &#1089;&#1090;&#1088;&#1072;&#1085;&#1080;, &#1086;&#1089;&#1074;&#1077;&#1085; &#1085;&#1072;
                                                &#1090;&#1077;&#1079;&#1080; &#1087;&#1088;&#1077;&#1076;&#1074;&#1080;&#1076;&#1077;&#1085;&#1080; &#1074;
                                                &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1072;&#1090;&#1072;
                                                &#1055;&#1086;&#1083;&#1080;&#1090;&#1080;&#1082;&#1072;.
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                                &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                                &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo;
                                                &nbsp;&#1079;&#1072;&#1095;&#1080;&#1090;&#1072; &#1080; &#1087;&#1072;&#1079;&#1080;
                                                &#1087;&#1086;&#1074;&#1077;&#1088;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086;&#1089;&#1090;&#1090;&#1072;
                                                &#1085;&#1072; &#1042;&#1072;&#1096;&#1080;&#1090;&#1077; &#1083;&#1080;&#1095;&#1085;&#1080;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;. &#1055;&#1088;&#1080;
                                                &#1089;&#1087;&#1072;&#1079;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1074;&#1080;&#1090;&#1077;
                                                &#1080;&#1079;&#1080;&#1089;&#1082;&#1074;&#1072;&#1085;&#1080;&#1103; &#1080;&#1083;&#1080;
                                                &#1087;&#1088;&#1080; &#1076;&#1072;&#1076;&#1077;&#1085;&#1086; &#1086;&#1090; &#1042;&#1072;&#1089;
                                                &#1089;&#1098;&#1075;&#1083;&#1072;&#1089;&#1080;&#1077; &#1077;
                                                &#1074;&#1098;&#1079;&#1084;&#1086;&#1078;&#1085;&#1086;
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072; &#1076;&#1072;
                                                &#1088;&#1072;&#1079;&#1082;&#1088;&#1080;&#1077; &#1042;&#1072;&#1096;&#1080;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080; &#1085;&#1072;
                                                &#1090;&#1088;&#1077;&#1090;&#1080; &#1089;&#1090;&#1088;&#1072;&#1085;&#1080;,
                                                &#1082;&#1086;&#1080;&#1090;&#1086; &#1089;&#1072;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1097;&#1080;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;,
                                                &#1089;&#1098;&#1074;&#1084;&#1077;&#1089;&#1090;&#1085;&#1080; &#1080;&#1083;&#1080;
                                                &#1089;&#1072;&#1084;&#1086;&#1089;&#1090;&#1086;&#1103;&#1090;&#1077;&#1083;&#1085;&#1080;
                                                &#1072;&#1076;&#1084;&#1080;&#1085;&#1080;&#1089;&#1090;&#1088;&#1072;&#1090;&#1086;&#1088;&#1080;.&nbsp;&nbsp;</span>
                                        </p>
                                        <ol class="c18 lst-kix_list_10-0" start="8">
                                            <li class="c28 c36 li-bullet-4"><span class="c27">&#1051;&#1080;&#1094;&#1072;&#1090;&#1072;, &#1089;
                                                    &#1082;&#1086;&#1080;&#1090;&#1086; &#1089;&#1087;&#1086;&#1076;&#1077;&#1083;&#1103;&#1084;&#1077;
                                                    &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;, &#1089;&#1072;:</span></li>
                                        </ol>
                                        <ul class="c18 lst-kix_list_7-0 start">
                                            <li class="c15 c39"><span class="c1">&#1044;&#1088;&#1091;&#1075;&#1080;
                                                    &#1076;&#1088;&#1091;&#1078;&#1077;&#1089;&#1090;&#1074;&#1072; -
                                                    &#1087;&#1072;&#1088;&#1090;&#1085;&#1100;&#1086;&#1088;&#1080; &#1085;&#1072;
                                                    &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                                    &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                                    &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; &nbsp;&#1074;&#1098;&#1079;
                                                    &#1086;&#1089;&#1085;&#1086;&#1074;&#1072; &#1085;&#1072;
                                                    &#1089;&#1082;&#1083;&#1102;&#1095;&#1077;&#1085;&#1080;
                                                    &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1080; &#1089;
                                                    &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072; &#1080;
                                                    &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1103;&#1097;&#1080;
                                                    &#1074;&#1098;&#1079;&#1084;&#1086;&#1078;&#1085;&#1086;&#1089;&#1090; &#1079;&#1072;
                                                    &#1085;&#1072;&#1077;&#1084;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                    &#1088;&#1072;&#1073;&#1086;&#1090;&#1072; &#1085;&#1072;
                                                    &#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1090;&#1077;&#1083;&#1080;&#1090;&#1077; &#1085;&#1072;
                                                    &#1091;&#1089;&#1083;&#1091;&#1075;&#1080;&#1090;&#1077; &#1085;&#1072;
                                                    &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072;;</span></li>
                                            <li class="c8 c15"><span
                                                    class="c1">&#1056;&#1077;&#1075;&#1091;&#1083;&#1072;&#1090;&#1086;&#1088;&#1085;&#1080;
                                                    &#1085;&#1072;&#1094;&#1080;&#1086;&#1085;&#1072;&#1083;&#1085;&#1080; &#1080;
                                                    &#1077;&#1074;&#1088;&#1086;&#1087;&#1077;&#1081;&#1089;&#1082;&#1080;
                                                    &#1072;&#1076;&#1084;&#1080;&#1085;&#1080;&#1089;&#1090;&#1088;&#1072;&#1090;&#1080;&#1074;&#1085;&#1080;
                                                    &#1086;&#1088;&#1075;&#1072;&#1085;&#1080;,
                                                    &#1087;&#1086;&#1083;&#1091;&#1095;&#1072;&#1074;&#1072;&#1097;&#1080;
                                                    &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072;
                                                    &#1074;&#1098;&#1079; &#1086;&#1089;&#1085;&#1086;&#1074;&#1072; &#1085;&#1072;
                                                    &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1074;&#1080;
                                                    &#1087;&#1088;&#1072;&#1074;&#1086;&#1084;&#1086;&#1097;&#1080;&#1103; &#1076;&#1072;
                                                    &#1080;&#1079;&#1080;&#1089;&#1082;&#1074;&#1072;&#1090; &#1080;
                                                    &#1087;&#1086;&#1083;&#1091;&#1095;&#1072;&#1074;&#1072;&#1090;
                                                    &#1090;&#1072;&#1082;&#1072;&#1074;&#1072;
                                                    &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;,
                                                    &#1082;&#1072;&#1082;&#1090;&#1086; &#1080; &#1076;&#1088;&#1091;&#1075;&#1080;
                                                    &#1076;&#1098;&#1088;&#1078;&#1072;&#1074;&#1085;&#1080; &#1086;&#1088;&#1075;&#1072;&#1085;&#1080;
                                                    &#1074; &#1087;&#1088;&#1077;&#1076;&#1074;&#1080;&#1076;&#1077;&#1085;&#1080;&#1090;&#1077;
                                                    &#1086;&#1090; &#1079;&#1072;&#1082;&#1086;&#1085;&#1072; &#1089;&#1083;&#1091;&#1095;&#1072;&#1080;,
                                                    &#1074;&#1098;&#1074; &#1074;&#1088;&#1098;&#1079;&#1082;&#1072; &#1089;
                                                    &#1080;&#1079;&#1087;&#1098;&#1083;&#1085;&#1077;&#1085;&#1080;&#1077; &#1085;&#1072;
                                                    &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1074;&#1080;
                                                    &#1079;&#1072;&#1076;&#1098;&#1083;&#1078;&#1077;&#1085;&#1080;&#1103; &#1080;&#1083;&#1080;
                                                    &#1074;&#1098;&#1074; &#1074;&#1088;&#1098;&#1079;&#1082;&#1072; &#1089;&#1098;&#1089;
                                                    &#1089;&#1098;&#1076;&#1077;&#1073;&#1085;&#1080;
                                                    &#1087;&#1088;&#1086;&#1080;&#1079;&#1074;&#1086;&#1076;&#1089;&#1090;&#1074;&#1072;.</span></li>
                                            <li class="c15 c48"><span
                                                    class="c1">&#1054;&#1093;&#1088;&#1072;&#1085;&#1080;&#1090;&#1077;&#1083;&#1085;&#1080;
                                                    &#1092;&#1080;&#1088;&#1084;&#1080;,
                                                    &#1087;&#1088;&#1080;&#1090;&#1077;&#1078;&#1072;&#1074;&#1072;&#1097;&#1080;
                                                    &#1083;&#1080;&#1094;&#1077;&#1085;&#1079; &#1079;&#1072;
                                                    &#1080;&#1079;&#1074;&#1098;&#1088;&#1096;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                    &#1095;&#1072;&#1089;&#1090;&#1085;&#1072;
                                                    &#1086;&#1093;&#1088;&#1072;&#1085;&#1080;&#1090;&#1077;&#1083;&#1085;&#1072;
                                                    &#1076;&#1077;&#1081;&#1085;&#1086;&#1089;&#1090;,
                                                    &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1097;&#1080;
                                                    &#1074;&#1080;&#1076;&#1077;&#1086;&#1079;&#1072;&#1087;&#1080;&#1089;&#1080;&#1090;&#1077;
                                                    &#1086;&#1090; &#1086;&#1092;&#1080;&#1089;&#1080; &#1085;&#1072; &#1051;&#1077;&#1085;&#1086;
                                                    &#1080;/&#1080;&#1083;&#1080; &#1087;&#1086;&#1076;&#1076;&#1098;&#1088;&#1078;&#1072;&#1097;&#1080;
                                                    &#1076;&#1088;&#1091;&#1075;&#1080; &#1088;&#1077;&#1075;&#1080;&#1089;&#1090;&#1088;&#1080; &#1074;
                                                    &#1087;&#1088;&#1086;&#1094;&#1077;&#1089;&#1072; &#1085;&#1072;
                                                    &#1086;&#1089;&#1080;&#1075;&#1091;&#1088;&#1103;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                    &#1087;&#1088;&#1086;&#1087;&#1091;&#1089;&#1082;&#1072;&#1090;&#1077;&#1083;&#1085;&#1080;&#1103;
                                                    &#1088;&#1077;&#1078;&#1080;&#1084; &#1074; &#1089;&#1098;&#1097;&#1080;&#1090;&#1077;
                                                    &#1086;&#1073;&#1077;&#1082;&#1090;&#1080;;</span></li>
                                            <li class="c8 c15"><span class="c1">&#1044;&#1088;&#1091;&#1078;&#1077;&#1089;&#1090;&#1074;&#1072;,
                                                    &#1091;&#1095;&#1072;&#1089;&#1090;&#1074;&#1072;&#1097;&#1080; &#1074;
                                                    &#1087;&#1088;&#1086;&#1094;&#1077;&#1089;&#1072; &#1087;&#1086;
                                                    &#1085;&#1072;&#1077;&#1084;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                    &#1088;&#1072;&#1073;&#1086;&#1090;&#1072; &#1085;&#1072;
                                                    &#1088;&#1072;&#1073;&#1086;&#1090;&#1085;&#1080;&#1094;&#1080; &#1080;
                                                    &#1089;&#1083;&#1091;&#1078;&#1080;&#1090;&#1077;&#1083;&#1080;.</span></li>
                                            <li class="c8 c15"><span class="c1">&#1044;&#1088;&#1091;&#1078;&#1077;&#1089;&#1090;&#1074;&#1072; &#1080;
                                                    &#1086;&#1088;&#1075;&#1072;&#1085;&#1080;&#1079;&#1072;&#1094;&#1080;&#1080;
                                                    &#1087;&#1086;&#1076;&#1076;&#1098;&#1088;&#1078;&#1072;&#1097;&#1080;
                                                    &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;
                                                    &#1089;&#1074;&#1098;&#1088;&#1079;&#1072;&#1085;&#1072; &#1089;&#1098;&#1089;
                                                    &#1079;&#1076;&#1088;&#1072;&#1074;&#1085;&#1080;&#1103; &#1089;&#1090;&#1072;&#1090;&#1091;&#1089;
                                                    &#1085;&#1072; &#1083;&#1080;&#1094;&#1072;&#1090;&#1072;.</span></li>
                                            <li class="c4"><span class="c1">&#1044;&#1098;&#1088;&#1078;&#1072;&#1074;&#1085;&#1080; &#1080;
                                                    &#1086;&#1073;&#1097;&#1080;&#1085;&#1089;&#1082;&#1080; &#1086;&#1088;&#1075;&#1072;&#1085;&#1080;,
                                                    &#1089;&#1074;&#1098;&#1088;&#1079;&#1072;&#1085;&#1080; &#1089;
                                                    &#1080;&#1079;&#1087;&#1098;&#1083;&#1085;&#1077;&#1085;&#1080;&#1077;&#1090;&#1086; &#1085;&#1072;
                                                    &#1079;&#1072;&#1076;&#1098;&#1083;&#1078;&#1077;&#1085;&#1080;&#1077;&#1090;&#1086; &#1085;&#1072;
                                                    &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072;,
                                                    &#1087;&#1088;&#1086;&#1080;&#1079;&#1090;&#1080;&#1095;&#1072;&#1097;&#1080; &#1086;&#1090;
                                                    &#1076;&#1072;&#1085;&#1098;&#1095;&#1085;&#1086;&#1090;&#1086; &#1080;
                                                    &#1086;&#1089;&#1080;&#1075;&#1091;&#1088;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086;&#1090;&#1086;
                                                    &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1090;&#1077;&#1083;&#1089;&#1090;&#1074;&#1086;.</span>
                                            </li>
                                        </ul>
                                        <ol class="c18 lst-kix_list_10-0" start="9">
                                            <li class="c28 c29"><span class="c27">&#1047;&#1072; &#1082;&#1072;&#1082;&#1098;&#1074;
                                                    &#1089;&#1088;&#1086;&#1082;
                                                    &#1089;&#1098;&#1093;&#1088;&#1072;&#1085;&#1103;&#1074;&#1072;&#1084;&#1077;
                                                    &#1042;&#1072;&#1096;&#1080;&#1090;&#1077; &#1083;&#1080;&#1095;&#1085;&#1080;
                                                    &#1076;&#1072;&#1085;&#1085;&#1080;?</span></li>
                                        </ol>
                                        <p class="c12"><span class="c1">&#1051;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1042;&#1080;
                                                &#1076;&#1072;&#1085;&#1085;&#1080; &#1097;&#1077; &#1073;&#1098;&#1076;&#1072;&#1090;
                                                &#1089;&#1098;&#1093;&#1088;&#1072;&#1085;&#1103;&#1074;&#1072;&#1085;&#1080; &#1079;&#1072;
                                                &#1086;&#1087;&#1088;&#1077;&#1076;&#1077;&#1083;&#1077;&#1085; &#1089;&#1088;&#1086;&#1082; &#1086;&#1090;
                                                &#1074;&#1088;&#1077;&#1084;&#1077;, &#1077;&#1076;&#1080;&#1085;&#1089;&#1090;&#1074;&#1077;&#1085;&#1086;
                                                &#1079;&#1072; &#1094;&#1077;&#1083;&#1080;&#1090;&#1077;, &#1079;&#1072;
                                                &#1082;&#1086;&#1080;&#1090;&#1086; &#1089;&#1072; &#1073;&#1080;&#1083;&#1080;
                                                &#1089;&#1098;&#1073;&#1088;&#1072;&#1085;&#1080; &#1080; &#1089;&#1077;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1090;. &#1058;&#1077;
                                                &#1084;&#1086;&#1075;&#1072;&#1090; &#1076;&#1072; &#1073;&#1098;&#1076;&#1072;&#1090;
                                                &#1089;&#1098;&#1093;&#1088;&#1072;&#1085;&#1103;&#1074;&#1072;&#1085;&#1080;
                                                &#1082;&#1072;&#1082;&#1090;&#1086; &#1085;&#1072; &#1093;&#1072;&#1088;&#1090;&#1080;&#1077;&#1085;,
                                                &#1090;&#1072;&#1082;&#1072; &#1080; &#1085;&#1072;
                                                &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1077;&#1085;
                                                &#1085;&#1086;&#1089;&#1080;&#1090;&#1077;&#1083;. &#1053;&#1072;&#1087;&#1088;&#1080;&#1084;&#1077;&#1088;,
                                                &#1076;&#1072;&#1085;&#1085;&#1080;&#1090;&#1077; &#1042;&#1080; &#1079;&#1072;
                                                &#1082;&#1086;&#1085;&#1090;&#1072;&#1082;&#1090; &#1097;&#1077; &#1073;&#1098;&#1076;&#1072;&#1090;
                                                &#1089;&#1098;&#1093;&#1088;&#1072;&#1085;&#1103;&#1074;&#1072;&#1085;&#1080; &#1080;
                                                &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1080;
                                                &#1077;&#1076;&#1080;&#1085;&#1089;&#1090;&#1074;&#1077;&#1085;&#1086; &#1080; &#1089;&#1072;&#1084;&#1086;
                                                &#1079;&#1072; &#1080;&#1079;&#1087;&#1088;&#1072;&#1097;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;, &#1082;&#1086;&#1103;&#1090;&#1086;
                                                &#1042;&#1080;&#1077; &#1089;&#1090;&#1077; &#1087;&#1086;&#1080;&#1089;&#1082;&#1072;&#1083;&#1080;
                                                &#1086;&#1090; &#1085;&#1072;&#1089;, &#1076;&#1086;&#1082;&#1072;&#1090;&#1086; &#1085;&#1077;
                                                &#1086;&#1090;&#1090;&#1077;&#1075;&#1083;&#1080;&#1090;&#1077;
                                                &#1076;&#1072;&#1076;&#1077;&#1085;&#1086;&#1090;&#1086;
                                                &#1089;&#1098;&#1075;&#1083;&#1072;&#1089;&#1080;&#1077;.</span></p>
                                        <ol class="c18 lst-kix_list_10-0" start="10">
                                            <li class="c12 c32 li-bullet-3"><span class="c16">&#1052;&#1077;&#1088;&#1082;&#1080; &#1079;&#1072;
                                                    &#1079;&#1072;&#1097;&#1080;&#1090;&#1072; &#1085;&#1072;
                                                    &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1076;&#1072;&#1085;&#1085;&#1080;</span></li>
                                        </ol>
                                        <p class="c9"><span class="c3">&#1058;&#1077;&#1093;&#1085;&#1080;&#1095;&#1077;&#1089;&#1082;&#1080;
                                                &#1084;&#1077;&#1088;&#1082;&#1080;</span></p>
                                        <p class="c2"><span class="c3"></span></p>
                                        <p class="c8"><span class="c1">&#1051;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1076;&#1072;&#1085;&#1085;&#1080;
                                                &#1089;&#1077; &#1089;&#1098;&#1093;&#1088;&#1072;&#1085;&#1103;&#1074;&#1072;&#1090; &#1086;&#1090;
                                                &#1089;&#1090;&#1088;&#1072;&#1085;&#1072; &#1085;&#1072;
                                                &#1040;&#1076;&#1084;&#1080;&#1085;&#1080;&#1089;&#1090;&#1088;&#1072;&#1090;&#1086;&#1088;&#1072;
                                                &#1077;&#1076;&#1080;&#1085;&#1089;&#1090;&#1074;&#1077;&#1085;&#1086; &#1085;&#1072;
                                                &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1077;&#1085;
                                                &#1085;&#1086;&#1089;&#1080;&#1090;&#1077;&#1083;.</span></p>
                                        <p class="c6"><span class="c1"></span></p>
                                        <p class="c8"><span class="c1">&#1047;&#1072;&#1097;&#1080;&#1090;&#1072;&#1090;&#1072; &#1085;&#1072;
                                                &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1080;&#1090;&#1077;
                                                &#1076;&#1072;&#1085;&#1085;&#1080; &#1086;&#1090;
                                                &#1085;&#1077;&#1087;&#1088;&#1072;&#1074;&#1086;&#1084;&#1077;&#1088;&#1077;&#1085;
                                                &#1076;&#1086;&#1089;&#1090;&#1098;&#1087;,
                                                &#1087;&#1086;&#1074;&#1088;&#1077;&#1078;&#1076;&#1072;&#1085;&#1077;,
                                                &#1080;&#1079;&#1075;&#1091;&#1073;&#1074;&#1072;&#1085;&#1077; &#1080;&#1083;&#1080;
                                                &#1091;&#1085;&#1080;&#1097;&#1086;&#1078;&#1072;&#1074;&#1072;&#1085;&#1077;,
                                                &#1080;&#1079;&#1074;&#1098;&#1088;&#1096;&#1077;&#1085;&#1080;
                                                &#1091;&#1084;&#1080;&#1096;&#1083;&#1077;&#1085;&#1086; &#1086;&#1090; &#1083;&#1080;&#1094;&#1077;
                                                &#1080;&#1083;&#1080; &#1074; &#1089;&#1083;&#1091;&#1095;&#1072;&#1081; &#1085;&#1072;
                                                &#1090;&#1077;&#1093;&#1085;&#1080;&#1095;&#1077;&#1089;&#1082;&#1080;
                                                &#1085;&#1077;&#1080;&#1079;&#1087;&#1088;&#1072;&#1074;&#1085;&#1086;&#1089;&#1090;&#1080;,
                                                &#1072;&#1074;&#1072;&#1088;&#1080;&#1080;,
                                                &#1087;&#1088;&#1086;&#1080;&#1079;&#1096;&#1077;&#1089;&#1090;&#1074;&#1080;&#1103;,
                                                &#1073;&#1077;&#1076;&#1089;&#1090;&#1074;&#1080;&#1103;, &#1076;&#1088;., &#1089;&#1077;
                                                &#1086;&#1089;&#1080;&#1075;&#1091;&#1088;&#1103;&#1074;&#1072;
                                                &#1087;&#1086;&#1089;&#1088;&#1077;&#1076;&#1089;&#1090;&#1074;&#1086;&#1084;:</span></p>
                                        <p class="c8"><span class="c1">- &#1074;&#1098;&#1074;&#1077;&#1078;&#1076;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                &#1087;&#1072;&#1088;&#1086;&#1083;&#1080; &#1079;&#1072;
                                                &#1082;&#1086;&#1084;&#1087;&#1102;&#1090;&#1088;&#1080;&#1090;&#1077;, &#1095;&#1088;&#1077;&#1079;
                                                &#1082;&#1086;&#1080;&#1090;&#1086; &#1089;&#1077;
                                                &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1103;
                                                &#1076;&#1086;&#1089;&#1090;&#1098;&#1087; &#1076;&#1086; &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;, &#1080;
                                                &#1092;&#1072;&#1081;&#1083;&#1086;&#1074;&#1077;&#1090;&#1077;, &#1082;&#1086;&#1080;&#1090;&#1086;
                                                &#1089;&#1098;&#1076;&#1098;&#1088;&#1078;&#1072;&#1090; &#1083;&#1080;&#1095;&#1085;&#1080;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;;</span></p>
                                        <p class="c8"><span class="c1">- &#1072;&#1085;&#1090;&#1080;&#1074;&#1080;&#1088;&#1091;&#1089;&#1085;&#1080;
                                                &#1087;&#1088;&#1086;&#1075;&#1088;&#1072;&#1084;&#1080;,
                                                &#1087;&#1088;&#1086;&#1074;&#1077;&#1088;&#1082;&#1080; &#1079;&#1072;
                                                &#1085;&#1077;&#1083;&#1077;&#1075;&#1072;&#1083;&#1085;&#1086;
                                                &#1080;&#1085;&#1089;&#1090;&#1072;&#1083;&#1080;&#1088;&#1072;&#1085;
                                                &#1089;&#1086;&#1092;&#1090;&#1091;&#1077;&#1088;;</span></p>
                                        <p class="c8"><span class="c1">- &#1087;&#1077;&#1088;&#1080;&#1086;&#1076;&#1080;&#1095;&#1085;&#1080;
                                                &#1087;&#1088;&#1086;&#1074;&#1077;&#1088;&#1082;&#1080; &#1085;&#1072;
                                                &#1094;&#1077;&#1083;&#1086;&#1089;&#1090;&#1090;&#1072; &#1085;&#1072;
                                                &#1073;&#1072;&#1079;&#1072;&#1090;&#1072; &#1076;&#1072;&#1085;&#1085;&#1080; &#1080;
                                                &#1072;&#1082;&#1090;&#1091;&#1072;&#1083;&#1080;&#1079;&#1080;&#1088;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1085;&#1072;&#1090;&#1072;
                                                &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;,
                                                &#1087;&#1086;&#1076;&#1076;&#1098;&#1088;&#1078;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1072;&#1090;&#1072; &#1079;&#1072;
                                                &#1076;&#1086;&#1089;&#1090;&#1098;&#1087; &#1076;&#1086;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;&#1090;&#1077;;</span></p>
                                        <p class="c8"><span class="c1">- &#1087;&#1077;&#1088;&#1080;&#1086;&#1076;&#1080;&#1095;&#1085;&#1086;
                                                &#1072;&#1088;&#1093;&#1080;&#1074;&#1080;&#1088;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;&#1090;&#1077; &#1085;&#1072;
                                                &#1090;&#1077;&#1093;&#1085;&#1080;&#1095;&#1077;&#1089;&#1082;&#1080;
                                                &#1085;&#1086;&#1089;&#1080;&#1090;&#1077;&#1083;&#1080;,
                                                &#1087;&#1086;&#1076;&#1076;&#1098;&#1088;&#1078;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072; &#1085;&#1072;
                                                &#1093;&#1072;&#1088;&#1090;&#1080;&#1077;&#1085; &#1085;&#1086;&#1089;&#1080;&#1090;&#1077;&#1083;
                                                (&#1072;&#1088;&#1093;&#1080;&#1074;&#1085;&#1080; &#1082;&#1086;&#1087;&#1080;&#1103;).</span></p>
                                        <p class="c6"><span class="c1"></span></p>
                                        <p class="c9"><span class="c3">&#1052;&#1077;&#1088;&#1082;&#1080; &#1079;&#1072;
                                                &#1076;&#1086;&#1082;&#1091;&#1084;&#1077;&#1085;&#1090;&#1072;&#1083;&#1085;&#1072;
                                                &#1079;&#1072;&#1097;&#1080;&#1090;&#1072;</span></p>
                                        <p class="c2"><span class="c3"></span></p>
                                        <p class="c8"><span class="c1">&#1044;&#1088;&#1091;&#1078;&#1077;&#1089;&#1090;&#1074;&#1086;&#1090;&#1086;
                                                &#1091;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1103;&#1074;&#1072;
                                                &#1087;&#1088;&#1086;&#1094;&#1077;&#1076;&#1091;&#1088;&#1080; &#1087;&#1086;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;,
                                                &#1088;&#1077;&#1075;&#1083;&#1072;&#1084;&#1077;&#1085;&#1090;&#1080;&#1088;&#1072;&#1085;&#1077;
                                                &#1085;&#1072; &#1076;&#1086;&#1089;&#1090;&#1098;&#1087;&#1072; &#1076;&#1086;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;&#1090;&#1077;,
                                                &#1087;&#1088;&#1086;&#1094;&#1077;&#1076;&#1091;&#1088;&#1080; &#1087;&#1086;
                                                &#1091;&#1085;&#1080;&#1097;&#1086;&#1078;&#1072;&#1074;&#1072;&#1085;&#1077; &#1080;
                                                &#1089;&#1088;&#1086;&#1082;&#1086;&#1074;&#1077; &#1079;&#1072;
                                                &#1089;&#1098;&#1093;&#1088;&#1072;&#1085;&#1077;&#1085;&#1080;&#1077;,
                                                &#1087;&#1086;&#1076;&#1088;&#1086;&#1073;&#1085;&#1086;
                                                &#1088;&#1072;&#1079;&#1087;&#1080;&#1089;&#1072;&#1085;&#1080; &#1074; &#1090;&#1077;&#1079;&#1080;
                                                &#1055;&#1088;&#1072;&#1074;&#1080;&#1083;&#1072;. </span></p>
                                        <p class="c6"><span class="c1"></span></p>
                                        <p class="c8"><span
                                                class="c1">&#1056;&#1072;&#1079;&#1084;&#1085;&#1086;&#1078;&#1072;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086;
                                                &#1080;
                                                &#1088;&#1072;&#1079;&#1087;&#1088;&#1086;&#1089;&#1090;&#1088;&#1072;&#1085;&#1077;&#1085;&#1080;&#1077;&#1090;&#1086;
                                                &#1085;&#1072; &#1076;&#1086;&#1082;&#1091;&#1084;&#1077;&#1085;&#1090;&#1080; &#1080;&#1083;&#1080;
                                                &#1092;&#1072;&#1081;&#1083;&#1086;&#1074;&#1077;,
                                                &#1089;&#1098;&#1076;&#1098;&#1088;&#1078;&#1072;&#1097;&#1080; &#1083;&#1080;&#1095;&#1085;&#1080;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;, &#1089;&#1077; &#1080;&#1079;&#1074;&#1098;&#1088;&#1096;&#1074;&#1072;
                                                &#1089;&#1072;&#1084;&#1086; &#1080; &#1077;&#1076;&#1080;&#1085;&#1089;&#1090;&#1074;&#1077;&#1085;&#1086;
                                                &#1086;&#1090; &#1091;&#1087;&#1098;&#1083;&#1085;&#1086;&#1084;&#1086;&#1097;&#1077;&#1085;&#1080;
                                                &#1089;&#1083;&#1091;&#1078;&#1080;&#1090;&#1077;&#1083;&#1080; &#1087;&#1088;&#1080;
                                                &#1074;&#1098;&#1079;&#1085;&#1080;&#1082;&#1085;&#1072;&#1083;&#1072;
                                                &#1085;&#1077;&#1086;&#1073;&#1093;&#1086;&#1076;&#1080;&#1084;&#1086;&#1089;&#1090;. </span></p>
                                        <p class="c6"><span class="c1"></span></p>
                                        <p class="c9"><span class="c3">&#1055;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1085;&#1080;
                                                &#1084;&#1077;&#1088;&#1082;&#1080; &#1085;&#1072; &#1079;&#1072;&#1097;&#1080;&#1090;&#1072;</span></p>
                                        <p class="c2"><span class="c3"></span></p>
                                        <p class="c8"><span class="c1">&#1055;&#1088;&#1077;&#1076;&#1080; &#1079;&#1072;&#1077;&#1084;&#1072;&#1085;&#1077;
                                                &#1085;&#1072; &#1089;&#1098;&#1086;&#1090;&#1074;&#1077;&#1090;&#1085;&#1072;&#1090;&#1072;
                                                &#1076;&#1083;&#1098;&#1078;&#1085;&#1086;&#1089;&#1090; &#1083;&#1080;&#1094;&#1072;&#1090;&#1072;,
                                                &#1082;&#1086;&#1080;&#1090;&#1086;
                                                &#1086;&#1089;&#1098;&#1097;&#1077;&#1089;&#1090;&#1074;&#1103;&#1074;&#1072;&#1090;
                                                &#1079;&#1072;&#1097;&#1080;&#1090;&#1072; &#1080;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1076;&#1072;&#1085;&#1085;&#1080;: </span></p>
                                        <p class="c8"><span class="c1">- &#1087;&#1086;&#1077;&#1084;&#1072;&#1090;
                                                &#1079;&#1072;&#1076;&#1098;&#1083;&#1078;&#1077;&#1085;&#1080;&#1077; &#1079;&#1072;
                                                &#1085;&#1077;&#1088;&#1072;&#1079;&#1087;&#1088;&#1086;&#1089;&#1090;&#1088;&#1072;&#1085;&#1077;&#1085;&#1080;&#1077;
                                                &#1085;&#1072; &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1076;&#1072;&#1085;&#1085;&#1080;,
                                                &#1076;&#1086; &#1082;&#1086;&#1080;&#1090;&#1086; &#1080;&#1084;&#1072;&#1090;
                                                &#1076;&#1086;&#1089;&#1090;&#1098;&#1087; &#1080;
                                                &#1087;&#1086;&#1076;&#1087;&#1080;&#1089;&#1074;&#1072;&#1090;
                                                &#1076;&#1077;&#1082;&#1083;&#1072;&#1088;&#1072;&#1094;&#1080;&#1103; &#1079;&#1072;
                                                &#1082;&#1086;&#1085;&#1092;&#1080;&#1076;&#1077;&#1085;&#1094;&#1080;&#1072;&#1083;&#1085;&#1086;&#1089;&#1090;;</span>
                                        </p>
                                        <p class="c8"><span class="c1">- &#1089;&#1077;
                                                &#1079;&#1072;&#1087;&#1086;&#1079;&#1085;&#1072;&#1074;&#1072;&#1090; &#1089;
                                                &#1085;&#1086;&#1088;&#1084;&#1072;&#1090;&#1080;&#1074;&#1085;&#1072;&#1090;&#1072;
                                                &#1073;&#1072;&#1079;&#1072;, &#1074;&#1098;&#1090;&#1088;&#1077;&#1096;&#1085;&#1080;&#1090;&#1077;
                                                &#1087;&#1088;&#1072;&#1074;&#1080;&#1083;&#1072; &#1080;
                                                &#1087;&#1086;&#1083;&#1080;&#1090;&#1080;&#1082;&#1080; &#1085;&#1072;
                                                &#1076;&#1088;&#1091;&#1078;&#1077;&#1089;&#1090;&#1074;&#1086;&#1090;&#1086;
                                                &#1086;&#1090;&#1085;&#1086;&#1089;&#1085;&#1086; &#1079;&#1072;&#1097;&#1080;&#1090;&#1072;&#1090;&#1072;
                                                &#1085;&#1072; &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1076;&#1072;&#1085;&#1085;&#1080;;</span>
                                        </p>
                                        <p class="c8"><span class="c1">- &#1087;&#1088;&#1077;&#1084;&#1080;&#1085;&#1072;&#1074;&#1072;&#1090;
                                                &#1086;&#1073;&#1091;&#1095;&#1077;&#1085;&#1080;&#1077; &#1079;&#1072;
                                                &#1088;&#1077;&#1072;&#1082;&#1094;&#1080;&#1103; &#1087;&#1088;&#1080;
                                                &#1089;&#1098;&#1073;&#1080;&#1090;&#1080;&#1103;,
                                                &#1079;&#1072;&#1089;&#1090;&#1088;&#1072;&#1096;&#1072;&#1074;&#1072;&#1097;&#1080;
                                                &#1089;&#1080;&#1075;&#1091;&#1088;&#1085;&#1086;&#1089;&#1090;&#1090;&#1072; &#1085;&#1072;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;&#1090;&#1077;;</span></p>
                                        <p class="c8"><span class="c1">- &#1089;&#1072;
                                                &#1080;&#1085;&#1089;&#1090;&#1088;&#1091;&#1082;&#1090;&#1080;&#1088;&#1072;&#1085;&#1080; &#1079;&#1072;
                                                &#1086;&#1087;&#1072;&#1089;&#1085;&#1086;&#1089;&#1090;&#1080;&#1090;&#1077; &#1079;&#1072;
                                                &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1076;&#1072;&#1085;&#1085;&#1080;,
                                                &#1082;&#1086;&#1080;&#1090;&#1086; &#1089;&#1077;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1090; &#1086;&#1090;
                                                &#1076;&#1088;&#1091;&#1078;&#1077;&#1089;&#1090;&#1074;&#1086;&#1090;&#1086;;</span></p>
                                        <p class="c8"><span class="c1">- &#1089;&#1077;
                                                &#1079;&#1072;&#1076;&#1098;&#1083;&#1078;&#1072;&#1074;&#1072;&#1090; &#1076;&#1072; &#1085;&#1077;
                                                &#1089;&#1087;&#1086;&#1076;&#1077;&#1083;&#1103;&#1090;
                                                &#1082;&#1088;&#1080;&#1090;&#1080;&#1095;&#1085;&#1072;
                                                &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103;
                                                &#1087;&#1086;&#1084;&#1077;&#1078;&#1076;&#1091; &#1089;&#1080; &#1080; &#1089;
                                                &#1074;&#1098;&#1085;&#1096;&#1085;&#1080; &#1083;&#1080;&#1094;&#1072;, &#1086;&#1089;&#1074;&#1077;&#1085;
                                                &#1087;&#1086; &#1091;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1077;&#1085;&#1080;&#1103; &#1089;
                                                &#1090;&#1077;&#1079;&#1080; &#1055;&#1088;&#1072;&#1074;&#1080;&#1083;&#1072; &#1088;&#1077;&#1076;.</span>
                                        </p>
                                        <p class="c6"><span class="c1"></span></p>
                                        <p class="c8"><span class="c1">&#1055;&#1086;&#1089;&#1083;&#1077;&#1076;&#1074;&#1072;&#1097;&#1080;
                                                &#1086;&#1073;&#1091;&#1095;&#1077;&#1085;&#1080;&#1103; &#1080;
                                                &#1090;&#1088;&#1077;&#1085;&#1080;&#1088;&#1086;&#1074;&#1082;&#1080; &#1085;&#1072;
                                                &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1072; &#1089;&#1077;
                                                &#1087;&#1088;&#1086;&#1074;&#1077;&#1078;&#1076;&#1072;&#1090;
                                                &#1087;&#1077;&#1088;&#1080;&#1086;&#1076;&#1080;&#1095;&#1085;&#1086;, &#1079;&#1072; &#1076;&#1072;
                                                &#1089;&#1077; &#1075;&#1072;&#1088;&#1072;&#1085;&#1090;&#1080;&#1088;&#1072;
                                                &#1087;&#1086;&#1079;&#1085;&#1072;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                &#1085;&#1086;&#1088;&#1084;&#1072;&#1090;&#1080;&#1074;&#1085;&#1072;&#1090;&#1072;
                                                &#1091;&#1088;&#1077;&#1076;&#1073;&#1072;,
                                                &#1087;&#1086;&#1090;&#1077;&#1085;&#1094;&#1080;&#1072;&#1083;&#1085;&#1080;&#1090;&#1077;
                                                &#1088;&#1080;&#1089;&#1082;&#1086;&#1074;&#1077; &#1079;&#1072;
                                                &#1089;&#1080;&#1075;&#1091;&#1088;&#1085;&#1086;&#1089;&#1090;&#1090;&#1072; &#1085;&#1072;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;&#1090;&#1077; &#1080; &#1084;&#1077;&#1088;&#1082;&#1080;&#1090;&#1077;
                                                &#1079;&#1072; &#1085;&#1072;&#1084;&#1072;&#1083;&#1103;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086;
                                                &#1080;&#1084;. </span></p>
                                        <p class="c35"><span class="c1"></span></p>
                                        <ol class="c18 lst-kix_list_10-0" start="11">
                                            <li class="c22"><span class="c27">&#1042;&#1072;&#1096;&#1080;&#1090;&#1077; &#1087;&#1088;&#1072;&#1074;&#1072;
                                                    &#1087;&#1086; &#1086;&#1090;&#1085;&#1086;&#1096;&#1077;&#1085;&#1080;&#1077; &#1085;&#1072;
                                                    &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1042;&#1080;
                                                    &#1076;&#1072;&#1085;&#1085;&#1080;</span></li>
                                        </ol>
                                        <p class="c12"><span class="c1">&#1055;&#1088;&#1080; &#1089;&#1087;&#1072;&#1079;&#1074;&#1072;&#1085;&#1077;
                                                &#1085;&#1072; &#1073;&#1098;&#1083;&#1075;&#1072;&#1088;&#1089;&#1082;&#1086;&#1090;&#1086; &#1080;
                                                &#1077;&#1074;&#1088;&#1086;&#1087;&#1077;&#1081;&#1089;&#1082;&#1086;&#1090;&#1086;
                                                &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1090;&#1077;&#1083;&#1089;&#1090;&#1074;&#1086;,
                                                &#1074;&#1082;&#1083;&#1102;&#1095;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086;
                                                &#1056;&#1077;&#1075;&#1083;&#1072;&#1084;&#1077;&#1085;&#1090; (&#1045;&#1057;) 2016/679,
                                                &#1042;&#1080;&#1077; &#1080;&#1084;&#1072;&#1090;&#1077;
                                                &#1089;&#1083;&#1077;&#1076;&#1085;&#1080;&#1090;&#1077; &#1087;&#1088;&#1072;&#1074;&#1072;
                                                &#1089;&#1087;&#1088;&#1103;&#1084;&#1086; &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1042;&#1080;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;,
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1085;&#1080; &#1086;&#1090;
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                                &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                                &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; :</span></p>
                                        <p class="c24"><span class="c3">1. &nbsp;&#1054;&#1073;&#1097;&#1080; &#1087;&#1088;&#1072;&#1074;&#1072;</span></p>
                                        <p class="c24"><span class="c1">&#1042;&#1098;&#1074; &#1074;&#1088;&#1098;&#1079;&#1082;&#1072; &#1089;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086; &#1085;&#1072;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;, &#1080;&#1084;&#1072;&#1090;&#1077;
                                                &#1076;&#1086;&#1083;&#1091;&#1086;&#1087;&#1080;&#1089;&#1072;&#1085;&#1080;&#1090;&#1077;
                                                &#1087;&#1088;&#1072;&#1074;&#1072;, &#1082;&#1086;&#1080;&#1090;&#1086; &#1084;&#1086;&#1078;&#1077;
                                                &#1076;&#1072; &#1091;&#1087;&#1088;&#1072;&#1078;&#1085;&#1080;&#1090;&#1077; &#1074;&#1098;&#1074;
                                                &#1074;&#1089;&#1077;&#1082;&#1080; &#1084;&#1086;&#1084;&#1077;&#1085;&#1090;,
                                                &#1076;&#1086;&#1082;&#1072;&#1090;&#1086; &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                                &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                                &#1044;&#1086;&#1088;&#1077;&#1074;&#1080;&#1077;&#1090;&#1086;&ldquo;
                                                &#1089;&#1098;&#1093;&#1088;&#1072;&#1085;&#1103;&#1074;&#1072; &#1080;&#1083;&#1080;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;
                                                &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1074;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;,
                                                &#1082;&#1072;&#1090;&#1086; &#1080;&#1079;&#1087;&#1088;&#1072;&#1090;&#1080;&#1090;&#1077;
                                                &#1079;&#1072;&#1103;&#1074;&#1083;&#1077;&#1085;&#1080;&#1077; &#1085;&#1072;
                                                &#1072;&#1076;&#1088;&#1077;&#1089;&#1072; &#1085;&#1072;
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072;,
                                                &#1087;&#1086;&#1089;&#1086;&#1095;&#1077;&#1085; &#1087;&#1086;-&#1075;&#1086;&#1088;&#1077;,
                                                &#1080;&#1083;&#1080; &#1087;&#1086; &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1077;&#1085;
                                                &#1087;&#1098;&#1090; &#1085;&#1072; &#1080;&#1084;&#1077;&#1081;&#1083;.</span></p>
                                        <p class="c24"><span class="c1">&#1042;&#1089;&#1103;&#1082;&#1086; &#1083;&#1080;&#1094;&#1077;,
                                                &#1089;&#1083;&#1077;&#1076; &#1086;&#1090;&#1087;&#1088;&#1072;&#1074;&#1077;&#1085;&#1086;
                                                &#1087;&#1080;&#1089;&#1084;&#1077;&#1085;&#1086;
                                                &#1079;&#1072;&#1103;&#1074;&#1083;&#1077;&#1085;&#1080;&#1077;, &#1080;&#1084;&#1072;
                                                &#1087;&#1088;&#1072;&#1074;&#1086; &#1085;&#1072; &#1076;&#1086;&#1089;&#1090;&#1098;&#1087; &#1076;&#1086;
                                                &#1089;&#1098;&#1073;&#1088;&#1072;&#1085;&#1080;&#1090;&#1077; &#1079;&#1072; &#1085;&#1077;&#1075;&#1086;
                                                &#1086;&#1090; &#1051;&#1077;&#1085;&#1086; &#1083;&#1080;&#1095;&#1085;&#1080;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;. &#1042; &#1089;&#1083;&#1091;&#1095;&#1072;&#1080;&#1090;&#1077;,
                                                &#1082;&#1086;&#1075;&#1072;&#1090;&#1086; &#1087;&#1088;&#1080;
                                                &#1086;&#1089;&#1098;&#1097;&#1077;&#1089;&#1090;&#1074;&#1103;&#1074;&#1072;&#1085;&#1077; &#1086;&#1090;
                                                &#1089;&#1090;&#1088;&#1072;&#1085;&#1072; &#1085;&#1072; &#1082;&#1083;&#1080;&#1077;&#1085;&#1090;&#1072;
                                                &#1085;&#1072; &#1090;&#1086;&#1074;&#1072; &#1089;&#1080; &#1087;&#1088;&#1072;&#1074;&#1086;
                                                &#1085;&#1072; &#1076;&#1086;&#1089;&#1090;&#1098;&#1087;, &#1084;&#1086;&#1075;&#1072;&#1090;
                                                &#1076;&#1072; &#1089;&#1077; &#1088;&#1072;&#1079;&#1082;&#1088;&#1080;&#1103;&#1090;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080; &#1080; &#1079;&#1072;
                                                &#1090;&#1088;&#1077;&#1090;&#1086; &#1083;&#1080;&#1094;&#1077;,
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072; &#1077;
                                                &#1076;&#1083;&#1098;&#1078;&#1085;&#1072; &#1076;&#1072;
                                                &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1080; &#1085;&#1072;
                                                &#1089;&#1098;&#1086;&#1090;&#1074;&#1077;&#1090;&#1085;&#1086;&#1090;&#1086; &#1083;&#1080;&#1094;&#1077;
                                                &#1076;&#1086;&#1089;&#1090;&#1098;&#1087; &#1076;&#1086; &#1095;&#1072;&#1089;&#1090;&#1090;&#1072;
                                                &#1086;&#1090; &#1090;&#1103;&#1093;, &#1086;&#1090;&#1085;&#1072;&#1089;&#1103;&#1097;&#1072;
                                                &#1089;&#1077; &#1089;&#1072;&#1084;&#1086; &#1079;&#1072; &#1085;&#1077;&#1075;&#1086;.
                                                &#1055;&#1088;&#1080; &#1091;&#1087;&#1088;&#1072;&#1078;&#1085;&#1103;&#1074;&#1072;&#1085;&#1077;
                                                &#1085;&#1072; &#1087;&#1088;&#1072;&#1074;&#1086;&#1090;&#1086; &#1089;&#1080; &#1085;&#1072;
                                                &#1076;&#1086;&#1089;&#1090;&#1098;&#1087;, &#1074;&#1089;&#1077;&#1082;&#1080;
                                                &#1082;&#1083;&#1080;&#1077;&#1085;&#1090; &#1085;&#1072;
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072; &#1080;&#1084;&#1072;
                                                &#1087;&#1088;&#1072;&#1074;&#1086; &#1087;&#1086; &#1074;&#1089;&#1103;&#1082;&#1086;
                                                &#1074;&#1088;&#1077;&#1084;&#1077; &#1076;&#1072; &#1087;&#1086;&#1080;&#1089;&#1082;&#1072;:</span></p>
                                        <ul class="c18 lst-kix_list_24-0 start">
                                            <li class="c32 c47 li-bullet-0"><span
                                                    class="c1">&#1087;&#1086;&#1090;&#1074;&#1098;&#1088;&#1078;&#1076;&#1077;&#1085;&#1080;&#1077;
                                                    &#1079;&#1072; &#1090;&#1086;&#1074;&#1072;, &#1076;&#1072;&#1083;&#1080;
                                                    &#1086;&#1090;&#1085;&#1072;&#1089;&#1103;&#1097;&#1080; &#1089;&#1077; &#1076;&#1086;
                                                    &#1085;&#1077;&#1075;&#1086; &#1076;&#1072;&#1085;&#1085;&#1080; &#1089;&#1077;
                                                    &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1090;,
                                                    &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103; &#1079;&#1072;
                                                    &#1094;&#1077;&#1083;&#1080;&#1090;&#1077; &#1085;&#1072; &#1090;&#1086;&#1074;&#1072;
                                                    &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1085;&#1077;, &#1079;&#1072;
                                                    &#1082;&#1072;&#1090;&#1077;&#1075;&#1086;&#1088;&#1080;&#1080;&#1090;&#1077;
                                                    &#1076;&#1072;&#1085;&#1085;&#1080; &#1080; &#1079;&#1072;
                                                    &#1087;&#1086;&#1083;&#1091;&#1095;&#1072;&#1090;&#1077;&#1083;&#1080;&#1090;&#1077;
                                                    &#1080;&#1083;&#1080; &#1082;&#1072;&#1090;&#1077;&#1075;&#1086;&#1088;&#1080;&#1080;&#1090;&#1077;
                                                    &#1087;&#1086;&#1083;&#1091;&#1095;&#1072;&#1090;&#1077;&#1083;&#1080;, &#1085;&#1072;
                                                    &#1082;&#1086;&#1080;&#1090;&#1086; &#1076;&#1072;&#1085;&#1085;&#1080;&#1090;&#1077; &#1089;&#1077;
                                                    &#1088;&#1072;&#1079;&#1082;&#1088;&#1080;&#1074;&#1072;&#1090;;</span></li>
                                            <li class="c32 c45 li-bullet-3"><span class="c1">&#1089;&#1098;&#1086;&#1073;&#1097;&#1077;&#1085;&#1080;&#1077;
                                                    &#1076;&#1086; &#1082;&#1083;&#1080;&#1077;&#1085;&#1090;&#1072; &#1074;
                                                    &#1088;&#1072;&#1079;&#1073;&#1080;&#1088;&#1072;&#1077;&#1084;&#1072;
                                                    &#1092;&#1086;&#1088;&#1084;&#1072;, &#1089;&#1098;&#1076;&#1098;&#1088;&#1078;&#1072;&#1097;&#1086;
                                                    &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1084;&#1091; &#1076;&#1072;&#1085;&#1085;&#1080;,
                                                    &#1082;&#1086;&#1080;&#1090;&#1086; &#1089;&#1077;
                                                    &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1090;,
                                                    &#1082;&#1072;&#1082;&#1090;&#1086; &#1080; &#1074;&#1089;&#1103;&#1082;&#1072;
                                                    &#1085;&#1072;&#1083;&#1080;&#1095;&#1085;&#1072;
                                                    &#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103; &#1079;&#1072;
                                                    &#1090;&#1077;&#1093;&#1085;&#1080;&#1103;
                                                    &#1080;&#1079;&#1090;&#1086;&#1095;&#1085;&#1080;&#1082;;</span></li>
                                            <li class="c45 c32 li-bullet-0"><span
                                                    class="c1">&#1080;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1080;&#1103; &#1079;&#1072;
                                                    &#1083;&#1086;&#1075;&#1080;&#1082;&#1072;&#1090;&#1072; &#1085;&#1072;
                                                    &#1074;&#1089;&#1103;&#1082;&#1086;
                                                    &#1072;&#1074;&#1090;&#1086;&#1084;&#1072;&#1090;&#1080;&#1079;&#1080;&#1088;&#1072;&#1085;&#1086;
                                                    &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                    &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;.</span></li>
                                        </ul>
                                        <p class="c10"><span class="c1"></span></p>
                                        <p class="c41"><span class="c1">&#1042;&#1089;&#1077;&#1082;&#1080; &#1082;&#1083;&#1080;&#1077;&#1085;&#1090;
                                                &#1080;&#1084;&#1072; &#1087;&#1088;&#1072;&#1074;&#1086; &#1087;&#1086; &#1074;&#1089;&#1103;&#1082;&#1086;
                                                &#1074;&#1088;&#1077;&#1084;&#1077; &#1076;&#1072; &#1087;&#1086;&#1080;&#1089;&#1082;&#1072; &#1086;&#1090;
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072; &nbsp;&#1076;&#1072;:</span></p>
                                        <ul class="c18 lst-kix_list_25-0 start">
                                            <li class="c5 li-bullet-5"><span class="c1">&#1079;&#1072;&#1083;&#1080;&#1095;&#1080;,
                                                    &#1082;&#1086;&#1088;&#1080;&#1075;&#1080;&#1088;&#1072; &#1080;&#1083;&#1080;
                                                    &#1073;&#1083;&#1086;&#1082;&#1080;&#1088;&#1072; &#1085;&#1077;&#1075;&#1086;&#1074;&#1080;
                                                    &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;,
                                                    &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086;
                                                    &#1085;&#1072; &#1082;&#1086;&#1080;&#1090;&#1086; &#1085;&#1077;
                                                    &#1086;&#1090;&#1075;&#1086;&#1074;&#1072;&#1088;&#1103; &#1085;&#1072;
                                                    &#1080;&#1079;&#1080;&#1089;&#1082;&#1074;&#1072;&#1085;&#1080;&#1103;&#1090;&#1072; &#1085;&#1072;
                                                    &#1085;&#1086;&#1088;&#1084;&#1072;&#1090;&#1080;&#1074;&#1085;&#1072;&#1090;&#1072;
                                                    &#1091;&#1088;&#1077;&#1076;&#1073;&#1072;;</span></li>
                                            <li class="c9 c19 li-bullet-0"><span class="c1">&#1091;&#1074;&#1077;&#1076;&#1086;&#1084;&#1080;
                                                    &#1090;&#1088;&#1077;&#1090;&#1080;&#1090;&#1077; &#1083;&#1080;&#1094;&#1072;, &#1085;&#1072;
                                                    &#1082;&#1086;&#1080;&#1090;&#1086; &#1089;&#1072; &#1073;&#1080;&#1083;&#1080;
                                                    &#1088;&#1072;&#1079;&#1082;&#1088;&#1080;&#1090;&#1080;
                                                    &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1084;&#1091; &#1076;&#1072;&#1085;&#1085;&#1080;,
                                                    &#1079;&#1072; &#1074;&#1089;&#1103;&#1082;&#1086;
                                                    &#1079;&#1072;&#1083;&#1080;&#1095;&#1072;&#1074;&#1072;&#1085;&#1077;,
                                                    &#1082;&#1086;&#1088;&#1080;&#1075;&#1080;&#1088;&#1072;&#1085;&#1077; &#1080;&#1083;&#1080;
                                                    &#1073;&#1083;&#1086;&#1082;&#1080;&#1088;&#1072;&#1085;&#1077;,
                                                    &#1080;&#1079;&#1074;&#1098;&#1088;&#1096;&#1077;&#1085;&#1086; &#1074;
                                                    &#1089;&#1098;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1077; &#1089;
                                                    &#1087;&#1088;&#1077;&#1076;&#1093;&#1086;&#1076;&#1085;&#1072;&#1090;&#1072;
                                                    &#1090;&#1086;&#1095;&#1082;&#1072;, &#1089;
                                                    &#1080;&#1079;&#1082;&#1083;&#1102;&#1095;&#1077;&#1085;&#1080;&#1077; &#1085;&#1072;
                                                    &#1089;&#1083;&#1091;&#1095;&#1072;&#1080;&#1090;&#1077;, &#1082;&#1086;&#1075;&#1072;&#1090;&#1086;
                                                    &#1090;&#1086;&#1074;&#1072; &#1077;
                                                    &#1085;&#1077;&#1074;&#1098;&#1079;&#1084;&#1086;&#1078;&#1085;&#1086; &#1080;&#1083;&#1080; &#1077;
                                                    &#1089;&#1074;&#1098;&#1088;&#1079;&#1072;&#1085;&#1086; &#1089;
                                                    &#1087;&#1088;&#1077;&#1082;&#1086;&#1084;&#1077;&#1088;&#1085;&#1080;
                                                    &#1091;&#1089;&#1080;&#1083;&#1080;&#1103;.</span></li>
                                        </ul>
                                        <p class="c40"><span class="c1"></span></p>
                                        <p class="c41"><span class="c1">&#1042;&#1089;&#1077;&#1082;&#1080; &#1082;&#1083;&#1080;&#1077;&#1085;&#1090;,
                                                &#1079;&#1072; &#1082;&#1086;&#1075;&#1086;&#1090;&#1086; &#1089;&#1077;
                                                &#1086;&#1090;&#1085;&#1072;&#1089;&#1103;&#1090; &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;, &#1080;&#1084;&#1072; &#1087;&#1088;&#1072;&#1074;&#1086;
                                                &#1076;&#1072;:</span></p>
                                        <ul class="c18 lst-kix_list_26-0 start">
                                            <li class="c5 li-bullet-5"><span class="c1">&#1074;&#1098;&#1079;&#1088;&#1072;&#1079;&#1080;
                                                    &#1087;&#1088;&#1077;&#1076; &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072;
                                                    &#1089;&#1088;&#1077;&#1097;&#1091;
                                                    &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086;
                                                    &#1085;&#1072; &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1084;&#1091;
                                                    &#1076;&#1072;&#1085;&#1085;&#1080; &#1087;&#1088;&#1080;
                                                    &#1085;&#1072;&#1083;&#1080;&#1095;&#1080;&#1077; &#1085;&#1072;
                                                    &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1074;&#1086;
                                                    &#1086;&#1089;&#1085;&#1086;&#1074;&#1072;&#1085;&#1080;&#1077; &#1079;&#1072;
                                                    &#1090;&#1086;&#1074;&#1072;; &#1082;&#1086;&#1075;&#1072;&#1090;&#1086;
                                                    &#1074;&#1098;&#1079;&#1088;&#1072;&#1078;&#1077;&#1085;&#1080;&#1077;&#1090;&#1086; &#1077;
                                                    &#1086;&#1089;&#1085;&#1086;&#1074;&#1072;&#1090;&#1077;&#1083;&#1085;&#1086;,
                                                    &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1076;&#1072;&#1085;&#1085;&#1080; &#1085;&#1072;
                                                    &#1089;&#1098;&#1086;&#1090;&#1074;&#1077;&#1090;&#1085;&#1080;&#1103;
                                                    &#1082;&#1083;&#1080;&#1077;&#1085;&#1090; &#1085;&#1077; &#1084;&#1086;&#1075;&#1072;&#1090;
                                                    &#1087;&#1086;&#1074;&#1077;&#1095;&#1077; &#1076;&#1072; &#1073;&#1098;&#1076;&#1072;&#1090;
                                                    &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1085;&#1080;;</span></li>
                                            <li class="c9 c19 li-bullet-0"><span class="c1">&#1074;&#1098;&#1079;&#1088;&#1072;&#1079;&#1080;
                                                    &#1089;&#1088;&#1077;&#1097;&#1091;
                                                    &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086;
                                                    &#1085;&#1072; &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1084;&#1091;
                                                    &#1076;&#1072;&#1085;&#1085;&#1080; &#1079;&#1072; &#1094;&#1077;&#1083;&#1080;&#1090;&#1077;
                                                    &#1085;&#1072; &#1076;&#1080;&#1088;&#1077;&#1082;&#1090;&#1085;&#1080;&#1103;
                                                    &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;;</span></li>
                                            <li class="c9 c19 li-bullet-3"><span class="c1">&#1073;&#1098;&#1076;&#1077;
                                                    &#1091;&#1074;&#1077;&#1076;&#1086;&#1084;&#1077;&#1085;, &#1087;&#1088;&#1077;&#1076;&#1080;
                                                    &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1084;&#1091; &#1076;&#1072;&#1085;&#1085;&#1080;
                                                    &#1076;&#1072; &#1073;&#1098;&#1076;&#1072;&#1090;
                                                    &#1088;&#1072;&#1079;&#1082;&#1088;&#1080;&#1090;&#1080; &#1079;&#1072; &#1087;&#1088;&#1098;&#1074;
                                                    &#1087;&#1098;&#1090; &#1085;&#1072; &#1090;&#1088;&#1077;&#1090;&#1080; &#1083;&#1080;&#1094;&#1072;
                                                    &#1080;&#1083;&#1080; &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1080;
                                                    &#1086;&#1090; &#1090;&#1103;&#1093;&#1085;&#1086; &#1080;&#1084;&#1077; &#1079;&#1072;
                                                    &#1094;&#1077;&#1083;&#1080;&#1090;&#1077; &#1085;&#1072;
                                                    &#1076;&#1080;&#1088;&#1077;&#1082;&#1090;&#1085;&#1080;&#1103;
                                                    &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;, &#1082;&#1072;&#1090;&#1086;
                                                    &#1084;&#1091; &#1073;&#1098;&#1076;&#1077;
                                                    &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1077;&#1085;&#1072;
                                                    &#1074;&#1098;&#1079;&#1084;&#1086;&#1078;&#1085;&#1086;&#1089;&#1090; &#1076;&#1072;
                                                    &#1074;&#1098;&#1079;&#1088;&#1072;&#1079;&#1080; &#1089;&#1088;&#1077;&#1097;&#1091;
                                                    &#1090;&#1072;&#1082;&#1086;&#1074;&#1072;
                                                    &#1088;&#1072;&#1079;&#1082;&#1088;&#1080;&#1074;&#1072;&#1085;&#1077; &#1080;&#1083;&#1080;
                                                    &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077;.</span></li>
                                        </ul>
                                        <p class="c23"><span class="c3">2. &nbsp;&#1046;&#1072;&#1083;&#1073;&#1072; &#1076;&#1086;
                                                &#1085;&#1072;&#1076;&#1079;&#1086;&#1088;&#1085;&#1080;&#1103; &#1086;&#1088;&#1075;&#1072;&#1085;</span>
                                        </p>
                                        <p class="c23"><span class="c1">&#1048;&#1084;&#1072;&#1090;&#1077; &#1087;&#1088;&#1072;&#1074;&#1086;
                                                &#1076;&#1072; &#1087;&#1086;&#1076;&#1072;&#1076;&#1077;&#1090;&#1077; &#1078;&#1072;&#1083;&#1073;&#1072;
                                                &#1085;&#1072;&#1087;&#1088;&#1072;&#1074;&#1086; &#1076;&#1086;
                                                &#1085;&#1072;&#1076;&#1079;&#1086;&#1088;&#1085;&#1080;&#1103; &#1086;&#1088;&#1075;&#1072;&#1085;,
                                                &#1082;&#1072;&#1090;&#1086;
                                                &#1082;&#1086;&#1084;&#1087;&#1077;&#1090;&#1077;&#1085;&#1090;&#1085;&#1080;&#1103;&#1090; &#1079;&#1072;
                                                &#1090;&#1086;&#1074;&#1072; &#1086;&#1088;&#1075;&#1072;&#1085; &#1077;:</span></p>
                                        <p class="c23"><span class="c38">&#1050;&#1086;&#1084;&#1080;&#1089;&#1080;&#1103; &#1079;&#1072;
                                                &#1079;&#1072;&#1097;&#1080;&#1090;&#1072; &#1085;&#1072; &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;, &#1072;&#1076;&#1088;&#1077;&#1089;:
                                                &#1041;&#1098;&#1083;&#1075;&#1072;&#1088;&#1080;&#1103;, &#1075;&#1088;.
                                                &#1057;&#1086;&#1092;&#1080;&#1103; 1592, &#1073;&#1091;&#1083;. &bdquo;&#1055;&#1088;&#1086;&#1092;.
                                                &#1062;&#1074;&#1077;&#1090;&#1072;&#1085; &#1051;&#1072;&#1079;&#1072;&#1088;&#1086;&#1074;&rdquo; &#8470;
                                                2 (</span><span class="c38 c50">www.cpdp.bg</span><span class="c1">).</span></p>
                                        <p class="c23"><span class="c1">&#1042; &#1089;&#1083;&#1091;&#1095;&#1072;&#1081;, &#1095;&#1077;
                                                &#1080;&#1084;&#1072;&#1090;&#1077; &#1074;&#1098;&#1087;&#1088;&#1086;&#1089;&#1080;
                                                &#1080;/&#1080;&#1083;&#1080; &#1086;&#1087;&#1083;&#1072;&#1082;&#1074;&#1072;&#1085;&#1080;&#1103;
                                                &#1086;&#1090;&#1085;&#1086;&#1089;&#1085;&#1086;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1082;&#1072;&#1090;&#1072; &#1085;&#1072;
                                                &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1074;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;
                                                &#1080;/&#1080;&#1083;&#1080;
                                                &#1091;&#1087;&#1088;&#1072;&#1078;&#1085;&#1103;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086; &#1085;&#1072;
                                                &#1075;&#1086;&#1088;&#1077;&#1087;&#1086;&#1089;&#1086;&#1095;&#1077;&#1085;&#1080;&#1090;&#1077;
                                                &#1087;&#1088;&#1072;&#1074;&#1072;, &#1084;&#1086;&#1078;&#1077;&#1090;&#1077; &#1076;&#1072;
                                                &#1089;&#1077; &#1086;&#1073;&#1098;&#1088;&#1085;&#1077;&#1090;&#1077; &#1082;&#1098;&#1084;
                                                &#1044;&#1083;&#1098;&#1078;&#1085;&#1086;&#1089;&#1090;&#1085;&#1086; &#1083;&#1080;&#1094;&#1077;
                                                &#1087;&#1086; &#1079;&#1072;&#1097;&#1080;&#1090;&#1072; &#1085;&#1072;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;&#1090;&#1077; (&#1085;&#1072;
                                                &#1087;&#1086;&#1089;&#1086;&#1095;&#1077;&#1085;&#1080;&#1090;&#1077;
                                                &#1087;&#1086;-&#1075;&#1086;&#1088;&#1077; &#1076;&#1072;&#1085;&#1085;&#1080; &#1079;&#1072;
                                                &#1082;&#1086;&#1085;&#1090;&#1072;&#1082;&#1090;).</span></p>
                                        <p class="c23"><span class="c3">3. &nbsp;&#1042;&#1098;&#1079;&#1088;&#1072;&#1078;&#1077;&#1085;&#1080;&#1077;
                                                &#1089;&#1088;&#1077;&#1097;&#1091; &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077;
                                                &#1079;&#1072; &#1076;&#1080;&#1088;&#1077;&#1082;&#1090;&#1077;&#1085;
                                                &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075;</span></p>
                                        <p class="c23"><span class="c1">&#1048;&#1084;&#1072;&#1090;&#1077; &#1087;&#1088;&#1072;&#1074;&#1086;
                                                &#1076;&#1072; &#1074;&#1098;&#1079;&#1088;&#1072;&#1079;&#1080;&#1090;&#1077;
                                                &#1089;&#1088;&#1077;&#1097;&#1091;
                                                &#1086;&#1073;&#1088;&#1072;&#1073;&#1086;&#1090;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086; &#1079;&#1072;
                                                &#1074; &#1073;&#1098;&#1076;&#1077;&#1097;&#1077; &#1085;&#1072;
                                                &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1074;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;
                                                &#1079;&#1072; &#1094;&#1077;&#1083;&#1080;&#1090;&#1077; &#1085;&#1072;
                                                &#1076;&#1080;&#1088;&#1077;&#1082;&#1090;&#1085;&#1080;&#1103;
                                                &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075; &#1080;
                                                &#1088;&#1077;&#1082;&#1083;&#1072;&#1084;&#1072;, &#1082;&#1072;&#1082;&#1090;&#1086; &#1080;
                                                &#1089;&#1088;&#1077;&#1097;&#1091;
                                                &#1088;&#1072;&#1079;&#1082;&#1088;&#1080;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086; &#1080;&#1084;
                                                &#1085;&#1072; &#1090;&#1088;&#1077;&#1090;&#1080; &#1083;&#1080;&#1094;&#1072; &#1080;
                                                &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077;&#1090;&#1086; &#1080;&#1084;
                                                &#1086;&#1090; &#1090;&#1103;&#1093;&#1085;&#1086; &#1080;&#1084;&#1077; &#1079;&#1072;
                                                &#1094;&#1077;&#1083;&#1080;&#1090;&#1077; &#1085;&#1072;
                                                &#1076;&#1080;&#1088;&#1077;&#1082;&#1090;&#1085;&#1080;&#1103;
                                                &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075; &#1080;
                                                &#1088;&#1077;&#1082;&#1083;&#1072;&#1084;&#1072;, &#1082;&#1072;&#1090;&#1086;
                                                &#1086;&#1090;&#1090;&#1077;&#1075;&#1083;&#1080;&#1090;&#1077;
                                                &#1076;&#1072;&#1076;&#1077;&#1085;&#1086;&#1090;&#1086; &#1086;&#1090; &#1074;&#1072;&#1089;
                                                &#1089;&#1098;&#1075;&#1083;&#1072;&#1089;&#1080;&#1077; &#1087;&#1086; &#1074;&#1089;&#1103;&#1082;&#1086;
                                                &#1074;&#1088;&#1077;&#1084;&#1077;. &#1047;&#1072; &#1094;&#1077;&#1083;&#1090;&#1072;
                                                &#1084;&#1086;&#1078;&#1077; &#1076;&#1072; &#1080;&#1079;&#1087;&#1088;&#1072;&#1090;&#1080;&#1090;&#1077;
                                                &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;
                                                &#1089;&#1098;&#1086;&#1073;&#1097;&#1077;&#1085;&#1080;&#1077; &#1089;&#1098;&#1089;
                                                &#1089;&#1098;&#1086;&#1090;&#1074;&#1077;&#1090;&#1085;&#1086;&#1090;&#1086;
                                                &#1080;&#1089;&#1082;&#1072;&#1085;&#1077; &#1079;&#1072;
                                                &#1087;&#1088;&#1077;&#1091;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1103;&#1074;&#1072;&#1085;&#1077;
                                                &#1085;&#1072; &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;&#1077; &#1085;&#1072;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;&#1090;&#1077; &#1074;&#1080; &#1079;&#1072;
                                                &#1094;&#1077;&#1083;&#1080;&#1090;&#1077; &#1085;&#1072;
                                                &#1076;&#1080;&#1088;&#1077;&#1082;&#1090;&#1085;&#1080;&#1103;
                                                &#1084;&#1072;&#1088;&#1082;&#1077;&#1090;&#1080;&#1085;&#1075; &#1085;&#1072;
                                                &#1072;&#1076;&#1088;&#1077;&#1089;: info@mnd.bg</span></p>
                                        <p class="c23"><span class="c3">5. &nbsp;&#1052;&#1086;&#1078;&#1077; &#1083;&#1080; &#1076;&#1072;
                                                &#1086;&#1090;&#1082;&#1072;&#1078;&#1077;&#1090;&#1077;
                                                &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1103;&#1085;&#1077;&#1090;&#1086;
                                                &#1085;&#1072; &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080; &#1085;&#1072;
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072; &#1080;
                                                &#1082;&#1072;&#1082;&#1074;&#1080; &#1089;&#1072;
                                                &#1087;&#1086;&#1089;&#1083;&#1077;&#1076;&#1080;&#1094;&#1080;&#1090;&#1077; &#1086;&#1090;
                                                &#1090;&#1086;&#1074;&#1072;</span></p>
                                        <p class="c0"><span class="c1"></span></p>
                                        <p class="c23"><span class="c1">&#1047;&#1072; &#1076;&#1072; &#1089;&#1082;&#1083;&#1102;&#1095;&#1080;&#1084;
                                                &#1074;&#1072;&#1083;&#1080;&#1076;&#1077;&#1085; &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088; &#1089;
                                                &#1074;&#1072;&#1089; &#1080;/&#1080;&#1083;&#1080; &#1079;&#1072; &#1076;&#1072; &#1074;&#1080;
                                                &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1080;&#1084;
                                                &#1079;&#1072;&#1103;&#1074;&#1077;&#1085;&#1080;&#1090;&#1077; &#1091;&#1089;&#1083;&#1091;&#1075;&#1080;
                                                &#1074; &#1089;&#1098;&#1086;&#1090;&#1074;&#1077;&#1090;&#1089;&#1090;&#1074;&#1080;&#1077;
                                                &#1089;&#1098;&#1089; &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1074;&#1080;&#1090;&#1077; &#1080; &#1074;
                                                &#1087;&#1086;&#1089;&#1083;&#1077;&#1076;&#1089;&#1090;&#1074;&#1080;&#1077;
                                                &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1085;&#1080;&#1090;&#1077; &#1085;&#1080;
                                                &#1079;&#1072;&#1076;&#1098;&#1083;&#1078;&#1077;&#1085;&#1080;&#1103;,
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;&#1090;&#1072; &#1089;&#1077;
                                                &#1085;&#1091;&#1078;&#1076;&#1072;&#1077; &#1086;&#1090;
                                                &#1086;&#1087;&#1088;&#1077;&#1076;&#1077;&#1083;&#1077;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;,
                                                &#1087;&#1086;&#1076;&#1088;&#1086;&#1073;&#1085;&#1086; &#1086;&#1087;&#1080;&#1089;&#1072;&#1085;&#1080;
                                                &#1074; &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1072;&#1090;&#1072;
                                                &#1055;&#1086;&#1083;&#1080;&#1090;&#1080;&#1082;&#1072; &#1079;&#1072;
                                                &#1087;&#1086;&#1074;&#1077;&#1088;&#1080;&#1090;&#1077;&#1083;&#1085;&#1086;&#1089;&#1090;.</span></p>
                                        <p class="c23"><span
                                                class="c1">&#1053;&#1077;&#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1103;&#1085;&#1077;&#1090;&#1086;
                                                &#1085;&#1072; &#1090;&#1072;&#1082;&#1080;&#1074;&#1072; &#1076;&#1072;&#1085;&#1085;&#1080;
                                                &#1084;&#1086;&#1078;&#1077; &#1076;&#1072;
                                                &#1074;&#1098;&#1079;&#1087;&#1088;&#1077;&#1087;&#1103;&#1090;&#1089;&#1090;&#1074;&#1072;
                                                &#1074;&#1098;&#1079;&#1084;&#1086;&#1078;&#1085;&#1086;&#1089;&#1090;&#1090;&#1072; &#1076;&#1072;
                                                &#1074;&#1080; &#1087;&#1088;&#1077;&#1076;&#1086;&#1089;&#1090;&#1072;&#1074;&#1080;&#1084;
                                                &#1074;&#1080;&#1076;&#1072; &#1091;&#1089;&#1083;&#1091;&#1075;&#1072;, &#1082;&#1086;&#1103;&#1090;&#1086;
                                                &#1089;&#1090;&#1077; &#1079;&#1072;&#1103;&#1074;&#1080;&#1083;&#1080; &#1080;/&#1080;&#1083;&#1080;
                                                &#1076;&#1072; &#1089;&#1082;&#1083;&#1102;&#1095;&#1080;&#1084;
                                                &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088; &#1087;&#1088;&#1080;
                                                &#1078;&#1077;&#1083;&#1072;&#1085;&#1080;&#1090;&#1077; &#1086;&#1090; &#1074;&#1072;&#1089;
                                                &#1091;&#1089;&#1083;&#1086;&#1074;&#1080;&#1103;.</span></p>
                                        <p class="c23"><span class="c3">6.
                                                &nbsp;&#1055;&#1088;&#1077;&#1085;&#1086;&#1089;&#1080;&#1084;&#1086;&#1089;&#1090; &#1085;&#1072;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;</span></p>
                                        <p class="c23"><span class="c1">&#1048;&#1084;&#1072;&#1090;&#1077; &#1087;&#1088;&#1072;&#1074;&#1086;
                                                &#1076;&#1072; &#1080;&#1079;&#1080;&#1089;&#1082;&#1072;&#1090;&#1077;
                                                &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1074;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080;
                                                &#1076;&#1072; &#1073;&#1098;&#1076;&#1072;&#1090;
                                                &#1087;&#1088;&#1077;&#1076;&#1072;&#1076;&#1077;&#1085;&#1080; &#1080;&#1083;&#1080;
                                                &#1087;&#1088;&#1077;&#1093;&#1074;&#1098;&#1088;&#1083;&#1077;&#1085;&#1080; &#1085;&#1072;
                                                &#1076;&#1088;&#1091;&#1075;
                                                &#1072;&#1076;&#1084;&#1080;&#1085;&#1080;&#1089;&#1090;&#1088;&#1072;&#1090;&#1086;&#1088; &#1074;
                                                &#1089;&#1090;&#1088;&#1091;&#1082;&#1090;&#1091;&#1088;&#1080;&#1088;&#1072;&#1085;,
                                                &#1096;&#1080;&#1088;&#1086;&#1082;&#1086; &#1080;&#1079;&#1087;&#1086;&#1083;&#1079;&#1074;&#1072;&#1085;
                                                &#1080; &#1087;&#1088;&#1080;&#1075;&#1086;&#1076;&#1077;&#1085; &#1079;&#1072;
                                                &#1084;&#1072;&#1096;&#1080;&#1085;&#1085;&#1086; &#1095;&#1077;&#1090;&#1077;&#1085;&#1077;
                                                &#1092;&#1086;&#1088;&#1084;&#1072;&#1090;. &#1051;&#1077;&#1085;&#1086; &#1097;&#1077;
                                                &#1080;&#1079;&#1074;&#1098;&#1088;&#1096;&#1080; &#1087;&#1088;&#1103;&#1082;&#1086;
                                                &#1087;&#1088;&#1077;&#1093;&#1074;&#1098;&#1088;&#1083;&#1103;&#1085;&#1077; &#1085;&#1072;
                                                &#1076;&#1072;&#1085;&#1085;&#1080;&#1090;&#1077;, &#1089;&#1072;&#1084;&#1086; &#1072;&#1082;&#1086;
                                                &#1090;&#1086;&#1074;&#1072; &#1077; &#1090;&#1077;&#1093;&#1085;&#1080;&#1095;&#1077;&#1089;&#1082;&#1080;
                                                &#1086;&#1089;&#1098;&#1097;&#1077;&#1089;&#1090;&#1074;&#1080;&#1084;&#1086;.</span></p>
                                        <ol class="c18 lst-kix_list_10-0" start="12">
                                            <li class="c28 c32 li-bullet-0"><span class="c27">&#1050;&#1086;&#1080; &#1089;&#1084;&#1077;
                                                    &#1085;&#1080;&#1077;?&nbsp;</span></li>
                                        </ol>
                                        <p class="c12"><span class="c1">&#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                                &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                                &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo; &#1077;
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;,
                                                &#1089;&#1098;&#1079;&#1076;&#1072;&#1076;&#1077;&#1085;&#1072; &#1074;
                                                &#1086;&#1073;&#1097;&#1077;&#1089;&#1090;&#1074;&#1077;&#1085;&#1072; &#1087;&#1086;&#1083;&#1079;&#1072;,
                                                &#1088;&#1077;&#1075;&#1080;&#1089;&#1090;&#1088;&#1080;&#1088;&#1072;&#1085;&#1072; &#1074;
                                                &#1058;&#1098;&#1088;&#1075;&#1086;&#1074;&#1089;&#1082;&#1080;&#1103;
                                                &#1088;&#1077;&#1075;&#1080;&#1089;&#1090;&#1098;&#1088; &#1082;&#1098;&#1084;
                                                &#1040;&#1075;&#1077;&#1085;&#1094;&#1080;&#1103; &#1087;&#1086;
                                                &#1074;&#1087;&#1080;&#1089;&#1074;&#1072;&#1085;&#1080;&#1103;&#1090;&#1072; &#1087;&#1086;&#1076;
                                                &#1045;&#1048;&#1050; 206771528, &#1089;&#1098;&#1089;
                                                &#1089;&#1077;&#1076;&#1072;&#1083;&#1080;&#1097;&#1077; &#1080; &#1072;&#1076;&#1088;&#1077;&#1089;
                                                &#1085;&#1072; &#1091;&#1087;&#1088;&#1072;&#1074;&#1083;&#1077;&#1085;&#1080;&#1077;:
                                                &#1056;&#1077;&#1087;&#1091;&#1073;&#1083;&#1080;&#1082;&#1072;
                                                &#1041;&#1098;&#1083;&#1075;&#1072;&#1088;&#1080;&#1103;, &#1043;&#1088;.
                                                &#1057;&#1086;&#1092;&#1080;&#1103;, &#1088;-&#1085; &#1050;&#1088;&#1072;&#1089;&#1085;&#1086;
                                                &#1089;&#1077;&#1083;&#1086;, &#1091;&#1083;. &#1051;&#1077;&#1088;&#1080;&#1085;&#1089;&#1082;&#1072;
                                                &#1095;&#1091;&#1082;&#1072; &#8470; 2, &#1077;&#1090;. 5, &#1072;&#1087;. 20.
                                                &#1057;&#1098;&#1075;&#1083;&#1072;&#1089;&#1085;&#1086;
                                                &#1087;&#1088;&#1080;&#1083;&#1086;&#1078;&#1080;&#1084;&#1086;&#1090;&#1086;
                                                &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1090;&#1077;&#1083;&#1089;&#1090;&#1074;&#1086;
                                                &#1079;&#1072; &#1079;&#1072;&#1097;&#1080;&#1090;&#1072; &#1085;&#1072;
                                                &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1076;&#1072;&#1085;&#1085;&#1080;,
                                                &#1060;&#1086;&#1085;&#1076;&#1072;&#1094;&#1080;&#1103;
                                                &bdquo;&#1052;&#1086;&#1089;&#1090;&#1086;&#1074;&#1077; &#1085;&#1072;
                                                &#1076;&#1086;&#1074;&#1077;&#1088;&#1080;&#1077;&#1090;&#1086;&ldquo;
                                                &#1076;&#1077;&#1081;&#1089;&#1090;&#1074;&#1072; &#1074;
                                                &#1082;&#1072;&#1095;&#1077;&#1089;&#1090;&#1074;&#1086;&#1090;&#1086; &#1089;&#1080; &#1085;&#1072;
                                                &#1072;&#1076;&#1084;&#1080;&#1085;&#1080;&#1089;&#1090;&#1088;&#1072;&#1090;&#1086;&#1088; &#1085;&#1072;
                                                &#1083;&#1080;&#1095;&#1085;&#1080; &#1076;&#1072;&#1085;&#1085;&#1080; &#1087;&#1086;
                                                &#1086;&#1090;&#1085;&#1086;&#1096;&#1077;&#1085;&#1080;&#1077; &#1085;&#1072;
                                                &#1051;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1076;&#1072;&#1085;&#1085;&#1080; &#1085;&#1072;
                                                &#1087;&#1086;&#1090;&#1088;&#1077;&#1073;&#1080;&#1090;&#1077;&#1083;&#1080;&#1090;&#1077; &#1085;&#1072;
                                                &#1091;&#1077;&#1073;&#1089;&#1090;&#1088;&#1072;&#1085;&#1080;&#1094;&#1072;&#1090;&#1072; &#1080;
                                                &#1087;&#1088;&#1080;&#1083;&#1086;&#1078;&#1077;&#1085;&#1080;&#1103;&#1090;&#1072;
                                                &#1089;&#1080;.&nbsp;</span></p>
                                        <p class="c28"><span class="c27">&#1050;&#1072;&#1082; &#1076;&#1072; &#1089;&#1077;
                                                &#1089;&#1074;&#1098;&#1088;&#1078;&#1077;&#1090;&#1077; &#1089; &#1085;&#1072;&#1089;?</span></p>
                                        <p class="c12"><span class="c38">&#1042; &#1089;&#1083;&#1091;&#1095;&#1072;&#1081; &#1095;&#1077;
                                                &#1085;&#1072;&#1089;&#1090;&#1086;&#1103;&#1097;&#1072;&#1090;&#1072;
                                                &#1087;&#1086;&#1083;&#1080;&#1090;&#1080;&#1082;&#1072; &#1085;&#1077;
                                                &#1086;&#1090;&#1075;&#1086;&#1074;&#1086;&#1088;&#1080; &#1085;&#1072;
                                                &#1042;&#1072;&#1096;&#1080;&#1090;&#1077; &#1074;&#1098;&#1087;&#1088;&#1086;&#1089;&#1080;
                                                &#1080;&#1083;&#1080; &#1080;&#1084;&#1072;&#1090;&#1077; &#1076;&#1088;&#1091;&#1075;&#1086;
                                                &#1079;&#1072;&#1087;&#1080;&#1090;&#1074;&#1072;&#1085;&#1077;, &#1084;&#1086;&#1083;&#1080;&#1084;
                                                &#1076;&#1072; &#1089;&#1077; &#1089;&#1074;&#1098;&#1088;&#1078;&#1077;&#1090;&#1077; &#1089;
                                                &#1085;&#1072;&#1089; &#1076;&#1080;&#1088;&#1077;&#1082;&#1090;&#1085;&#1086; &#1085;&#1072;
                                                &#1089;&#1083;&#1077;&#1076;&#1085;&#1080;&#1103; &#1072;&#1076;&#1088;&#1077;&#1089;: &#1075;&#1088;.
                                                &#1057;&#1086;&#1092;&#1080;&#1103;, &#1088;-&#1085; &#1050;&#1088;&#1072;&#1089;&#1085;&#1086;
                                                &#1089;&#1077;&#1083;&#1086;, &#1091;&#1083;. &#1051;&#1077;&#1088;&#1080;&#1085;&#1089;&#1082;&#1072;
                                                &#1095;&#1091;&#1082;&#1072; &#8470; 2, &#1077;&#1090;. 5, &#1072;&#1087;. 20, &#1080;&#1083;&#1080;
                                                &#1085;&#1072; email:w</span><span class="c34">info@mnd.bg</span><span class="c38">, &#1080;&#1083;&#1080;
                                                &#1089; &#1076;&#1083;&#1098;&#1078;&#1085;&#1086;&#1089;&#1090;&#1085;&#1086;&#1090;&#1086; &#1085;&#1080;
                                                &#1083;&#1080;&#1094;&#1077; &#1087;&#1086; &#1079;&#1072;&#1097;&#1080;&#1090;&#1072; &#1085;&#1072;
                                                &#1083;&#1080;&#1095;&#1085;&#1080;&#1090;&#1077; &#1076;&#1072;&#1085;&#1085;&#1080;:&nbsp; </span><span
                                                class="c3">&#1048;&#1074;&#1072;&#1081;&#1083;&#1086;
                                                &#1062;&#1074;&#1077;&#1090;&#1086;&#1084;&#1080;&#1088;&#1086;&#1074; &#1052;&#1080;&#1090;&#1077;&#1074;
                                                &ndash; email: ivaylo.mitev@mnd.bg</span></p>
                                        <p class="c31"><span class="c1">&nbsp;</span></p>
                                        <p class="c21"><span class="c13"></span></p>

                                    </div>
                                ) : (
                                    <div className='en content'>
                                    <p class="c22"><span class="c5">In accordance with the requirements of Regulation (EU) 2016/679 of the EU of
                                            27.04.2016 on the protection of natural persons in connection with the processing of personal data and on
                                            the free movement of such data and on the repeal of Directive 95/46/EC ( below only &quot;Regulation
                                            2016/679&quot; or &quot;GDPR&quot;) and the other applicable acts of the European Union and the Republic of
                                            Bulgaria, with this notice the &quot;Bridges of Trust&quot; Foundation informs you about its personal data
                                            processing activities, the purposes , for which the data is processed, the measures and guarantees for the
                                            protection of the processed data, your rights and the way you can exercise them.</span></p>
                                    <p class="c30 c35"><span class="c11"></span></p>
                                    <ol class="c3 lst-kix_8imn3szo0r4-0 start" start="1">
                                        <li class="c27 li-bullet-0" id="h.gjdgxs"><span class="c26">Introduction</span></li>
                                    </ol>
                                    <p class="c2"><span class="c5">With this policy, we aim to inform you about the processing of personal data that we
                                            collect in connection with our activities. Personal data covers all information related to an identified or
                                            identifiable natural person. This includes information such as name, address, email or phone number.
                                            Information that is not directly related to your identity, e.g. the number of users of the website does not
                                            fall within this scope.</span></p>
                                    <p class="c2"><span class="c5">Most of our services do not require any form of registration. We respect and honor
                                            the privacy of all users of our website. This privacy policy describes the ways and conditions under which
                                            your personal data collected when visiting or using the web page is processed and used. We recommend that
                                            you read this Privacy Policy to obtain more information about the processing and use of your personal
                                            data.</span></p>
                                    <ol class="c3 lst-kix_8imn3szo0r4-0" start="2">
                                        <li class="c27 li-bullet-0"><span class="c12">Basic concepts to better understand this policy</span></li>
                                    </ol>
                                    <p class="c2"><span class="c5">&quot;Personal Data&quot; within the meaning of this policy is data provided by users
                                            and clients of the Bridges of Trust Foundation in relation to (including but not limited to):</span></p>
                                    <ol class="c3 lst-kix_q59ohnnmbyw3-0 start" start="1">
                                        <li class="c0 c32 li-bullet-0"><span class="c5">use of the web pages and/or online applications owned and
                                                administered by the Foundation &quot;Bridges of Trust&quot; (including the official pages of the
                                                Foundation in social networks - Facebook; Instagram, Linkedin, etc.)</span></li>
                                        <li class="c0 c32 li-bullet-0"><span class="c5">receiving and sending personal information about initiatives or
                                                other events organized, supported or financed by the &quot;Bridges of Trust&quot; Foundation;</span>
                                        </li>
                                        <li class="c0 c32 li-bullet-0"><span class="c5">applications for the announced job vacancies on the
                                                Foundation&#39;s website.</span></li>
                                        <li class="c0 c32 li-bullet-0"><span class="c5">in connection with the exercise of rights of data
                                                subjects.</span></li>
                                    </ol>
                                    <p class="c2"><span class="c5">&quot;Processing of personal data&quot; of a user is any operation or set of
                                            operations performed on personal data or a set of personal data by automatic or other means such as
                                            collection, recording, organization, structuring, storage, adaptation or modification, retrieval,
                                            consultation, use , disclosure by transmission, distribution or other way in which data is made available,
                                            arrangement or combination, limitation, erasure or destruction</span></p>
                                    <p class="c2"><span class="c5">&quot;Controller&quot; means a natural or legal person, public body, agency or other
                                            structure which alone or jointly with others determines the purposes and means of processing personal data,
                                            where the purposes and means of such processing are determined by Union law or the law of a State Member
                                            State, the controller or the special criteria for its designation may be laid down in Union law or in the
                                            law of a Member State.</span></p>
                                    <p class="c2"><span class="c5">&quot;Consent of the data subject&quot; means any freely expressed, specific,
                                            informed and unequivocal indication of the will of the data subject, by means of a statement or a clear
                                            affirmative action, which expresses his consent to the personal data relating to him being processed;</span>
                                    </p>
                                    <p class="c2"><span class="c5">&quot;Processor of personal data&quot; means a natural or legal person, public body,
                                            agency or other entity that processes personal data on behalf of the Bridges of Trust Foundation.</span></p>
                                    <p class="c2"><span class="c5">&quot;Third party&quot; means a natural or legal person, public body, agency or other
                                            body other than the data subject, the controller, the personal data processor and the persons who, under the
                                            direct supervision of the controller or the personal data processor, have the right to process the personal
                                            data.</span></p>
                                    <p class="c2"><span class="c5">&quot;Data subject&quot; means a natural person who can be identified directly or
                                            indirectly, in particular by an identifier such as name, identification number, location data, online
                                            identifier or by one or more characteristics specific to the physical, physiological, genetic, the psychic,
                                            mental, economic, cultural or social identity of that natural person.</span></p>
                                    <p class="c2"><span class="c5">&quot;Applicable legislation&quot; means the legislation of the European Union and
                                            the Republic of Bulgaria, which is relevant to the protection of personal data (Law on the Protection of
                                            Personal Data, etc.);</span></p>
                                    <p class="c2"><span class="c5">&quot;Regulation (EU) 2016/679&quot; means Regulation (EU) 2016/679 of the European
                                            Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the
                                            processing of personal data and on the free movement of such data and on repealing Directive 95/46/EC
                                            (General Data Protection Regulation), published in the Official Journal of the European Union on May 4,
                                            2016, hereinafter referred to for short as &quot;the Regulation&quot;.</span></p>
                                    <ol class="c3 lst-kix_8imn3szo0r4-0" start="3">
                                        <li class="c2 c39 li-bullet-0"><span class="c21">Purposes of personal data processing</span></li>
                                    </ol>
                                    <p class="c2"><span class="c5">Bridges of Trust Foundation collects and uses your personal data through the website
                                            for the following purposes:</span></p>
                                    <ul class="c3 lst-kix_pt5vqywkwb4v-0 start">
                                        <li class="c38 c47"><span class="c11">Provision of services requested by you:</span></li>
                                    </ul>
                                    <p class="c0"><span class="c5 c25">Almost all personal data that you fill in the registration forms or your profile
                                            is necessary for us to respond to your need or requested service that the Bridges of Trust Foundation
                                            provides. In order to provide quality services online and offline, we need your identification data, the
                                            scope of which varies depending on the type of service requested and the legal requirements applicable to
                                            it.</span></p>
                                    <p class="c0 c30"><span class="c5 c25"></span></p>
                                    <ul class="c3 lst-kix_pt5vqywkwb4v-0">
                                        <li class="c0 c38"><span class="c11">Marketing activity:</span></li>
                                    </ul>
                                    <p class="c0"><span class="c5">The personal data you provide may be used to inform you about new services provided
                                            by the Bridges of Trust Foundation. The messages sent by us will be in accordance with the applicable
                                            Bulgarian and European legislation.</span></p>
                                    <p class="c0 c30"><span class="c5"></span></p>
                                    <p class="c0 c30"><span class="c5"></span></p>
                                    <p class="c0"><span class="c5">You can object in an easy and convenient way to the use of your data for marketing,
                                            where the basis for the processing is a legitimate interest, and we will stop it immediately; information on
                                            how to withdraw consent once given or object to receiving an unsolicited marketing message will always be
                                            placed prominently in the message.</span></p>
                                    <p class="c0 c30"><span class="c5"></span></p>
                                    <p class="c0"><span class="c5">For the purposes of marketing activity, we perform traffic analysis and track user
                                            behavior on the website through codes from third-party domains, where we process your personal data using
                                            cookies. You can read more about them in our cookie policy.</span></p>
                                    <p class="c0 c30"><span class="c24"></span></p>
                                    <ul class="c3 lst-kix_pt5vqywkwb4v-0">
                                        <li class="c0 c38"><span class="c11">Selection of employees who applied for job advertisements:</span></li>
                                    </ul>
                                    <p class="c0"><span class="c5 c25">Through our website, we offer a convenient opportunity to familiarize yourself
                                            with announced job vacancies in partner companies that have signed contracts with the &quot;Bridges of
                                            Trust&quot; Foundation and to apply for a position of your choice. Thus, through the website, you can submit
                                            your documents, and for the purposes of the selection, we will identify you and process the information you
                                            sent and your personal data.</span></p>
                                    <p class="c0 c30"><span class="c24"></span></p>
                                    <ol class="c3 lst-kix_8imn3szo0r4-0" start="4">
                                        <li class="c34 li-bullet-0"><span class="c41 c31">Principles of personal data processing</span></li>
                                    </ol>
                                    <p class="c0"><span class="c5">Personal data is processed lawfully, in good faith and transparently in compliance
                                            with the following principles:</span></p>
                                    <p class="c0"><span class="c5">(1) The data subject is informed in advance about the processing of his personal
                                            data;</span></p>
                                    <p class="c0"><span class="c5">(2) Personal data are collected for specific, precisely defined and legal purposes
                                            and are not further processed in a manner incompatible with these purposes;</span></p>
                                    <p class="c0"><span class="c5">(3) Personal data correspond to the purposes for which they are collected;</span></p>
                                    <p class="c0"><span class="c5">(4) Personal data must be accurate and, if necessary, updated;</span></p>
                                    <p class="c0"><span class="c5">(5) Personal data are deleted or corrected when it is established that they are
                                            inaccurate or do not correspond to the purposes for which they are processed;</span></p>
                                    <p class="c0"><span class="c5">(6) Personal data is maintained in a form that allows identification of the relevant
                                            natural persons for a period not longer than necessary for the purposes for which these data are
                                            processed.</span></p>
                                    <p class="c13"><span class="c5"></span></p>
                                    <p class="c0 c30 c45"><span class="c5"></span></p>
                                    <ol class="c3 lst-kix_8imn3szo0r4-0" start="5">
                                        <li class="c34 li-bullet-0"><span class="c21">Grounds for processing personal data</span></li>
                                    </ol>
                                    <p class="c19"><span class="c5">When the Bridges of Trust Foundation processes your personal data for the purposes
                                            of providing services that you have requested through our website, we most often process your personal data
                                            on a contractual basis pursuant to Art. 6, paragraph 1, letter &quot;b&quot; of Regulation (EU) 2016/679
                                            (GDPR).</span></p>
                                    <p class="c19"><span class="c5">In the event that you apply for an advertised position through the website of the
                                            &quot;Bridges of Trust&quot; Foundation, we collect the data for selection purposes on the basis of your
                                            consent to this in accordance with Art. 6, paragraph 1, letter &quot;a&quot; of Regulation (EU) 2016/679
                                            (GDPR).</span></p>
                                    <p class="c19"><span class="c5">For the purposes of marketing activities, we process your personal data because we
                                            have a legitimate interest within the meaning of Art. 6, paragraph 1, letter &quot;f&quot; of Regulation
                                            (EU) 2016/679 (GDPR) or because you have given us your consent for this on the basis of art. 6, paragraph 1,
                                            letter &quot;a&quot; of Regulation (EU) 2016/679 (GDPR).</span></p>
                                    <ol class="c3 lst-kix_8imn3szo0r4-0" start="6">
                                        <li class="c9 li-bullet-0"><span class="c12">What personal data of yours do we process?</span></li>
                                    </ol>
                                    <p class="c0"><span class="c5">We collect and process your personal data through our website, including through:
                                            online forms submitted by you on our website.</span></p>
                                    <p class="c0"><span class="c5">We may process the following categories of personal data (the list is exemplary and
                                            non-exhaustive) provided by you to the Foundation:</span></p>
                                    <p class="c14"><span class="c5"></span></p>
                                    <p class="c20"><span class="c11">A) General Personal Data Category</span></p>
                                    <p class="c20"><span class="c31 c15">&nbsp;</span></p>
                                    <p class="c20"><span class="c5">1. General personal data</span></p>
                                    <ul class="c3 lst-kix_pt5vqywkwb4v-0">
                                        <li class="c10"><span class="c5">Three names and initials</span></li>
                                        <li class="c10"><span class="c5">Address</span></li>
                                        <li class="c10"><span class="c5">Email</span></li>
                                        <li class="c10"><span class="c5">Telephone</span></li>
                                    </ul>
                                    <p class="c14"><span class="c5"></span></p>
                                    <p class="c20"><span class="c5">2. Specific Identification Data</span></p>
                                    <ul class="c3 lst-kix_10y2v5u8n79z-0 start">
                                        <li class="c20 c28 li-bullet-1"><span class="c5">Identification number;</span></li>
                                        <li class="c20 c28 li-bullet-1"><span class="c5">The data from an identity document of the person;</span></li>
                                        <li class="c20 c28 li-bullet-1"><span class="c25">Data on workplace, profession/position, work experience and
                                                education;</span></li>
                                    </ul>
                                    <p class="c14"><span class="c5"></span></p>
                                    <p class="c20"><span class="c5">3. Data about your state of health</span></p>
                                    <ul class="c3 lst-kix_rht2dq9zb0mz-0 start">
                                        <li class="c1 li-bullet-2"><span class="c5">TELK decisions, medical certificates, hospital sheets, etc.</span>
                                        </li>
                                    </ul>
                                    <p class="c2"><span class="c5">4. Expressed interests, preferences, opinions, comments, positions and the like in
                                            relation to the activities of the &quot;Bridges of Trust&quot; Foundation, including for specific campaigns,
                                            initiatives or other events organized, supported or financed by the &quot;Bridges of Trust&quot; Foundation
                                            &quot;</span></p>
                                    <p class="c43"><span class="c15 c31">B) Other data</span></p>
                                    <p class="c19"><span class="c5">Any other information that you may voluntarily provide to us by accessing and/or
                                            using our website, including applications operating on it, filling out forms, etc., or by accessing and/or
                                            using the Foundation&#39;s official pages in social networks &ndash; Facebook; Instagram; Twitter, Linkedin,
                                            Youtube, etc.).</span></p>
                                    <p class="c17"><span class="c5"></span></p>
                                    <ol class="c3 lst-kix_8imn3szo0r4-0" start="7">
                                        <li class="c9 li-bullet-3"><span class="c12">Who has access to your information?</span></li>
                                    </ol>
                                    <p class="c2"><span class="c5">&quot;Bridges of Trust&quot; Foundation is the administrator of your personal data
                                            and as such will not share, transmit, disclose or distribute your personal data to third parties, except for
                                            those provided for in this Policy. Bridges of Trust Foundation respects and protects the privacy of your
                                            personal data. In compliance with the legal requirements or with your consent, it is possible for the
                                            Foundation to disclose your personal data to third parties who are personal data processors, joint or
                                            independent administrators.</span></p>
                                    <ol class="c3 lst-kix_8imn3szo0r4-0" start="8">
                                        <li class="c36 li-bullet-4"><span class="c12">The persons with whom we share information are:</span></li>
                                    </ol>
                                    <ul class="c3 lst-kix_q8y1d68dqvdx-0 start">
                                        <li class="c38 c48"><span class="c5">Other companies - partners of the &quot;Bridges of Trust&quot; Foundation
                                                based on contracts concluded with the Foundation and providing the opportunity to employ users of the
                                                Foundation&#39;s services;</span></li>
                                        <li class="c0 c38"><span class="c5">Regulatory national and European administrative authorities receiving the
                                                information based on legal powers to request and receive such information, as well as other government
                                                authorities in the cases provided for by law, in connection with the fulfillment of legal obligations or
                                                in connection with legal proceedings.</span></li>
                                        <li class="c38 c54"><span class="c5">Security companies holding a license to carry out private security
                                                activity, processing the video recordings from Leno&#39;s offices and/or maintaining other registers in
                                                the process of ensuring the access regime in the same objects;</span></li>
                                        <li class="c0 c38"><span class="c5">Companies participating in the process of hiring workers and
                                                employees.</span></li>
                                        <li class="c0 c38"><span class="c5">Companies and organizations maintaining information related to the health
                                                status of individuals.</span></li>
                                        <li class="c38 c40"><span class="c5">State and municipal bodies related to the fulfillment of the
                                                Foundation&#39;s obligations arising from tax and insurance legislation.</span></li>
                                    </ul>
                                    <ol class="c3 lst-kix_8imn3szo0r4-0" start="9">
                                        <li class="c44 c50"><span class="c12">For how long do we store your personal data?</span></li>
                                    </ol>
                                    <p class="c2"><span class="c5">Your personal data will be stored for a certain period of time, solely for the
                                            purposes for which it was collected and is being processed. They can be stored both on paper and on
                                            electronic media. For example, your contact details will be stored and used solely to send you information
                                            that you have requested from us, until you withdraw your consent.</span></p>
                                    <ol class="c3 lst-kix_8imn3szo0r4-0" start="10">
                                        <li class="c2 c39 li-bullet-3"><span class="c31 c41">Personal data protection measures</span></li>
                                    </ol>
                                    <p class="c20"><span class="c11">Technical measures</span></p>
                                    <p class="c14"><span class="c11"></span></p>
                                    <p class="c0"><span class="c5">Personal data is stored by the Administrator only on an electronic medium.</span></p>
                                    <p class="c0 c30"><span class="c5"></span></p>
                                    <p class="c0"><span class="c5">The protection of electronic data from unauthorized access, damage, loss or
                                            destruction, committed intentionally by a person or in the event of technical malfunctions, accidents,
                                            accidents, disasters, etc., is ensured through:</span></p>
                                    <p class="c0"><span class="c5">- entering passwords for the computers through which access to the personal data is
                                            provided and the files that contain personal data;</span></p>
                                    <p class="c0"><span class="c5">- antivirus programs, checks for illegally installed software;</span></p>
                                    <p class="c0"><span class="c5">- periodic checks of the integrity of the database and updating of system
                                            information, maintenance of the data access system;</span></p>
                                    <p class="c0"><span class="c5">- periodic archiving of data on technical media, maintenance of information on paper
                                            (archive copies).</span></p>
                                    <p class="c0 c30"><span class="c5"></span></p>
                                    <p class="c20"><span class="c11">Documentary protection measures</span></p>
                                    <p class="c14"><span class="c11"></span></p>
                                    <p class="c0"><span class="c5">The company establishes procedures for processing personal data, regulating access to
                                            data, destruction procedures and storage terms, detailed in these Rules.</span></p>
                                    <p class="c0 c30"><span class="c5"></span></p>
                                    <p class="c0"><span class="c5">The reproduction and distribution of documents or files containing personal data is
                                            carried out only and only by authorized employees when necessary.</span></p>
                                    <p class="c0 c30"><span class="c5"></span></p>
                                    <p class="c20"><span class="c11">Personal protective measures</span></p>
                                    <p class="c14"><span class="c11"></span></p>
                                    <p class="c0"><span class="c5">Before taking up the relevant position, the persons who carry out the protection and
                                            processing of personal data:</span></p>
                                    <p class="c0"><span class="c5">- take an obligation not to distribute the personal data to which they have access
                                            and sign a confidentiality declaration;</span></p>
                                    <p class="c0"><span class="c5">- familiarize themselves with the legal framework, the company&#39;s internal rules
                                            and policies regarding the protection of personal data;</span></p>
                                    <p class="c0"><span class="c5">- undergo training to react to events threatening data security;</span></p>
                                    <p class="c0"><span class="c5">- are instructed about the dangers for the personal data processed by the
                                            company;</span></p>
                                    <p class="c0"><span class="c5">- undertake not to share critical information with each other and with outsiders,
                                            except in accordance with the procedure established by these Rules.</span></p>
                                    <p class="c0 c30"><span class="c5"></span></p>
                                    <p class="c0"><span class="c5">Follow-up training and staff training are conducted periodically to ensure knowledge
                                            of the regulatory framework, potential data security risks and mitigation measures.</span></p>
                                    <p class="c13"><span class="c5"></span></p>
                                    <ol class="c3 lst-kix_8imn3szo0r4-0" start="11">
                                        <li class="c8"><span class="c12">Your rights regarding your personal data</span></li>
                                    </ol>
                                    <p class="c2"><span class="c5">Subject to compliance with Bulgarian and European legislation, including Regulation
                                            (EU) 2016/679, you have the following rights regarding your personal data processed by the &quot;Bridges of
                                            Trust&quot; Foundation:</span></p>
                                    <p class="c18"><span class="c11">1. &nbsp;General Rights</span></p>
                                    <p class="c18"><span class="c5">In connection with the processing of personal data, you have the rights described
                                            below, which you can exercise at any time while the Foundation &quot;Bridges of the Dorevieto&quot; stores
                                            or processes your personal data, by sending an application to the address of the Foundation indicated above,
                                            or by electronic via email.</span></p>
                                    <p class="c18"><span class="c5">Every person, after making a written application, has the right to access the
                                            personal data collected for him by Leno. In cases where, when the client exercises this right of access,
                                            personal data may also be disclosed to a third party, the Foundation is obliged to provide the relevant
                                            person with access to the part of them that pertains only to him. In exercising their right of access, each
                                            client of the Foundation has the right at any time to request:</span></p>
                                    <ul class="c3 lst-kix_h9vgkrvbirk1-0 start">
                                        <li class="c33 li-bullet-3"><span class="c5">confirmation of whether data relating to him is being processed,
                                                information on the purposes of such processing, on the categories of data and on the recipients or
                                                categories of recipients to whom the data is disclosed;</span></li>
                                        <li class="c4 li-bullet-3"><span class="c5">a message to the client in an understandable form containing his
                                                personal data that is being processed, as well as any available information about their source;</span>
                                        </li>
                                        <li class="c4 li-bullet-3"><span class="c5">information about the logic of any automated processing of personal
                                                data.</span></li>
                                    </ul>
                                    <p class="c30 c53"><span class="c5"></span></p>
                                    <p class="c37 c25"><span class="c5">Each client has the right at any time to request the Foundation to:</span></p>
                                    <ul class="c3 lst-kix_99f7gpt3pgpg-0 start">
                                        <li class="c6 li-bullet-3"><span class="c5">delete, correct or block his personal data, the processing of which
                                                does not meet the requirements of the regulatory framework;</span></li>
                                        <li class="c20 c23 li-bullet-3"><span class="c5">notify the third parties to whom his personal data has been
                                                disclosed of any deletion, correction or blocking carried out in accordance with the previous point,
                                                except in cases where this is impossible or involves excessive efforts.</span></li>
                                    </ul>
                                    <p class="c25 c30 c45 c51"><span class="c5"></span></p>
                                    <p class="c25 c37"><span class="c5">Each customer to whom the personal data relates has the right to:</span></p>
                                    <ul class="c3 lst-kix_x7t1ka14d47s-0 start">
                                        <li class="c6 li-bullet-3"><span class="c5">objected to the Foundation against the processing of his personal
                                                data if there is a legal basis for this; when the objection is justified, the personal data of the
                                                respective customer can no longer be processed;</span></li>
                                        <li class="c20 c23 li-bullet-3"><span class="c5">object to the processing of his personal data for direct
                                                marketing purposes;</span></li>
                                        <li class="c20 c23 li-bullet-3"><span class="c5">be notified before his personal data is disclosed for the first
                                                time to third parties or used on their behalf for direct marketing purposes, and be given the
                                                opportunity to object to such disclosure or use.</span></li>
                                    </ul>
                                    <p class="c7"><span class="c11">2. Complaint to the supervisory authority</span></p>
                                    <p class="c7"><span class="c5">You have the right to file a complaint directly with the supervisory authority, the
                                            competent authority being:</span></p>
                                    <p class="c7"><span class="c15">Commission for the Protection of Personal Data, address: Bulgaria, Sofia 1592,
                                            &quot;Prof. Tsvetan Lazarov&quot; No. 2 (</span><span class="c15 c46">www.cpdp.bg</span><span
                                            class="c5">).</span></p>
                                    <p class="c7"><span class="c5">In the event that you have questions and/or complaints regarding the processing of
                                            your personal data and/or the exercise of the aforementioned rights, you may contact the Data Protection
                                            Officer (at the above contact details).</span></p>
                                    <p class="c7"><span class="c11">3. Objection to use for direct marketing</span></p>
                                    <p class="c7"><span class="c5">You have the right to object to the future processing of your personal data for the
                                            purposes of direct marketing and advertising, as well as to their disclosure to third parties and their use
                                            on their behalf for the purposes of direct marketing and advertising, by withdrawing your consent under
                                            anytime. For this purpose, you can send an electronic message with the corresponding request to stop using
                                            your data for direct marketing purposes to the address: info@mnd.bg</span></p>
                                    <p class="c7"><span class="c11">5. Can you refuse to provide personal data to the Foundation and what are the
                                            consequences of this</span></p>
                                    <p class="c7 c30"><span class="c5"></span></p>
                                    <p class="c7"><span class="c5">In order to enter into a valid contract with you and/or to provide you with the
                                            requested services in accordance with our legal and subsequently contractual obligations, the Foundation
                                            needs certain data detailed in this Privacy Policy.</span></p>
                                    <p class="c7"><span class="c5">Failure to provide such data may prevent us from providing you with the type of
                                            service you have requested and/or entering into a contract on the terms you desire.</span></p>
                                    <p class="c7"><span class="c11">6. Portability of personal data</span></p>
                                    <p class="c7"><span class="c5">You have the right to request that your personal data be transmitted or transferred
                                            to another controller in a structured, widely used and machine-readable format. Leno will only carry out a
                                            direct data transfer if this is technically feasible.</span></p>
                                    <ol class="c3 lst-kix_8imn3szo0r4-0" start="12">
                                        <li class="c9 li-bullet-3"><span class="c12">Who are we?</span></li>
                                    </ol>
                                    <p class="c2"><span class="c5">Bridges of Trust Foundation is a Foundation created for public benefit, registered in
                                            the Commercial Register at the Registration Agency under EIK 206771528, with headquarters and management
                                            address: Republic of Bulgaria, Gr. Sofia, Krasno Selo District, Lerinska Chuka St. No. 2, floor 5, apartment
                                            20. In accordance with applicable data protection legislation, Bridges of Trust Foundation acts as a data
                                            controller with respect to the Personal Data of users of its website and applications.</span></p>
                                    <p class="c44"><span class="c12">How to contact us?</span></p>
                                    <p class="c2"><span class="c15">In the event that this policy does not answer your questions or you have another
                                            inquiry, please contact us directly at the following address: Sofia, Krasno Selo District, Lerinska Chuka
                                            Street No. 2, Floor 5, Apt. 20, or at email: w</span><span class="c31 c15">info@mnd.bg</span><span
                                            class="c15">, or with our data protection officer:</span><span class="c11">Ivaylo Tsvetomirov Mitev - email:
                                            ivaylo.mitev@mnd.bg</span></p>
                                    <p class="c43"><span class="c5">&nbsp;</span></p>
                                    <p class="c16"><span class="c49"></span></p>
                                    <p class="c29"><span class="c52"></span></p>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default PrivacyPolicy;