import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BannerFive = ( props ) => {
    const [toggler, setToggler] = useState(false);
    const videoLink = [
        props.videoLink || 'https://www.youtube.com/watch?v=pNje3bWz7V8'
    ];
    const { t } = useTranslation();
    return (
        <>
            <div className="slider-area banner-style-5 bg-image">
                <div className="wrapper d-flex align-items-center">
                    <div className="container">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="inner pt--100 pt_md--0 pt_sm--0">
                                    <div className="content text-start">
                                        <h1 className="title">{t('landingh1')}</h1>
                                        <p className="description">{t('landingDesc')}</p>
                                        <div className="read-more-btn">
                                            <a className="edu-btn" href="#services">{t('landingButton')} <i className="icon-arrow-right-line-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="banner-image">
                                    <div className="thumbnail">
                                        <img src="/images/banner/banner-05/hero.png" alt="Banner Images" />
                                    </div>
                                    <div className="card-info bounce-slide">
                                        <div className="inner">
                                            <div className="name">{t("reviewTitle1")} <span>/ {t('reviewTitle2')}</span></div>
                                            <div className="rating-wrapper d-flex">
                                                <div className="rating bridgesoftrust-course-rating-stars">
                                                    <i className="icon-Star"></i>
                                                    <i className="icon-Star"></i>
                                                    <i className="icon-Star"></i>
                                                    <i className="icon-Star"></i>
                                                    <i className="icon-Star"></i>
                                                </div>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-07.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-03-05.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-05-04.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-4">
                        <img src="/images/shapes/shape-06.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-5">
                        <img src="/images/shapes/shape-01-03.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-6">
                        <img src="/images/shapes/shape-01-04.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
            <FsLightbox toggler={ toggler } sources={ videoLink } />
        </>
    )
}

export default BannerFive;