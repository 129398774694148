import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import AboutSeven from '../../components/about/AboutSeven';
import AboutUsOneService from '../../components/about-us-one/AboutUsOneService';
import AboutSix from '../../components/about/AboutSix';
import AboutOne from '../../components/about/AboutThree';
import AboutUsOneTeam from '../../components/about-us-one/AboutUsOneTeam';
import HomeTwoTestimonial from '../../components/home-two/HomeTwoTestimonial';
import FooterTwo from '../../common/footer/FooterTwo';
import { useTranslation } from 'react-i18next';

const AboutUsOne = () => {
    const { t } = useTranslation();
    return (
        <>
            <SEO title={t("aboutHeaderTitle")} />

            <HeaderTwo styles="header-style-2" searchDisable buttonStyle="bg-color-white" />

            <BreadcrumbOne 
                title={t("aboutTitle")}
                rootUrl="/"
                parentUrl={t("home")}
                currentUrl={t("aboutHeaderTitle")}
            />

            <AboutSeven />
            
            <AboutUsOneService />

            <AboutSix />

            <AboutOne />

            <AboutUsOneTeam />

            {/* <HomeTwoTestimonial /> */}

            <FooterTwo />
        </>
    )
}

export default AboutUsOne;