import { forwardRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import langs from "../../i18n/langs.json";
import withClickOutside from "./withClickOutside";

const HeaderLanguage = forwardRef(({open, setOpen}, ref) => {
  const { i18n } = useTranslation();
  const [languages] = useState(langs);

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
    setOpen(false);
  }

  return (
    <section ref={ref}>
      <div className={`language-switcher ${open ? "open" : "closed"}`}>
        <button type="button" className="languageButton" onClick={() => setOpen(!open)}>
          <div className="language-label">
            <img
              src={`/images/flags/${i18n.language}.webp`}
              alt={langs[i18n.language]}
            ></img>
          </div>
        </button>
        <div
          className="language-menu"
          id="langMenu"
          style={{ display: open ? "block" : "none" }}
        >
          {Object.keys(languages).map(function (key) {
            return (
              <button
                key={key}
                className="switchButton"
                onClick={changeLanguage}
                value={key}
              >
                <img src={`/images/flags/${key}.webp`}></img>
                {languages[key]}
              </button>
            );
          })}
        </div>
      </div>
    </section>
  );
})
  
export default withClickOutside(HeaderLanguage);